<template id="loginviw">
    <v-container class="v-container " fluid id="contai">
        <div style="background-color: whitesmoke; zoom: 100%;" class="mx-auto">
            <div class="text-center mx-auto" id="logo">
                <v-img id="img" src="../assets/logo-avec-fond1.png" class="mx-auto" contain>
                </v-img>
            </div>
            <v-row>
                <v-alert v-if="errors.message" class="mt-15" dense :type="errors.type"
                    style="height: 60px; padding-top: 10px">
                    {{ errors.message }}
                </v-alert>
            </v-row>
            <v-container>
                <v-row>
                    <!-- Mode essais -->
                    <v-col cols="12" md="6">
                        <v-card max-width="25rem" variant="outlined" id="essais-card" class="suscrib-card mx-auto"
                            style="border-top: 4px solid pink;">
                            <v-card-text>
                                <p class="text-h5  text--accent-3" style="color:#114E60;">
                                    {{ $t('loginView.mode1') }}
                                </p>
                                <div class="text--primary mode-essai" align="left">
                                    {{ $t('typeSuscriptioin.mode1') }}
                                    <br>
                                    <b>NB: </b> <span>
                                        {{ $t('typeSuscriptioin.mode1_1') }}
                                    </span>
                                </div>
                                <v-form ref="formEssai" v-model="valid" lazy-validation>
                                    <v-text-field :rules="requireRule" v-model="formLigneSuscription.entreprise"
                                        :label="$t('formField.codeinscription')" required class="mt-4" type="numeric">
                                    </v-text-field>
                                </v-form>
                            </v-card-text>

                            <v-card-actions>
                                <v-btn variant="outlined" @click="saveEssais">
                                    {{ $t('buttons.validate') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                    <!-- Fin -->
                    <!-- Mode inscription directe -->
                    <v-col cols="12" md="6">

                        <v-card max-width="25rem" variant="outlined" id="direct-card" class="suscrib-card mx-auto"
                            style="border-top: 4px solid green;">
                            <v-card-text>
                                <p class="text-h5  text--accent-3" style="color:#114E60;">
                                    {{ $t('loginView.mode2') }}
                                </p>
                                <div class="text--primary mode-essai" align="left">
                                    {{ $t('typeSuscriptioin.mode2') }}
                                    <br>
                                    <b>NB: </b> <span>
                                        {{ $t('typeSuscriptioin.mode2_1') }}
                                    </span>
                                </div>
                                <v-form ref="formDirect" v-model="valid" lazy-validation>
                                    <v-text-field :rules="requireRule" v-model="formLigneSuscription.entreprise1"
                                        :label="$t('formField.codeinscription')" required class="mt-4" type="numeric">
                                    </v-text-field>
                                    <v-combobox v-model="selectperiode"
                                        :items="$i18n.locale == 'fr' ? periodSuscription : periodSuscription1"
                                        :label="$t('formField.period')" item-value="id" item-text="text" dense
                                        auto-select-first eraseble class="mt-8" @change="setSelectPeriode"
                                        :rules="requireRule">
                                    </v-combobox>
                                </v-form>
                            </v-card-text>

                            <v-card-actions>
                                <v-btn variant="outlined" @click="saveDirect">
                                    {{ $t('buttons.validate') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                    <!-- Fin -->

                </v-row>
            </v-container>
        </div>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import SuscriptionManager from '@/services/Suscription'
import Message from '@/services/Message'
export default {
    name: "TypeSuscription",
    data() {
        return {
            selectperiode: '',
            valid: true,
            errors: this.getErrors(),
            periodSuscription: [
                { id: 1, text: '1 Mois' },
                { id: 3, text: '3 Mois' },
                { id: 6, text: '6 Mois' },
                { id: 12, text: '1 Ans' },
            ],
            periodSuscription1: [
                { id: 1, text: '1 Month' },
                { id: 3, text: '3 Months' },
                { id: 6, text: '6 Months' },
                { id: 12, text: '1 Year' },
            ],
            formLigneSuscription: {
                periode: "",
                entreprise: "",
                entreprise1: "",
            },
            // Validation rules
            requireRule: [
                v => !!v || 'Ce champ est obligatoire',
            ],
        }
    },
    methods: {
        ...mapGetters(['getErrors']),
        setSelectPeriode() {
            console.log(this.selectperiode)
            this.formLigneSuscription.periode = this.selectperiode.id
        },
        saveEssais() {
            if (this.$refs.formEssai.validate() == true) {
                SuscriptionManager.modeEssais(this.formLigneSuscription.entreprise).then((res) => {
                    this.$router.push({ path: '/singin' })
                    this.$store.dispatch(
                        "makeErros",
                        Message.createMessage(
                            res.data.message,
                            "success"
                        )
                    );
                }).catch((error) => {
                    this.$store.dispatch(
                        "makeErros",
                        Message.createMessage(
                            error.response.data.message,
                        )
                    );
                });
            }
        },
        saveDirect() {
            if (this.$refs.formDirect.validate() == true) {
                var data = {
                    'entreprise': this.formLigneSuscription.entreprise1,
                    'periode': this.formLigneSuscription.periode,
                }
                SuscriptionManager.modeDirect(data).then((res) => {
                    this.$router.push({ path: '/singin' })
                    this.$store.dispatch(
                        "makeErros",
                        Message.createMessage(
                            res.data.message,
                            "success"
                        )
                    );
                }).catch((error) => {
                    this.$store.dispatch(
                        "makeErros",
                        Message.createMessage(
                            error.response.data.message,
                        )
                    );
                });
            }
        }
    }

}
</script>
<style lang="scss">
.v-container {
    height: 100%;
    background-color: #e9f0ea;
    padding: 10px 5% 0 5%;
}

.suscrib-card {
    margin-top: 15rem;
    border-radius: 0px;
    font-size: 16px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-align: left;

}

@media only screen and (max-width: 946px) {
    #essais-card {
        margin-top: 9rem;
    }

    #direct-card {
        margin-top: 3px;
    }
}

.mode-essai {
    font-size: 18px;
}
</style>
