import ApiServiceD from "@/classes/ApiServiceD";
import ApiService from "@/classes/ApiService";
export default class ConteneurManager{
   static conteneurListe(entreprise_id, token=null){
        return new Promise(function(resolve, reject){
            new ApiServiceD().getList('conteneur/register/',{entreprise: entreprise_id}, token)
            .then((resp)=>{
                resolve(resp);
            }).catch((err)=>{reject(err);})
        })
    }
    static getArticleInContener(conteneur_id, token=null){
        return new Promise(function(resolve, reject){
            new ApiServiceD().getList('conteneur/article-in-conteneur/',{conteneur: conteneur_id}, token)
            .then((resp)=>{
                resolve(resp);
            }).catch((err)=>{reject(err);})
        })
    }

    static registerConteneur(formData, token=null){
        console.log(token);
        return new Promise(function(resolve, reject){
            new ApiService().add('conteneur/register/',formData, null, token)
            .then((resp)=>{
                resolve(resp);
            }).catch((err)=>{reject(err);})
        })
    }
    static updatePiece(formData, conteur_id, token=null){
        return new Promise(function(resolve, reject){
            new ApiServiceD().update('conteneur/register/',formData,
            { id: conteur_id}, token)
            .then((resp)=>{
                resolve(resp);
            }).catch((err)=>{reject(err);})
        })
    }
}