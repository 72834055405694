<template id="loginviw">
    <div id="contai">
        <v-container>
            <div class="text-center mx-auto" id="logo">
                <v-img id="img" src="../assets/logo-avec-fond1.png" class="mx-auto" contain>
                </v-img>
            </div>
            <v-container class="fill-height" fluid>
                <v-row align="center" justify="center" class="mt-15">
                    <v-col cols="12" md="8" sm="8">
                        <v-card class="elevation-5">
                            <RegisterForm />
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-container>
    </div>
</template>
<script>
import RegisterForm from "../components/Account/RegisterForm.vue";

// import ApiService from '@/classes/ApiService'
export default {
    components: { RegisterForm },
    data: () => ({
        name: "RegisterVue",
    }),
}
</script>
<style>
#contai{
    background-color: #e9f0ea;
}
</style>