<template lang="">
    <div class="pb-5">
        <v-card-text class="mt-12">
            <h3 class="text-center  text--accent-3" style="color:#114E60;">
                {{$t('entrepriseRegisterView.user_account')}}
            </h3>
            <v-alert v-if="errors.message"
            dense
            :type="errors.type"
            style="height: 60px; padding-to: 10px"
            >
            {{errors.message}}
            </v-alert>
            <div>
                <v-img id="signimg" src="../../assets/logo/3.png" class="mx-auto mb-5" contain>
            </v-img>
            </div>
            <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
                v-model="Form.entreprise"
                :label="$t('formField.codeinscription')"
                :name="$t('formField.codeinscription')"
                prepend-icon="person"
                type="text"
                color="cyan darken-3"
                :rules="requireRules" dense outlined required />
            <v-text-field
                v-model="Form.username"
                :label="$t('formField.username')"
                :name="$t('formField.username')"
                prepend-icon="person"
                type="text"
                color="cyan darken-3"
                :rules="requireRules" dense outlined required />
            <v-text-field
                v-model="Form.telephone"
                :label="$t('formField.phoneNumber')"
                :name="$t('formField.phoneNumber')"
                prepend-icon="phone"
                type="text"
                color="cyan darken-3"
                :rules="requireRules" dense outlined required />
            <v-text-field
                v-model="Form.email"
                :label="$t('formField.emailAdress')"
                :name="$t('formField.emailAdress')"
                prepend-icon="email"
                type="text"
                color="cyan darken-3"
                :rules="requireRules" dense outlined required />
            <v-text-field
                v-model="Form.password"
                id="password"
                :label="$t('formField.password')"
                :name="$t('formField.password')"
                prepend-icon="lock"
                type="password"
                color="cyan darken-3"
                :rules="requireRules" dense outlined required />
            <v-text-field
                v-model="Form.password2"
                id="password2"
                :label="$t('formField.password_confirm')"
                :name="$t('formField.password_confirm')"
                prepend-icon="lock"
                type="password"
                color="cyan darken-3"
                :rules="requireRules" dense outlined required />
            </v-form>
            <div class="text-center mt-3 mb-5">
                <v-btn rounded v-appcolor dark @click="register">{{$t('buttons.save')}}</v-btn>
            </div>
            <i18n path="already_registered" tag="p" id="noaccount">
                <router-link id="link" to="/login">{{$t('buttons.login')}}</router-link>
            </i18n>
            <!-- <p id="noaccount">{{$t('already_registered')}} ? 
                <router-link id="link" to="/login">{{$t('buttons.login')}}</router-link>
            </p> -->
        </v-card-text>
        <!-- <LoaderViewVue v-if="loader"/> -->
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import UserService from '@/services/UserService'
export default {
    name: 'RegisterForm',
    data() {
        return {
            valid: true,
            Form: {
                entreprise: "",
                username: "",
                telephone: "",
                email: "",
                password: "",
                password2: "",
            },
            errors: this.getErrors(),
            requireRules: [
                v => !!v || "Ce champ est obligatoire",
            ],
        }
    },
    methods: {
        ...mapGetters(['getErrors']),
        ...mapGetters('entreprise', ['getCodeEntreprise']),
        ...mapActions(['userRegister']),
        loginview() {
            this.$router.push({ path: '/login' })
        },
        register() {
            if (this.$refs.form.validate()) {
                UserService.register('auth/register/', this.Form)
            }

        },
    },

}
</script>