<template>
    <table>
        <tbody>
            <tr>
                <v-list-item-title class="mb-1" align="left">
                    <th>Nom d'utilisateur:</th>
                    <td><span>{{user.username}}</span></td>
                </v-list-item-title>
            </tr>
            <tr>
                <v-list-item-title class="mb-1" align="left">
                    <th>Prénom(s):</th>
                    <td><span>{{user.first_name}}</span></td>
                </v-list-item-title>
            </tr>
            <tr>
                <v-list-item-title class="mb-1" align="left">
                    <th>Nom:</th>
                    <td><span>{{user.last_name}}</span></td>
                </v-list-item-title>
            </tr>
            <tr>
                <v-list-item-title class="mb-1" align="left">
                    <th>Contact:</th>
                    <td><span>{{user.tel}}</span></td>
                </v-list-item-title>
            </tr>
            <tr>
                <v-list-item-title class="mb-1" align="left">
                    <th>Email:</th>
                    <td><span>{{user.email}}</span></td>
                </v-list-item-title>
            </tr>
        </tbody>
    </table>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    components: {  },
    data: () => ({
        tab: null,
        user: {
            username: '',
            first_name: '',
            last_name: '',
            tel: '',
            email: '',
        },
    }),
    created(){
        this.initialize()
    },
    methods: {
        ...mapGetters(['getUser']),
        initialize(){
            this.user = this.getUser()
        }
    },
}
</script>