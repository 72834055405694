// import moment from "moment"
export default{
    namespaced: true,
    state:{
        disalogService: false,
        listAccessByService: [],
    },
    getters:{
        getDialogService:(state) => state.disalogService,
        getListAccessByService:(state) => state.listAccessByService
    },
    mutations:{  
        SET_DIALOG:(state, value) => state.disalogService = value,
        SET_LIST_ACCESS_BY_SERVICE:(state, value) => state.listAccessByService = value,
    },
    actions:{
       switchDialogService: ({commit}, payload) => commit('SET_DIALOG',payload),
       setListAccessByService: ({commit}, payload) => commit('SET_LIST_ACCESS_BY_SERVICE',payload),
        
    }
}