export default{
    namespaced: true,
    state:{
        listMaintenance: [],
    },
    getters:{
        getlistMaintenance:(state) => state.listMaintenance,
    },
    mutations: {
        SET_LIST_MAINTENANCE:(state, payload) => state.listMaintenance = payload,
        // FILTER_LIST_APPAREIL: (state, id) => state.appareil = state.listMaintenance.filter(app => app.ticket.id === id)[0],     
    },
    actions: {
        async fetchlistMaintenance({commit}, payload){
            await commit('SET_LIST_MAINTENANCE', payload)
        },
        // async filterAppareil({commit}, payload){
        //     commit('FILTER_LIST_APPAREIL', payload)
        // }
    }
}
