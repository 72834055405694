import ApiServiceD from "@/classes/ApiServiceD";

export default class FournisseurManager{
    static listFournisseur(entreprise_id, token=null){
        return new Promise(function(resolve, reject){
            new ApiServiceD().getList('fournisseurs/register/',{entreprise: entreprise_id}, token)
            .then((resp)=>{
                resolve(resp);
            }).catch((err)=>{reject(err);})
        })
    }
    static registerFournisseur(formData, token){
        return new Promise(function(resolve, reject){
            new ApiServiceD().add('fournisseurs/register/',formData,null, token)
            .then((resp)=>{
                resolve(resp);
            }).catch((err)=>{reject(err);})
        })
    }
    static updateFournisseur(formData, entreprise_id, id_fourni, token=null){
        return new Promise(function(resolve, reject){
            new ApiServiceD().update('fournisseurs/register/',formData
            ,{id: id_fourni, entreprise: entreprise_id}, token)
            .then((resp)=>{
                resolve(resp);
            }).catch((err)=>{reject(err);})
        })
    }
    static deleteFournisseur(id_fourni, token=null){
        return new Promise(function(resolve, reject){
            new ApiServiceD().delete('fournisseurs/register/',{id: id_fourni}, token)
            .then((resp)=>{
                resolve(resp);
            }).catch((err)=>{reject(err);})
        })
    }
}