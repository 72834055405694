<template>
  <v-card class="p-3" style="border-radius: 0px;">
    <v-toolbar v-appcolor color="white--text" flat>
      <slot name="header">Defaut header</slot>
    </v-toolbar>
    <v-card-text>
      <slot name="cbody">Defaut content</slot>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'FormTemplate'

}
</script>

<style>
</style>