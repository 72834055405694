<template>
    <v-app id="contai">
        <SideBare />
        <ToolBare>
            <template v-slot:cbody>
                <ServiceChips v-on:linkCliked="makeLinke()" />
            </template>
        </ToolBare>
        <component v-bind:is="cmp"></component>
    </v-app>
</template>
<script>
import SideBare from "../components/SideBare.vue";
import ToolBare from "@/components/ToolBare.vue";
import ServiceChips from "@/components/ServiceChips.vue";
import VenteView from "../components/Vente/VenteView.vue";
import ClientView from "../components/Client/ClientView.vue";
export default {
    name: "ServiceView",
    data: () => ({
        cmp: '',
    }),
    components: {
        SideBare,
        ToolBare,
        ServiceChips,
        "vente": VenteView,
        "client": ClientView
    },

    methods: {
       makeLinke() {
            this.cmp = window.location.pathname.split('/').pop()
        },
    },
    created(){
        this.makeLinke()
    }
}
</script>
<style>
</style>