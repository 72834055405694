<template>
    <v-app-bar color="transparent" dark dense flat>
        <v-chip class="ma-2 respocl" to="/" style="font-size:18px;" pill>
            <span class="d-none d-sm-flex">
                {{ $i18n.locale == 'fr' ? "Accueil" : "Home" }}
                <v-icon right small color="white">fa-brands fa-accusoft</v-icon>
            </span>
            <v-icon left color="white" class="d-sm-none" size="25px">fa-brands fa-accusoft</v-icon>
        </v-chip>
        <v-chip class="ma-2 respocl" v-for="(item, i) in items" :key="i" @click="navigateTo(item.link)"
            style="font-size:18px;" pill>
            <span class="d-none d-sm-flex">
                {{ item.text }}
                <v-icon right small color="white">{{ item.icon }}</v-icon>
            </span>
            <v-icon left color="white" class="d-sm-none" size="25px">{{ item.icon }}</v-icon>
        </v-chip>
    </v-app-bar>
</template>

<script>
// import { mapGetters } from "vuex";
export default {
    name: 'StockChips',
    data() {
        return {
            items: this.$i18n.locale == 'fr' ? [
                { text: "Approvisionnement", icon: "fab fa-dropbox", link: "/admin/stockes/aprovisionnement" },
                { text: "Fournisseur", icon: "fas fa-users", link: "/admin/stockes/fournisseur" },
            ] : [
                { text: "Sourcing", icon: "fab fa-dropbox", link: "/admin/stockes/aprovisionnement" },
                { text: "Supplier", icon: "fas fa-users", link: "/admin/stockes/fournisseur" },
            ]
        }
    },
    methods: {
        // ...mapGetters(['getUserComponents','getAccessMenu']),
        navigateTo(chemin) {
            this.$router.push({ path: chemin }).catch(()=>{});
            this.$emit('linkCliked', chemin)
        },
    }

}
</script>
<style>
@media only screen and (max-width: 600px) {

    /* For mobile phones: */
    .respocl {
        background-color: transparent !important;
        border-radius: 25px;
        display: block;
        margin-left: -20px;
    }
}
</style>