import ApiServiceD from "@/classes/ApiServiceD";
import store from "@/store";
import Message from "./Message";
import UserService from "./UserService";
export default class ServiceAccesManager{
   static listServiceAcces(entreprise_id,token= null){
        return  new Promise((resolve, reject)=>{
             new ApiServiceD().getList('services/register/',{entreprise: entreprise_id},token)
            .then((rep) =>{
                resolve(rep.data)
            }).catch(err=>{
                reject(err)
            })
        })
    }

    static ListComposant(token= null){
        return  new Promise((resolve, reject)=>{
             new ApiServiceD().getList('components/register/',null,token)
            .then((rep) =>{
                resolve(rep.data)
            }).catch(err=>{
                UserService.getErrorMessage(err) 
                reject(err)
            })
        })
    }

    static RegisterService(data, entreprise_id, token){
        return new Promise((resolve) =>{
            new ApiServiceD().create('services/register/', data, {entreprise:entreprise_id},token)
            .then((res) =>{
                resolve(res.data)
                store.dispatch(
                    "makeErros",
                    Message.createMessage(
                      "Service ajouter avec success", 'success'
                    )
                );
                setTimeout(() =>{
                    store.dispatch(
                        "makeErros",
                        Message.createMessage("")
                    );
                }, new ApiServiceD().getTimeout())
            }).catch(err=>{
                UserService.getErrorMessage(err) 
            });
        })
    }
    static UpdateService(data, service_id, token){
        return new Promise((resolve, reject) =>{
            new ApiServiceD().create('services/add/', data, {service:service_id},token)
            .then((res) =>{
                resolve(res.data)
                store.dispatch(
                    "makeErros",
                    Message.createMessage(
                      "Mise à jour effectué avec success", 'success'
                    )
                );
                setTimeout(() =>{
                    store.dispatch(
                        "makeErros",
                        Message.createMessage("")
                    );
                }, new ApiServiceD().getTimeout())
            }).catch(err=>{
                console.log(err);
                UserService.getErrorMessage(err) 
                reject(err)
            });
        })
    }
    static DeleteService(service_id, token){
        return new Promise((resolve, reject) =>{
            new ApiServiceD().delete('services/register/',{service_id:service_id},token)
            .then((res) =>{
                resolve(res.data)
                store.dispatch(
                    "makeErros",
                    Message.createMessage(
                      "Service supprimé avec success", 'success'
                    )
                );
                setTimeout(() =>{
                    store.dispatch(
                        "makeErros",
                        Message.createMessage("")
                    );
                }, new ApiServiceD().getTimeout())
            }).catch(err=>{reject(err)});
        })
    }
    
    static AddUserToService(data, token){
        return new Promise((resolve, reject) =>{
            new ApiServiceD().add('accountservices/register/',data,null,token)
            .then((res) =>{
                resolve(res.data)
                store.dispatch(
                    "makeErros",
                    Message.createMessage(
                      "Eregistrement effectué avec success", 'success'
                    )
                );
                setTimeout(() =>{
                    store.dispatch(
                        "makeErros",
                        Message.createMessage("")
                    );
                }, new ApiServiceD().getTimeout())
            }).catch(err=>{
                UserService.getErrorMessage(err) 
                reject(err)
            });
        })
    }

    static RemoveUserToService(user_id, token){
        return new Promise((resolve, reject) =>{
            new ApiServiceD().delete('accountservices/register/',{account:user_id},token)
            .then((res) =>{
                resolve(res.data)
                store.dispatch(
                    "makeErros",
                    Message.createMessage(
                      "Retrait de servie effectué avec success", 'success'
                    )
                );
                setTimeout(() =>{
                    store.dispatch(
                        "makeErros",
                        Message.createMessage("")
                    );
                }, new ApiServiceD().getTimeout())
            }).catch(err=>{reject(err)});
        })
    }
}