<template>
  <v-app id="contai">
    <SideBare />
    <ToolBare>
      <template v-slot:cbody>
        <ComptaChips />
      </template>
    </ToolBare>


    <v-container fluid>
      <v-card>
        <v-toolbar flat dark v-appcolor>

          <v-toolbar-title>{{ lang == 'fr' ? 'Votre situation financière' : 'Your financial situation'
            }}</v-toolbar-title>
        </v-toolbar>
        <v-tabs align="center">

          <v-tab style="color: #114E60;" v-if="userIsMaster">
            <v-icon left>
              fas fa-money-check-dollar
            </v-icon>
            {{ lang == 'fr' ? 'entrer caisse' : 'enter cash register' }}
          </v-tab>

          <v-tab style="color: #114E60;">
            <v-icon left>
              fas fa-money-bill-trend-up
            </v-icon>
            {{ lang == 'fr' ? 'sortie caisse' : 'checkout' }}
          </v-tab>

          <v-tab style="color: #114E60;">
            <v-icon left>
              fas fa-money-check-dollar
            </v-icon>
            {{ lang == 'fr' ? 'solde Caisse' : 'cash balance' }}
          </v-tab>

          <!-- Enter financière -->
          <v-tab-item v-if="userIsMaster">
            <v-card flat>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="6">
                    <BilanEntreVenteView @totalVente="(result) => total_Vente = result" />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!-- Sortie financière -->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-row>
                  <v-col cols="12" md="6">
                    <BilanApproView />
                  </v-col>
                  <v-col cols="12" md="6">
                    <BilanDepenseBenefice v-on:submit="initialize()" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                    <DepenseTotal :soldeTotal="launchTotalLoad" />
                  </v-col>
                  <v-col cols="12" md="6">
                    <ListDepense :listDepenseMensuelle="listDepense" />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <!-- Solde de la caisse -->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-spacer></v-spacer>
                <SoldeCaisseView />
                <v-spacer></v-spacer>
              </v-card-text>
            </v-card>
          </v-tab-item>

        </v-tabs>
      </v-card>
    </v-container>
  </v-app>
</template>
<script>
import SideBare from '@/components/SideBare.vue';
import ToolBare from '@/components/ToolBare.vue';
import ComptaChips from '../components/ComptaChips.vue';
import BilanEntreVenteView from '@/components/Bilan/BilanEntreVenteView.vue';
import BilanApproView from '../components/Bilan/BilanApproView.vue';
import BilanDepenseBenefice from '@/components/Bilan/BilanDepenseBenefice.vue';
import DepenseTotal from '@/components/Bilan/DepenseTotal.vue';
import ListDepense from '../components/Bilan/ListDepense.vue';
import SoldeCaisseView from '@/components/Bilan/SoldeCaisse.vue';
import SoldeCaisse from '@/services/caisse/SoldeCaisse';
import { mapGetters } from 'vuex'
export default {
  name: "ComptaView",
  components: {
    SideBare,
    ToolBare,
    ComptaChips,
    BilanEntreVenteView,
    BilanApproView,
    BilanDepenseBenefice,
    DepenseTotal,
    ListDepense,
    SoldeCaisseView
  },
  data: () => ({
    listDepense: [],
    total_Vente: '',//Total vente par jour
    total_maint: '',//Total maintenance par jour
    /**
     * Variable pour declacher le chargement de la depense total dans le component DepenseTotal
     */
    launchTotalLoad: false,
  }),
  created() {
    this.makeLinke()
    this.initialize()
  },
  watch: {
    listDepense() {
      this.launchTotalLoad = !this.launchTotalLoad
    },
  },
  computed: {
    lang() {
      return this.$store.state.locale
    },
    userIsMaster() {
      return this.isMaster()
    },
  },
  methods: {
    ...mapGetters(['getAccessToken', 'getEntreprise', 'isMaster']),
    makeLinke() {
      this.cmp = window.location.pathname.split('/').pop()
    },

    initialize() {
      SoldeCaisse.getDepense(this.getEntreprise().id, this.getAccessToken())
      SoldeCaisse.soldeCaisse(this.getEntreprise().id, this.getAccessToken())
      SoldeCaisse.soldeBenefice(this.getEntreprise().id, this.getAccessToken())
      SoldeCaisse.venteJour(this.getEntreprise().id, this.getAccessToken())
      SoldeCaisse.maintenanceJour(this.getEntreprise().id, this.getAccessToken())
    },
  },

}
</script>

<style></style>