import ApiServiceD from "@/classes/ApiServiceD";
import store from "@/store";
import moment from "moment";
export default class SoldeCaisse{
    static async soldeAppro(entreprise_id,token=null){
        let todayDate = moment(new Date()).format('YYYY-MM')
        let soldeAppro =0
        return new ApiServiceD().getList('caisse/solde/appro/',{entreprise: entreprise_id},token)
        .then(rep=>{
            rep.data.forEach(el => {
                if (moment(el.date_appro).format('YYYY-MM') == todayDate){
                    soldeAppro += parseInt(el.pau)
                }
            });
            store.dispatch('comptable/setSoldeAppro', soldeAppro)
            store.dispatch('comptable/setListAppro',rep.data)
        })
        .catch((err)=>{
            console.log(err.data)
        })
    }
    static async registerDepense(data=null,token=null){
        return new ApiServiceD().add('depense/register/',data, null ,token)
        .then(rep=>{
            console.log(rep.data)
            store.dispatch('comptable/setListDepense',rep.data)
        })
        .catch((err)=>{
            console.log(err.data)
        })
    }
    static async returnDepense(data=null, depense_id,token=null){
        return new Promise(function (resolve, reject) {
            new ApiServiceD()
            .update('depense/return-depense/',data, {depenseID: depense_id} ,token)
              .then((rep) => {
                resolve(rep)
                store.dispatch('comptable/updateListDepense',rep.data)
                console.log("Test value updated", rep.data.source);
                console.log(parseInt(rep.data.source) == 1);
                if(parseInt(rep.data.source) == 1){
                    store.dispatch('comptable/setSoldeCaisse',rep.data.new_solde);
                }else{
                    store.dispatch('comptable/setSoldeBenefice',rep.data.new_solde);
                }
              })
              .catch((err) => {
                reject(err);
              });
          });
    }
    static async getDepense(entreprise_id,token=null){
        return new ApiServiceD().getList('depense/register/',{entreprise: entreprise_id},token)
        .then(rep=>{
            console.log(rep.data)
            store.dispatch('comptable/setListDepense',rep.data)
        })
        .catch((err)=>{
            console.log(err.data)
        })
    }
    static async soldeCaisse(entreprise_id,token=null){
        return new ApiServiceD().getList('caisse/solde/',{entreprise: entreprise_id},token)
        .then(rep=>{
            store.dispatch('comptable/setSoldeCaisse',rep.data.solde)
        })
        .catch((err)=>{
            console.log(err.data)
        })
    }
    static async soldeBenefice(entreprise_id,token=null){
        return new ApiServiceD().getList('caisse/solde/benefice/',{entreprise: entreprise_id},token)
        .then(rep=>{
            store.dispatch('comptable/setSoldeBenefice',rep.data.solde)
        })
        .catch((err)=>{
            console.log(err.data)
        })
    }
    static async venteJour(entreprise_id,token=null){
        return new ApiServiceD().getList('caisse/solde/vente/jour/',{entreprise: entreprise_id},token)
        .then(rep=>{
            let todayDate = moment(new Date()).format('YYYY-MM-DD')
            let totalVente =0, totalIncome =0;
            rep.data.forEach(el => {
                if(moment(todayDate).isSame(el.registred_at)){
                    console.log("Prix vente: "+el.prix_vente)
                    totalVente += parseFloat(el.prix_vente)
                    totalIncome += parseFloat(el.income)
                }
            });
            store.dispatch('comptable/setListVente',rep.data)
            store.dispatch('comptable/setTotalVente',totalVente)
            store.dispatch('comptable/setTotalIncome',totalIncome)
        })
        .catch(err=>{
            console.log(err.data)
        })
    }
    static async maintenanceJour(entreprise_id,token=null){
        return new ApiServiceD().getList('caisse/solde/maintenance/jour/',{entreprise: entreprise_id},token)
        .then(rep=>{
            let totalMaint = 0, totalIncome = 0;
            let todayDate = moment(new Date()).format('YYYY-MM-DD')
            rep.data.forEach(el => {
                if(moment(todayDate).isSame(el.registred_at)){
                    totalMaint += parseFloat(el.total_payer)
                    if(parseFloat(el.prix_pieces) > 0){
                        totalIncome += (parseFloat(el.total_apayer) - parseFloat(el.prix_pieces))
                    }
                }
            });
            store.dispatch('comptable/setListMaintenance',rep.data)
            store.dispatch('comptable/setTotalMaintenance',totalMaint)
            store.dispatch('comptable/setTotalIncomeMaintenance',totalIncome)
        })
        .catch(err=>{
            console.log(err.data)
        })
    }
}
