<template>
    <div>
        <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="register">
            <v-row>
                <v-col cols="12" class="mt-n4">
                    <v-text-field v-model="Form.username" :rules="requireRules" label="Nom d'utilisateur" required>
                    </v-text-field>
                </v-col>
                <v-col cols="12" class="mt-n4">
                    <v-text-field v-model="Form.first_name" :rules="requireRules" label="Prénom(s)" required>
                    </v-text-field>
                </v-col>
                <v-col cols="12" class="mt-n4">
                    <v-text-field v-model="Form.last_name" :rules="requireRules" label="Nom" required>
                    </v-text-field>
                </v-col>
                <v-col cols="12" class="mt-n4">
                    <v-text-field v-model="Form.telephone" :rules="requireRules" label="Contact" required>
                    </v-text-field>
                </v-col>
                <v-col cols="12" class="mt-n4">
                    <v-text-field v-model="Form.email" :rules="requireRules" label="Email" required>
                    </v-text-field>
                </v-col>
            </v-row>

            <div class="left">
                <v-btn :disabled="!valid" text color="success" class="mr-4" @click="update">
                    Validate
                </v-btn>

                <v-btn color="red darken-1" text class="mr-4" @click="clean">
                    Reset Form
                </v-btn>
            </div>
        </v-form>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import UserService from '@/services/UserService.js'
export default {
    components: {},
    data: () => ({
        user: null,
        valid: true,//Form validation
        requireRules: [
            v => !!v || 'Ce champ est obligatoire',
        ],
        Form: {
            username: null,
            first_name: null,
            last_name: null,
            telephone: null,
            email: null,
        },
    }),
    created() {
        this.initialize()
    },
    methods: {
        ...mapGetters(['getUser', 'getAccessToken', 'getEntreprise']),
        initialize() {
            this.Form.username = this.getUser().username
            this.Form.first_name = this.getUser().first_name
            this.Form.last_name = this.getUser().last_name
            this.Form.telephone = this.getUser().tel
            this.Form.email = this.getUser().email
        },
        update() {
            UserService.updateUser(this.Form, this.getEntreprise().id, this.getUser().id, this.getAccessToken())
                .then(() => {
                    this.$emit('updatedSucces')
                })
        },
        clean() {
            this.Form.username = null,
                this.Form.first_name = null,
                this.Form.last_name = null,
                this.Form.telephone = null,
                this.Form.email = null
        },
    },
}
</script>