import ApiService from "./ApiService";

export default class ApiServiceD extends ApiService {
  /**
   * Executes post request
   * @param {String} url
   * @param {object} data
   * @param {String} token
   * @param {Dict} params
   */
  create(url, data, params = null, token = null) {
    return this.axiosInstance().post(url, data, {
      params: params,
      headers: {
        Authorization: `Token ${token}`,
      },
    });
  }
  /**
   * Executes put request
   * @param {String} url
   * @param {object} data
   * @param {String} token
   * @param {Dict} params
   */
  update(url, data, params = null, token = null) {
    return this.axiosInstance().put(url, data, {
      params: params,
      headers: {
        Authorization: `Token ${token}`,
      },
    });
  }

  /**
   *
   * @param {String} url
   * @param {String} token
   * @param {Dict} param
   */
  delete(url, param, token=null) {
    return this.axiosInstance().delete(url, {
      params: param,
      headers: {
        Authorization: `Token ${token}`,
      },
    });
  }
  /**
   *
   * @param {String} url
   * @param {String} token
   * @param {Dict} param
   */
  getList(url, param, token=null) {
    return this.axiosInstance().get(url, {
         params: param,
         headers: {
             Authorization: `Token ${token}`
         }
        
    })
  }

  localFormat(amount) {
    return amount.toLocaleString(["fr-FR", "en-EN"]);
  }
}
