export default{
    namespaced: true,
    state: {
        listDette: [],
        totalNomPayer: 0,
        montantTotalDette: 0,
        listUpdated: false,
        dialogDette: false,
    },
    
    getters: {
        allDettes:(state) => state.listDette,
    
        totalDette:(state)=> state.totalNomPayer,
        
        maintUpdated:(state) => state.listUpdated,

        getDialogDette:(state) => state.dialogDette,

        getMontantTotalDette:(state) => state.montantTotalDette,
    },
    
    mutations: {
        SET_DETTES(state, data){
            state.listDette = data.list
            state.totalNomPayer = data.total
            state.listUpdated = ! state.listUpdated
        },
        SET_LIST_UPDATE:(state) => state.listUpdated = ! state.listUpdated,
        UPDATE_DETTES(state, updatedMaint){
            state.listDette.filter((maint) => {
                if(maint.id == updatedMaint.id){
                    maint.total_apayer= updatedMaint.total_apayer;
                    maint.total_payer= updatedMaint.total_payer;
                    maint.rest_apayer= parseFloat(updatedMaint.total_apayer)-parseFloat(updatedMaint.total_payer);
                }
            })
            /**
             * We filter the listDette and remove the items that no reminding
             * price rest_apayer = 0
             */
            state.listDette = state.listDette.filter(rep => 
                parseFloat(rep.total_apayer) - parseFloat(rep.total_payer) !==0),
    
            state.totalNomPayer = state.listDette.length
            console.log("Total Dette: ",state.totalNomPayer);
            state.listUpdated = ! state.listUpdated
        },

        SET_DIALOG_DETTE:(state, value) => state.dialogDette = value,

        SET_MONTANT_TOTAL_DETTE:(state, value) => state.montantTotalDette = value
            
    },
    actions: {
        async fetchDette({commit}, payload) {
            commit('SET_DETTES',payload)
        },
    
        async updateDette({commit}, payload) {
            commit('UPDATE_DETTES', payload)
        },

        updateListDette:({commit}) => commit('SET_LIST_UPDATE'),

        assignMontantTotalDette:({commit}, payload) => commit('SET_MONTANT_TOTAL_DETTE', payload),

        switchDialogDette:({commit}, payload) => commit('SET_DIALOG_DETTE', payload)
    }
}