

export default{
    namespaced: true,
    state:{
        listAppareil: [],
        appareil: '',
    },
    getters:{
        getListAppareil:(state) => state.listAppareil,
        getAppreil:(state) => state.appareil,
    },
    mutations : {
        SET_LIST_APPAREIL:(state, payload) => state.listAppareil = payload,
        
        FILTER_LIST_APPAREIL: (state, id) => state.appareil = state.listAppareil.filter(app => app.ticket.id === id)[0], 
    },
    actions : {
        async fetchListAppareil({commit}, payload){
            commit('SET_LIST_APPAREIL', payload)
        },
        async filterAppareil({commit}, payload){
            commit('FILTER_LIST_APPAREIL', payload)
        }
    }
}