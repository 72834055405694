<template>
  <div class="mt-n15">
    <v-divider></v-divider>
    <v-toolbar v-appcolor elevation="0">
      <body>
        <slot name="cbody">Defaut content</slot>
      </body>
    </v-toolbar>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.appcolor {
  background-color: #331f33 !important;
}
</style>