<template lang="">
    <div class="pb-5">
        <v-card-text class="mt-12">
            <h3 class="text-center  text--accent-3" style="color:#114E60;"
             v-t="'loginView.authentification'" />
            <div class="locale-switcher" align="right">
                <v-icon class="flang-icon">fas fa-globe</v-icon>
                <select v-model="$i18n.locale" @change="setLocale">
                    <option :value="locale.code" v-for="locale in locales" :key="locale.code">
                        {{locale.name}}
                    </option>
                </select>
            </div>
            <v-alert v-if="errors.message"
            dense
            :type="errors.type"
            style="height: 60px; padding-to: 10px"
            >
            {{errors.message}} 
            </v-alert>
            <div>
                <v-img id="signimg" src="../../assets/logo/2.png" class="mx-auto mb-5" contain>
            </v-img>
            </div>
            <v-form ref="form" v-model="valid" lazy-validation >
                <v-text-field
                    v-model.trim="Form.username"
                    :label="$t('formField.username')"
                    :name="$t('formField.username')"
                    prepend-icon="person"
                    type="text"
                    color="cyan darken-3"
                    :rules="requireRules" dense outlined required />
                <v-text-field
                    v-model.trim="Form.password"
                    id="password"
                    :label="$t('formField.password')"
                    :name="$t('formField.password')"
                    prepend-icon="lock"
                    type="password"
                    color="cyan darken-3"
                    :rules="requireRules" dense outlined required/>
            </v-form>
            <div class="text-center mb-5">
                <v-btn rounded v-appcolor dark @click="login">{{$t("buttons.login")}}</v-btn>
            </div>
            <p id="noaccount">{{$t("loginView.noaccount")}} ? 
                <router-link id="link" to="/entreprise">{{$t("loginView.register_your_business")}}</router-link>
            </p>
        </v-card-text>
        <LoaderViewVue v-if="loader"/>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import UserService from '@/services/UserService'
import LoaderViewVue from '../LoaderView.vue'
import { getSupportedLocales } from "@/util/i18n/supported-locales"
// import i18n from '@/i18n'
// import Message from '@/services/Message'
export default {
    components: {
        LoaderViewVue
    },
    data() {
        return {
            valid: true,
            loader: false,
            Form: {
                username: "",
                password: "",
            },
            incorrectAuth: false,
            requireRules: [
                v => !!v || "Ce champ est obligatoire",
            ],
            errors: this.getErrors(),
            locales: getSupportedLocales()
        }
    },
    computed: {
        lang: {
            get: function () {
                return this.$store.state.locale
            },
            set: function (newVal) {
                this.$store.dispatch('changeLocale', newVal)
            }
        }
    },
    methods: {
        ...mapActions(['setAccessToken', 'setUser', 'makeErros']),
        ...mapGetters(['getErrors']),
        registeriew() {
            this.$router.push({ path: '/entreprise' })
        },

        async login() {
            if (this.$refs.form.validate()) {
                this.loader = true
                await UserService.login(this.Form)
                    .then((res) => {
                        this.setAccessToken(res.data.tokens.access)
                        this.setUser(res.data.user)
                        this.makeErros('')
                        this.$router.push({ path: '/' })
                    }).catch((err) => {
                        this.loader = false
                        UserService.getErrorMessage(err)
                    })
            }
        },

        setLocale(locale) {
            this.lang = locale.target.value
        }

    },
}
</script>

<style lang="scss">
#noaccount {
    color: #556413;
    font-size: medium;
    font-weight: 600;

    #link {
        text-decoration: none;
        color: #114E60;
    }
}

.locale-switcher {
    .flang-icon {
        zoom: 100%;
        color: skyblue;
        margin-right: 5px;
    }

    select {
        padding: 5px 10px;
        width: 6rem;
        height: 2rem;
        border-radius: 10px 10px 0 0;
        border: 1px solid #114E60;

        option {
            background-color: #114E60;
            padding: 0 10px;
            font-size: 16px;
        }
    }
}

#logo {
    margin-bottom: -8rem;

    #img {
        width: 200px;
    }
}

#signimg {
    height: 150px;
}
</style>