<template>
   <div>
    <v-app-bar color="transparent" dark dense flat>
        <v-chip class="ma-2 respocl" to="/" style="font-size:18px;" pill>
            <span class="d-none d-sm-flex">
                {{$i18n.locale == 'fr' ? "Accueil" : "Home"}}
                <v-icon right small color="white">fa-brands fa-accusoft</v-icon>
            </span>
            <v-icon left color="white" class="d-sm-none" size="25px" >fa-brands fa-accusoft</v-icon>
        </v-chip>
        <v-chip class="ma-2 respocl" @click="lounchDialog" style="font-size:18px;" pill>
            <span class="d-none d-sm-flex">
                {{$i18n.locale == 'fr' ? "Mes contenaire" : "My container"}}
                <v-icon right small color="white">fa-brands fa-accusoft</v-icon>
            </span>
            <v-icon left color="white" class="d-sm-none" size="25px" >fa-brands fa-accusoft</v-icon>
        </v-chip>
    </v-app-bar>
    <DialogListConteneur  />
   </div>
</template>

<script>
import DialogListConteneur from '@/components/DialogListConteneur'
import { mapGetters } from 'vuex';
export default {
    name: 'ProduitChips',
    components:{DialogListConteneur},
    data: () => ({
        link: 'articles',
    }),
    methods: {
        ...mapGetters("conteneur",['getDialogConteneur']), 
        navigateTo(chemin) {
            this.$router.push({ path: chemin }).catch(()=>{});
            this.$emit('linkCliked', chemin)
        },
        lounchDialog(){
            this.$store.dispatch("conteneur/switchDialogConteneur", true)
        },
    }

}
</script>
<style>
@media only screen and (max-width: 600px) {

    /* For mobile phones: */
    .respocl {
        background-color: transparent !important;
        border-radius: 25px;
        display: block;
        margin-left: -20px;
    }
}
</style>