// import moment from "moment"
export default {
    namespaced: true,
    state: {
        listEntreprise: [],
        codeEntreprise: '',
        dialogEntreprise: false,
        singleEntreprise: null,
    },
    getters: {
        getListEntreprise: (state) => state.listEntreprise,
        getCodeEntreprise: (state) => state.codeEntreprise,
        getDialogEntreprise: (state) => state.dialogEntreprise,
        getSingleEntreprise: (state) => state.singleEntreprise,
    },
    mutations: {
        SET_CODE_ENTREPRISE: (state, code) => state.codeEntreprise = code,
        SET_LIST_ENTREPRISE: (state, lists) => state.listEntreprise = lists,
        SET_DIALOG_ENTREPRISE: (state, value) => state.dialogEntreprise = value,
        SET_SINGLE_ENTREPRISE(state, value) {
            state.singleEntreprise = value
        },
    },
    actions: {
        async assignCodeEntreprise({ commit }, payload) {
            commit('SET_CODE_ENTREPRISE', payload)
        },
        async assignListEntreprise({ commit }, payload) {
            commit('SET_LIST_ENTREPRISE', payload)
        },
        async setSingleEntreprise({ commit }, payload) {
            commit('SET_SINGLE_ENTREPRISE', payload)
        },
        switchDialogEntreprise: ({ commit }, payload) => commit('SET_DIALOG_ENTREPRISE', payload)
    }
}