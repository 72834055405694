<template>
  <v-container>
    <v-toolbar flat v-appcolor>
      <v-toolbar-title class="white--text" style="font-size:medium">{{ dateJour }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details filled dense
        rounded class="d-none d-sm-flex  white black--text" style="width:30%; max-width: 30%;">
      </v-text-field>
    </v-toolbar>
    <v-divider></v-divider>
    <div class="d-flex d-sm-none" align="left"
      style="color:cornflowerblue; font-weight:900; font-size:large;font-family:Arial, Helvetica, sans-serif">
      {{ lang == 'fr' ? 'Cout total' : 'Total cost' }}: {{ totalCost | localFormat }}{{ getEntreprise().type_monaie }}
    </div>
    <div class="d-flex d-sm-none" align="left"
      style="color:cornflowerblue; font-weight:900; font-size:large;font-family:Arial, Helvetica, sans-serif">
      {{ lang == 'fr' ? 'Quantité total' : 'Total quantity' }}: {{ quantiteTotal }}
    </div>
    <v-data-table :headers="headers" :items="listeVenteJours" :search="search" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-toolbar-title class="d-none d-sm-flex mr-2">{{ lang == 'fr' ? 'Cout total' : 'Total cost' }}: {{ totalCost |
            localFormat }}{{ getEntreprise().type_monaie }} </v-toolbar-title>
            <v-toolbar-title class="d-none d-sm-flex">
              {{ lang == 'fr' ? 'Quantité total' : 'Total quantity' }}: {{ quantiteTotal }}
            </v-toolbar-title>
          <v-spacer></v-spacer>
            
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details filled dense
            rounded class="d-flex d-sm-none white black--text" size="15px">
          </v-text-field>
          <v-dialog persistent v-model="dialog" max-width="700px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-appcolor dark class="mb-2 d-none d-sm-flex" :sm="false" v-bind="attrs" v-on="on">
                <v-icon>fa-duotone fa-calendar-days</v-icon>
              </v-btn>
              <v-btn fab v-appcolor dark class="ml-3 mt-3 mb-2 d-flex d-sm-none" width="40px" height="40px" v-bind="attrs"
                v-on="on">
                <v-icon>fa-duotone fa-calendar-days</v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-card-title v-appcolor>
                <span class="text-h5">{{ lang == 'fr' ? frFormTitle : enFormTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="6" v-if="toggleSwitch">
                      <DatePiker @selectedDate="setFromDate" />
                    </v-col>
                    <v-col cols="12" md="6" v-if="toggleSwitch">
                      <DatePiker2 @selectedDate="setToDate" />
                    </v-col>
                    <v-col cols="12" v-if="!toggleSwitch">
                      <DatePiker @selectedDate="setFindExactDate" />
                    </v-col>
                  </v-row>

                  <v-switch v-model="toggleSwitch"
                    :label="lang == 'fr' ? 'Chercher entre de dates' : 'Search between dates'" color="#114E60"
                    hide-details>
                  </v-switch>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  {{ $t('buttons.cancel') }}
                </v-btn>
                <v-btn color="blue darken-1" text @click="find">
                  {{ $t('buttons.display') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template `v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)" color="info" title="Editer">
          fa-duotone fa-pen-to-square
        </v-icon>
        <v-icon small @click="deleteItem(item)" color="red" title="Supprimer">
          fa-duotone fa-trash-can
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import DatePiker from '../DatePiker.vue'
import DatePiker2 from '../DatePiker2.vue'
import VenteManager from '@/services/VenteManager'
import moment from 'moment'
import { mapGetters } from 'vuex'
export default {
  components: {
    DatePiker,
    DatePiker2
  },
  data() {
    return {
      dialog: false,
      toggleSwitch: false,
      search: '',
      totalCost: 0,
      quantiteTotal: 0,
      headers: this.$i18n.locale == 'fr' ? [
        { text: 'Article', align: 'start', sortable: true, value: 'article' },
        { text: 'Prix vent unitaire', value: 'pvu', align: "center" },
        { text: 'Quantité', value: 'quantite', align: "center" },
        { text: 'Prix vente total', value: 'prix_vente', align: "center" },
        { text: 'Taux de reduction', value: 'taux', align: "center" },
        // { text: 'Actions', value: 'actions', sortable: false },
      ] : [
        { text: 'Article', align: 'start', sortable: true, value: 'article' },
        { text: 'Unit sales price', value: 'pvu', align: "center" },
        { text: 'Quantity', value: 'quantite', align: "center" },
        { text: 'Total sale price', value: 'prix_vente', align: "center" },
        { text: 'Discount rate', value: 'taux', align: "center" },
        // { text: 'Actions', value: 'actions', sortable: false },
      ],
      fromDate: null,
      toDate: null,
      exactDate: null,
      dateJour: null,

      tmp_list: [],
      listeVenteJours: [],

      listeVente: [],



      editedIndex: -1,
    }
  },

  computed: {
    frFormTitle() {
      return this.editedIndex === -1 ? 'Historique' : ''
    },
    enFormTitle() {
      return this.editedIndex === -1 ? 'Historical' : ''
    },
    lang() {
      return this.$store.state.locale
    },
    refreshed() {
      return this.getAppReloaded()
    },
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    refreshed() {
      this.initialize()
    }
  },
  created() {
    this.initialize()
    this.displayDate(new Date())
  },
  methods: {
    ...mapGetters(['getAccessToken', 'getEntreprise', 'getAppReloaded']),
    /**
     * This metho display display today's date sell
     * The data from this request is in this form:
     * {
     *   id: Integer, data: array(), registred_at: date
     * }
     */
    initialize() {
      VenteManager.listVente(this.getEntreprise().id, this.getAccessToken())
        .then((rep) => {
          // these variable both saves all sell and today's sell
          let sauve = [], sauveToday = [];
          this.totalCost = 0
          this.quantiteTotal = 0
          console.log(rep.data)
          rep.data.forEach(el => {
            // let registred_at = el.registred_at
            // a local variable store today date
            let todayDate = moment(new Date()).format('YYYY-MM-DD')
            let donne;
            if (moment(todayDate).isSame(el.registred_at)) {
              this.totalCost += parseFloat(el.prix_vente)
              this.quantiteTotal += parseInt(el.quantite)
              donne = {
                registred_at: el.registred_at,
                article: el.article,
                prix_vente: el.prix_vente,
                quantite: el.quantite,
                taux: el.taux,
                pvu: el.pvu
              }
              sauveToday.push(donne)
            } else {
              donne = {
                registred_at: el.registred_at,
                article: el.article,
                prix_vente: el.prix_vente,
                quantite: el.quantite,
                taux: el.taux,
                pvu: el.pvu
              }
            }
            sauve.push(donne)
          });
          this.listeVente = sauve
          this.listeVenteJours = sauveToday
        }).catch((error) => {
          console.log(error)
        })
    },
    /**
     * This method is to search in the list to display sells per date
     */
    find() {
      let sauve = [];
      this.totalCost = 0
      if (this.toggleSwitch == false) {
        this.displayDate(this.exactDate)
        if (this.tmp_list.length > 0) {
          this.tmp_list.forEach(el => {
            if (moment(el.registred_at).isSame(this.exactDate)) {
              this.totalCost += parseFloat(el.prix_vente)
              let donne = {
                registred_at: el.registred_at,
                article: el.article,
                prix_vente: el.prix_vente,
                quantite: el.quantite,
                taux: el.taux,
                pvu: el.pvu
              }
              sauve.push(donne)
            }
          });
        } else {
          this.tmp_list = this.listeVente
          this.listeVente.forEach(el => {
            if (moment(el.registred_at).isSame(this.exactDate)) {
              this.totalCost += parseFloat(el.prix_vente)
              this.quantiteTotal += parseInt(el.quantite)
              let donne = {
                registred_at: el.registred_at,
                article: el.article,
                prix_vente: el.prix_vente,
                quantite: el.quantite,
                taux: el.taux,
                pvu: el.pvu
              }
              sauve.push(donne)
            }
          });

        }
      } else {
        this.displayDate(new Date())
        if (this.tmp_list.length > 0) {
          this.tmp_list.forEach(el => {
            if (moment(el.registred_at).isBetween(this.fromDate, this.toDate)) {
              this.totalCost += parseFloat(el.prix_vente)
              this.quantiteTotal += parseInt(el.quantite)
              let donne = {
                registred_at: el.registred_at,
                article: el.article,
                prix_vente: el.prix_vente,
                quantite: el.quantite,
                taux: el.taux,
                pvu: el.pvu
              }
              sauve.push(donne)
            }
            // console.log(el.registred_at)
          });
        } else {
          this.tmp_list = this.listeVente
          this.listeVente.forEach(el => {
            if (moment(el.registred_at).isBetween(this.fromDate, this.toDate)) {
              this.totalCost += parseFloat(el.prix_vente)
              this.quantiteTotal += parseInt(el.quantite)
              let donne = {
                registred_at: el.registred_at,
                article: el.article,
                prix_vente: el.prix_vente,
                quantite: el.quantite,
                taux: el.taux,
                pvu: el.pvu
              }
              sauve.push(donne)
            }
            // console.log(el.registred_at)
          });

        }
      }
      this.listeVente = sauve
      this.listeVenteJours = sauve
      this.close()
    },
    setFromDate(val) {
      this.fromDate = val
    },
    displayDate(date) {
      let todayDate = moment(date).locale(this.lang).format('LLLL')
      this.dateJour = todayDate
    },
    setToDate(val) {
      this.toDate = val
    },
    setFindExactDate(val) {
      this.exactDate = val
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedIndex = -1
      })
    },
  },
}
</script>