<template>
    <v-container>
        <ListClientView />
    </v-container>
</template>

<script>
import ListClientView from "../Client/ListClientView.vue";
export default {
    name: "ClientVue",
    data() {
        return {
            tab: null,
        };
    },
    components: { ListClientView }
}
</script>

<style scoped>
#tab-body{
    background-color: #DDDDDD;
    border-radius: 0px;
}
</style>