<template>
    <v-row justify="center">
        <v-dialog persistent v-model="dialog" width="900px" hide-overlay>
            <v-card>
                <v-card-title v-appcolor>
                    <span class="text-h5 white--text">
                        {{ lang =='fr'? 'Liste conteneur':'Container list'}}
                    </span>
                    <v-spacer></v-spacer>
                    <v-btn v-appcolor dark class="ml-2 mb-2" @click="initialize" :loading="isLoading">
                        <v-icon>fas fa-rotate</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-data-table :headers="detailHeaders" :search="search" :items="listConteneur" class="elevation-1">
                        <template v-slot:top>
                           
                            <v-row class="mt-4">
                                <v-col cols="12" md="6">
                                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details filled
                                        dense rounded class="d-flex white black--text" size="15px">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" md="6" style="display: flex;">
                                    <v-text-field v-model="libelle" 
                                    :label="lang =='fr'? 'Désignation du conteneur':'Container designation'" single-line hide-details filled
                                        dense class="d-flex white black--text mr-2" size="15px">
                                    </v-text-field>
                                    <v-btn v-appcolor text @click="register" v-if="!updateOption">
                                        {{ lang =='fr'? 'Enregistrer':'Save'}}
                                    </v-btn>
                                    <v-btn v-appcolor text @click="update" v-if="updateOption">
                                        {{ lang =='fr'? 'Modifier':'Update'}}
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <hr>
                        </template>
                        <template v-slot:[`item.total_article`]="{ item }">
                            <v-badge
                                color="error"
                                :content="item.total_article"
                                inline
                            ></v-badge>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-icon small class="mr-2" @click="editItem(item)" color="info" title="Editer">
                                fa-duotone fa-pen-to-square
                            </v-icon>
                            <v-icon small :disabled="isLoadingArticle" class="mr-2" @click="loadArticleInContener(item)" color="success" title="Editer">
                                fas fa-eye
                            </v-icon>
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="closeDialog">{{ $t('buttons.close') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <DialogListArticleInContener />
    </v-row>
</template>
<script>
import ConteneurManager from '@/services/Conteneur';
import DialogListArticleInContener from '@/components/DialogListArticleInContener'
import { mapGetters } from 'vuex';
export default {
    name: "DialogMaintenace",
    components: {
        DialogListArticleInContener
    },
    data (){
        return{
            totalCout: 0,
            search: '',
            dateAchat: null,
            libelle:null,
            updateOption:false,
            isLoading:false,
            isLoadingArticle: false,
            selectedID: null,
            listConteneur: [],
            detailHeaders: this.$i18n.locale == 'fr' ?[
                { text: 'CONTENEUR', align: 'start', sortable: true, value: 'libelle'},
                { text: 'TOTAL ARTICLE', value: 'total_article' },
                { text: 'DATE D\'ENREGISTREMENT ', value: 'created_at' },
                { text: 'Actions', value: 'actions', sortable: false },

            ]
            :[
                { text: 'CONTAINER', align: 'start', sortable: true, value: 'libelle'},
                { text: 'TOTAL ARTICLE ', value: 'total_article' },
                { text: 'REGISTERETION DATE ', value: 'created_at' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],

        }
    },
    computed:{
        dialog(){
            return this.getDialogConteneur()
        },
        lang() {
            return this.$store.state.locale
        },
    },
    created() {
        this.initialize()
    },
    methods: {
        ...mapGetters("conteneur",['getDialogConteneur']), 
        ...mapGetters(['getAccessToken', 'getEntreprise']),
        closeDialog(){
            this.$store.dispatch("conteneur/switchDialogConteneur", false)
        },
        initialize(){
            this.isLoading = true;
           ConteneurManager.conteneurListe(this.getEntreprise().id, this.getAccessToken())
           .then(res => {
                this.listConteneur = res.data
                this.isLoading = false
            })
           .catch(error => {
                console.log("error :",error)
            })
        },

        register(){
            if (this.libelle != null) {
                var formData = {
                    'libelle' : this.libelle,
                    'entreprise' : this.getEntreprise().id
                }
                ConteneurManager.registerConteneur(formData, this.getAccessToken())
                .then(() => {
                        this.initialize()
                        this.libelle =null
                    })
                .catch(error => {
                        console.log("error :",error)
                    }) 
            }
           
        },
        update(){
            if (this.libelle != null) {
                var formData = {
                    'libelle' : this.libelle,
                }
                ConteneurManager.updatePiece(formData, this.selectedID, this.getAccessToken())
                .then(() => {
                        this.initialize()
                        this.libelle =null
                        this.selectedID = null
                        this.updateOption = false
                    })
                .catch(error => {
                        console.log("error :",error)
                    }) 
            }
           
        },
        editItem(item){
            this.updateOption = true
            this.libelle = item.libelle
            this.selectedID = item.id
        },
        loadArticleInContener(item){
            this.isLoadingArticle = true
            ConteneurManager.getArticleInContener(item.id, this.getAccessToken())
           .then(res => {
                this.$store.dispatch("conteneur/switchDialogListArticle", true)
                this.$store.dispatch("conteneur/assignlistArticleInContener", res.data)
                this.isLoadingArticle = false
            })
           .catch(error => {
                this.isLoadingArticle = false
                console.log("error :",error)
            })
        },
    }

}
</script>

<style>
.text-info {
    color: darkslategray;
    font-size: 16px;
}
</style>