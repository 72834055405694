<template>
    <v-row justify="center">
        <v-dialog persistent v-model="dialog" width="500px" hide-overlay>
            <v-card>
                <v-card-title v-appcolor>
                    <span class="text-h5 white--text">
                        {{ lang =='fr'? 'Liste Article':'Article ist'}}
                    </span>
                </v-card-title>
                <v-card-text>
                    <v-data-table :headers="detailHeaders" :search="search" :items="listArticle" class="elevation-1">
                        <template v-slot:top>
                           
                            <v-row class="mt-4">
                                <v-col cols="12" md="6">
                                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details filled
                                        dense rounded class="d-flex white black--text" size="15px">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <hr>
                        </template>
                        <template v-slot:[`item.total_article`]="{ item }">
                            <v-badge
                                color="error"
                                :content="item.total_article"
                                inline
                            ></v-badge>
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" text @click="closeDialog">{{ $t('buttons.close') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
// import ConteneurManager from '@/services/Conteneur';
import { mapGetters } from 'vuex';
export default {
    name: "DialogListArticleInContener",
    components: {},
    data (){
        return{
            totalCout: 0,
            search: '',
            libelle:null,
            isLoading:false,
            isLoadingArticle: false,
            selectedID: null,
            listConteneur: [],
            detailHeaders: this.$i18n.locale == 'fr' ?[
                { text: 'ARTICLE', align: 'start', sortable: true, value: 'libelle'},
                { text: 'QUANTITÉ', value: 'qantite_apro' },

            ]
            :[
                { text: 'ARTICLE', align: 'start', sortable: true, value: 'libelle'},
                { text: 'QUANTITY ', value: 'qantite_apro' },
            ],

        }
    },
    computed:{
        dialog(){
            return this.getDialogListArticle()
        },
        listArticle(){
            return this.getlistArticleInContener()
        },
        lang() {
            return this.$store.state.locale
        },
    },
    methods: {
        ...mapGetters("conteneur",['getDialogListArticle', 'getlistArticleInContener']), 
        ...mapGetters(['getAccessToken', 'getEntreprise']),
        closeDialog(){
            this.$store.dispatch("conteneur/switchDialogListArticle", false)
        },
        
    }

}
</script>

<style>
.text-info {
    color: darkslategray;
    font-size: 16px;
}
</style>