// import moment from "moment"
export default{
    namespaced: true,
    state:{
        listArticleInContener:[],
        dialogConteneur: false,
        dialogListArticle: false,
    },
    getters:{
        getlistArticleInContener:(state) => state.listArticleInContener,
        getDialogConteneur:(state) => state.dialogConteneur,
        getDialogListArticle:(state) => state.dialogListArticle,
    },
    mutations:{  
        SET_LIST_ARTICLE_IN_CONTENER:(state, lists) => state.listArticleInContener = lists,
        SET_DIALOG_Conteneur:(state, value) => state.dialogConteneur = value,
        SET_DIALOG_ARTICLE_IN_CONTENER:(state, value) => state.dialogListArticle = value,
    },
    actions:{
        async assignlistArticleInContener({commit}, payload){
            commit('SET_LIST_ARTICLE_IN_CONTENER',payload)
        },
        switchDialogConteneur:({commit}, payload) => commit('SET_DIALOG_Conteneur', payload),
        switchDialogListArticle:({commit}, payload) => commit('SET_DIALOG_ARTICLE_IN_CONTENER', payload),
    } 
}