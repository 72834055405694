<template>
    <v-dialog persistent v-model="dialog" max-width="350px" hide-overlay>
        <v-card>
            <v-card-title v-appcolor>
                <span class="text-h5 white--text">
                    {{ lang =='fr' ? 'Ajouter un nouvel service':'Add a new service' }}
                </span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-form ref="form" v-model="valid" lazy-validation>
                        <v-text-field v-model.trim="Form.libelle" 
                        :label=" lang =='fr' ? 'Nom du service':'Service Name'" 
                        :name=" lang =='fr' ? 'Nom du service':'Service Name'"
                            prepend-icon="person" type="text" color="cyan darken-3" :rules="requireRule" dense outlined
                            required />
                        <fieldset>
                            <legend align="left" style="font-weight:700; color:darkslategray">Composants</legend>

                            <v-list-item-group v-for="(item, i) in listComponents" :key="i">
                                <v-list-item>
                                    <v-list-item-action>
                                        <v-checkbox v-model="Form.components" :value="item.id"
                                            :checked="false"></v-checkbox>
                                    </v-list-item-action>
                                    <v-list-item-content align="left">
                                        <v-list-item-title>{{ item.libelle }}</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </fieldset>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <div class="left">
                    <v-btn color="primary darken-1" text class="mr-2" @click="save" v-if="service_id == null">
                        {{$t('buttons.save')}}
                    </v-btn>
                    <v-btn color="primary darken-1" text class="mr-2" @click="update" v-if="service_id != null">
                        {{$t('buttons.update')}}
                    </v-btn>
                    <v-btn color="red darken-1" text class="mr-2" @click="reset">
                        {{$t('buttons.cancel')}}
                    </v-btn>
                    <v-btn color="red darken-1" text class="mr-2" @click="close">
                        {{$t('buttons.close')}}
                    </v-btn>

                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import ServiceAccesManager from '@/services/ServiceAccesManager';
export default {
    name: "DialogServiceAcces",
    data() {
        return {
            valid: true,
            requireRule: [
                v => !!v || "Ce champ est obligatoire",
            ],
            Form: {
                libelle: "",
                components: []
            },
            listComponents: [],
            service_id: null,
        }
    },
    computed: {
        dialog() {
            return this.getDialogService()
        },
        litsAccessByService() {
            return this.getListAccessByService()
        },
        lang() {
            return this.$store.state.locale
        },
    },
    watch: {
        /**
         * On verifie si c'est pour l'edition quels sont les composant qui sont
         * associés à ce service
         */

        litsAccessByService() {
            this.litsAccessByService.services.components
                .filter(ele => this.Form.components.push(ele.id))
            this.service_id = this.litsAccessByService.services.id;
            this.Form.libelle = this.litsAccessByService.services.libelle
        }
    },
    created() {
        this.initialize()
    },
    methods: {
        ...mapGetters(['getAccessToken', 'getEntreprise']),
        ...mapGetters("serviceaccess", ['getDialogService', 'getListAccessByService']),

        close() {
            this.$store.dispatch('serviceaccess/switchDialogService', false)
            this.$refs.form.reset()
        },
        reset() {
            this.$refs.form.reset()
            this.service_id = null
        },
        initialize() {
            ServiceAccesManager.ListComposant(this.getAccessToken())
                .then((resp) => {
                    this.listComponents = resp
                })
                .catch((err) => {
                    console.log(err.data);
                })
        },
        save() {
            if (this.$refs.form.validate()) {
                ServiceAccesManager.RegisterService(this.Form, this.getEntreprise().id, this.getAccessToken())
                    .then(() => {
                        this.reset()
                        this.$emit('submited')
                    }).catch(() => { });
            }

        },
        update() {
            if (this.$refs.form.validate()) {
                ServiceAccesManager.UpdateService(this.Form, this.service_id, this.getAccessToken())
                    .then(() => {
                        this.reset()
                        this.$emit('submited')
                    }).catch(() => { });
                this.service_id = null;
                this.$store.dispatch('serviceaccess/switchDialogService', false)
            }
        },


    },

}
</script>

<style></style>