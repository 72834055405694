import ApiServiceD from "@/classes/ApiServiceD";
import ApiService from "@/classes/ApiService";
export default class NotesManager{
   static noteListe(author, token=null){
        return new Promise(function(resolve, reject){
            new ApiServiceD().getList('note/register/',{author: author}, token)
            .then((resp)=>{
                resolve(resp);
            }).catch((err)=>{reject(err);})
        })
    }
    static registerNote(formData, token=null){
        return new Promise(function(resolve, reject){
            new ApiService().add('note/register/',formData, null, token)
            .then((resp)=>{
                resolve(resp);
            }).catch((err)=>{reject(err);})
        })
    }
    static updateNote(formData, note_id, token=null){
        return new Promise(function(resolve, reject){
            new ApiServiceD().update('note/register/',formData,
            { noteId: note_id}, token)
            .then((resp)=>{
                resolve(resp);
            }).catch((err)=>{reject(err);})
        })
    }
    static deleteNote(note_id, token){
        return new Promise(function(resolve, reject){
            new ApiServiceD().delete('note/register/', {noteId: note_id}, token)
            .then((resp)=>resolve(resp))
            .catch((err)=>reject(err))
        })
    }
}