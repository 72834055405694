import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import i18n from './i18n'
// import VueConfirmDialog from 'vue-confirm-dialog'
// import VueHtml2Pdf from "vue-html2pdf";
Vue.config.productionTip = false
// let autdata ={access:localStorage.getItem('accessToken'), refresh:localStorage.getItem('refreshToken')}

// store.dispatch('auth/attempt',autdata)

//00303F, 114E60, 2C3333, 1B2430, 01579B, 
Vue.directive('appcolor',{
  bind(el){
    el.style.backgroundColor='#114E60'
    el.style.color='white'
  }
})
Vue.directive('btcolor',{
  bind(el){
    el.style.color='#114E60'
  }
})
Vue.directive('color',{
  bind(el, binding){
    el.style.color=binding
  }
})
Vue.directive('size',{
  bind(el, binding){
    el.style.fontSize = binding.value+'px'
  }
})
// Filter for local format e.g: 1000000 to 1 000 000 GNF
Vue.filter('localFormat', function(params) {
  let result = params.toLocaleString(["fr-FR", "en-EN"]);
  return result+" "
})
Vue.filter('troncateText', function(value, limit = 100) {
  if(!value) return "";
  if(value.length <= limit) return value;
  return value.slice(0, limit) + "..."
})
// Vue.use(VueConfirmDialog)
// Vue.component('vue-confirm-dialog', VueConfirmDialog.default)
// Vue.use(VueHtml2Pdf);
// Vue.filter('localFormat', function(params) {
//   let result = params.toLocaleString(["fr-FR", "en-EN"]);
//   return result+" GNF"
// })
new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
