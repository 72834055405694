<template>
    <v-container fluid id="card">
        <v-row >
            <v-col cols="12" xs="12" class="mb-5 d-sm-block" v-for="(item, i) in $i18n.locale == 'fr' ? frItems: enItems" :key="i">
                <v-card v-appcolor elevation="5" class="rounded-md" >
                    <v-row class="d-xs-block">
                        <v-col cols="6" xs="12" sm="6" md="6">
                            <div class="text-center">
                                <v-progress-circular
                                    :rotate="-90"
                                    :size="100"
                                    :width="15"
                                    :value="nb_piece"
                                    color="orange"
                                    >
                                    {{ nb_piece }}
                                </v-progress-circular>
                            </div>
                        </v-col>
                        <v-col cols="6" xs="12" sm="6" md="6" class="ml-n5">
                            <v-list-item three-line>
                                <v-list-item-content>
                                    <v-img height="100px" contain style="color:whitesmoke"
                                        :src="require(`../assets/icons/${item.image}`)"></v-img>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                    </v-row>
                    <v-row align="center">
                        <v-col v-if="i == 0" cols="12" sm="8" style="color:bisque;  font-size: 1.5rem;">
                            <span>Total article</span>
                        </v-col>
                        <v-col v-if="i == 1" cols="12" sm="8" style="color:bisque;  font-size: 2rem;">
                            <span>{{ nb_accessoire }}</span>
                        </v-col>
                        <v-col v-if="i == 2" cols="12" sm="8" style="color:bisque;  font-size: 2rem;">
                            <span>{{ nb_appareil }}</span>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-container>

</template>

<script>
import ArticleManager from '@/services/ArticleManager';
import { mapGetters } from 'vuex'
export default {
    name: "DashboardVue",
    data() {
        return {
            nb_piece: 0,
            nb_piece2: 50,
            nb_accessoire: 0,
            nb_appareil: 0,
            icon_like: '',
            baseUrl: '',
            interval: {},
            token: localStorage.getItem('accessToken'),
            frItems: [
                { text: "TOTOAL PIECES", image: "total_piece3.png" },
            ],
            enItems: [
                { text: "TOTAL PARTS", image: "total_piece3.png" },
            ],
            donne: [this.nb_piece, this.nb_accessoire, this.nb_appareil]

        }
    },
    created() {
        this.loadArticle()
        this.getUrl()
    },
    methods: {
        ...mapGetters(['getAccessToken', 'isAuthenticated', 'getEntreprise']),
        ...mapGetters('appareils', ['getListAppareil', 'isAuthenticated']),
        loadArticle() {
            //Definir cette method dans ArticleManager ???????
            ArticleManager.allArticle(this.getEntreprise().id, this.getAccessToken())
                .then((rep) => {
                    this.nb_piece = rep.data.length;
                })
                .catch((err) => {
                    console.log(err.data)
                })
        },
        getUrl() {
            console.log(this.baseUrl);
            this.baseUrl = window.document.location.href
        },
    },

}
</script>

<style>
#card {
    margin-top: -3rem;
}
</style>