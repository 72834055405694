<template>
  <v-row>
    <v-dialog v-model="dialog" max-width="300" hide-overlay transition="dialog-bottom-transition">

      <v-card>

        <v-toolbar dark v-appcolor>
          <v-toolbar-title>Settings</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="close">
              <v-icon>fa-solid fa-xmark</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-autocomplete v-model="Form.service" :items="clientList" item-value="services.id" item-text="services.libelle"
                auto-select-first chips clearable label="Service" :rules="requireRules">
            </v-autocomplete>
            <div class="left">
                <v-btn  text color="success" class="mr-4" @click="register">
                    {{ $t('buttons.save') }}
                </v-btn>
            </div>
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
// import ComponentRoleService from '@/services/ComponentRoleService.js'
import ServiceAccesManager from '@/services/ServiceAccesManager'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      clientList: [],
      valid: true,
      requireRules: [
        v => !!v || "Ce champ est obligatoire",
      ],
      Form:{
        service:'',
        account:'',
      },
    }
  },
  
  computed: {
    dialog() {
      return this.getShowDialogRoles()
    },
  },
  watch:{
   
  },
  created() {
    this.initilize()
  },
  
  methods: {
    ...mapGetters(['getShowDialogRoles', 'getAccessToken', 'getEntreprise','getSeletedUser']),

    initilize(){
      ServiceAccesManager.listServiceAcces(this.getEntreprise().id, this.getAccessToken())
      .then((resp) => {
        console.log(resp);
        this.clientList = resp
      })
    },
    close(){
      this.$store.commit('closeDialog')
      this.$refs.form.reset()
    },
    register(){
      if (this.$refs.form.validate()) {
        this.Form.account = this.getSeletedUser().user.id
        ServiceAccesManager.AddUserToService(this.Form, this.getAccessToken())
        .then(() => {
          this.$emit('submited')
          this.close()
        })
      }
    },
  },
}
</script>