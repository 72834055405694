<template>
    <v-app id="contai">
        <SideBare />
        <v-container class="mt-n14" 
        style="background-color: whitesmoke; padding: 2rem 0;">
            <v-row>
                <v-spacer></v-spacer>
                <v-btn class="mr-3" @click="showDialog">
                    {{ lang == 'fr' ? 'Novelle Note':'New Note' }}
                </v-btn>
                <v-btn class="mr-3" @click="switchIsGrip">
                    <v-icon v-if="isGrip">fas fa-grip</v-icon>
                    <v-icon v-if="!isGrip">fas fa-list</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
            </v-row>
            <!-- Show card -->
        <v-row class=" ml-5 mr-5" v-if="isGrip">
            <v-col cols="12" md="3" v-for="(item, index) in notes" :key="index">
                <v-card class="card" elevation="3" >
                    <v-card-title class="card-title">{{ item.title | troncateText(20)}}</v-card-title>
                    <v-card-subtitle class="card-subtitle">
                        <i>{{ item.created_at }}</i>
                    </v-card-subtitle>
                    <v-card-text class="card-text">
                        {{ item.content }}
                    </v-card-text>
                    <v-card-actions class="card-actions">
                        <v-spacer></v-spacer>
                        <v-icon @click="viewNote(item)" class="ml-3 icon" size="16px" color="#114E60">fas fa-eye</v-icon>
                        <v-icon @click="editNote(item)" class="ml-3 icon" size="16px" color="#114E60">fas fa-pen-to-square</v-icon>
                        <v-icon @click="deleteNote(item)" class="ml-3 icon" size="16px" color="red">fas fa-trash-can</v-icon>
                    </v-card-actions>
                </v-card>  
            </v-col>
        </v-row>
        <!-- End Card -->
        <!-- Show in list -->
        <v-container>
            <v-data-table v-if="!isGrip" :headers="noteHeaders" :search="search" :items="notes" class="elevation-1 mt-5">
                <template v-slot:top>
                    <v-row>
                        <v-spacer></v-spacer>
                        <v-col cols="12" md="4">
                            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details filled
                            dense rounded class="d-flex white black--text" size="15px">
                            </v-text-field>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:[`item.title`]="{ item }">
                    {{ item.title | troncateText(20)}}
                </template>
                <template v-slot:[`item.content`]="{ item }">
                    {{ item.content | troncateText(80)}}
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon @click="viewNote(item)" class="ml-3 icon" size="16px" color="#114E60">fas fa-eye</v-icon>
                    <v-icon @click="editNote(item)" class="ml-3 icon" size="16px" color="#114E60">fas fa-pen-to-square</v-icon>
                    <v-icon @click="deleteNote(item)" class="ml-3 icon" size="16px" color="red">fas fa-trash-can</v-icon>
                </template>   
            </v-data-table>
        </v-container>
        <!-- End list -->
         <!-- Dialog create Note form -->
        <v-dialog persistent v-model="dialog" width="500px" hide-overlay>
            <v-card>
                <v-card-title v-appcolor>
                    <span class="text-h5 white--text">
                        {{ lang =='fr'? 'Prise de Note':'Note Taking'}}
                    </span>
                    <v-spacer></v-spacer>
                    <v-icon color="white" size="30" @click="closeDialog">fas fa-circle-xmark</v-icon>
                </v-card-title>
                <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="register">
                    <v-card-text>
                        <v-text-field v-model="Form.title"
                        :label="lang == 'fr' ? 'Titre' : 'Title'"
                        required>
                        </v-text-field>

                        <v-textarea clearable filled auto-grow v-model="Form.content"
                            :label="lang == 'fr' ? 'Contenu ici' : 'Content here'" 
                            rows="8" row-height="30">
                        </v-textarea> 
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn type="submit" color="info darken-1" text :loading="isLoading">{{ $t('buttons.save') }}</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
         <!-- End Note form -->
         <!-- View Note -->
         <v-dialog
            v-model="dialogViewNote"
            width="500"
            persistent
            >
            <v-card>
                <v-card-title v-appcolor>
                    <span class="text-h5">{{ Form.title }}</span>
                    <v-spacer></v-spacer>
                    <v-icon color="white" size="30" @click="dialogViewNote = false">fas fa-circle-xmark</v-icon>
                </v-card-title>
                <v-card-text style="color: #000; text-align: justify;">
                    {{ Form.content }}
                </v-card-text>
                <!-- <v-card-actions>
                <v-spacer></v-spacer>
                    <v-btn color="green-darken-1" variant="text">
                        Disagree
                    </v-btn>
                </v-card-actions> -->
            </v-card>
            </v-dialog>
         <!-- End view Note -->
        <ConfirmAlert @confirmed="isConfirmed"/>
        </v-container>
    </v-app>
</template>
<script>
import SideBare from "../components/SideBare.vue";
import moment from "moment";
import { mapGetters } from "vuex";
import NotesManager from '@/services/Notes';
import ConfirmAlert from '@/components/Alerts/ConfirmAlert.vue';
export default {
    name: "NoteBook",
    data: () => ({
        valid: false,
        isGrip: true,
        updateOptions: false,
        isLoading: false,
        search: '',
        dialog: false,
        dialogViewNote: false,
        noteHeaders:[
            { text: 'TITRE', align: 'start', sortable: true, value: 'title'},
            { text: 'CONTENUE', value: 'content' },
            { text: 'Actions', value: 'actions', sortable: false },
        ],
        notes:[],
        selectedId:'',
        selectedIdex:'',
        selectedItem:{},
        Form:{
            title:'',
            content:'',
            author: '',
        },
    }),
    components: {
        SideBare,
        ConfirmAlert
    },
    computed: {
        lang() {
            return this.$store.state.locale
        },
    },
    created(){
        this.initialize() 
    },
    methods: {
        ...mapGetters(['getAccessToken','getEntreprise','getUser']),
        initialize(){
            NotesManager.noteListe(this.getUser().id, this.getAccessToken())
            .then(response =>{
                response.data.forEach(ele => {
                    ele.created_at = moment(ele.created_at).format('DD-MM-YYYY')
                });
                this.notes = response.data.reverse();
            } )
            .catch(error => console.log("error :",error))
        },
        register(){
            if (this.$refs.form.validate()) {
                if (this.Form.title.length <= 50) {
                    this.isLoading = true;
                    if (! this.updateOptions ) {
                        this.Form.author = this.getUser().id
                        NotesManager.registerNote(this.Form, this.getAccessToken())
                        .then(response => {
                            this.closeDialog()
                            this.notes.unshift(response.data)
                        })
                        .catch(error => {
                            console.log("error :",error), 
                            this.isLoading = false
                        })
                    }else{
                        NotesManager.updateNote(this.Form, this.selectedId, this.getAccessToken())
                        .then(response => {
                            response.data.created_at = moment(response.data.created_at).format('DD-MM-YYYY')
                            this.notes.splice(this.selectedIdex, 1, response.data)
                            this.closeDialog()
                        })
                        .catch(error => console.log("error :",error))
                    }
                } else {
                    var message = this.lang =='fr'?
                    "Le titre ne doit pas dépasser 50 caractères":
                    "The title must not exceed 50 characters"
                    this.errorMessage(message)
                }
                
                
            }
            
        },
        deleteNote(item){
            this.$store.dispatch('alerts/switchConfirmation',true)
            this.selectedItem = item
        },

        isConfirmed(value){
            if (value) {
                NotesManager.deleteNote(this.selectedItem.id, this.getAccessToken())
                .then(() => {
                    this.notes = this.notes.filter(note => note.id != this.selectedItem.id)
                })
                .catch(error => console.log("error :",error))
            }
        },
        editNote(item){
            this.selectedIdex = this.notes.indexOf(item)
            this.selectedId = item.id
            this.Form.title = item.title
            this.Form.content = item.content
            this.updateOptions = true
            this.showDialog()
        },
        viewNote(item){
            this.Form.title = item.title
            this.Form.content = item.content
            this.dialogViewNote = true
        },
        switchIsGrip(){
            this.isGrip = !this.isGrip
        },
        closeDialog(){
            this.clean()
            this.updateOptions = false
            this.isLoading = false
            this.dialog = false
        },
        showDialog(){
            this.dialog = true
        },
        clean(){
            this.Form.title = ""
            this.Form.content = ""
        },
        errorMessage(message) {
            this.$toast.error(message, {
                position: "bottom-right",
                timeout: 6000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: false,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false
            });
        },
    },
    
}
</script>
<style lang="scss">
.card{
    .card-title{
        margin-right: 10px;
        font-size: small;
    }
    .card-subtitle{
        text-align: right;
        line-height: -5;
    }
    .card-text{
        height: 100px;
        text-align: left;
        overflow: auto;
    }
}
</style>