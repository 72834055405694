// import moment from "moment"
export default{
    namespaced: true,
    state:{
        // Approvision
        listAppro: [],
        totalAppro: 0,
        // Depenses
        listDepense: [],
        soldeDepense: 0,
        depenseUpdated: false,
        // Caisses
        soldeCaisse: 0,
        // Benefices
        soldeBenefice: 0,
        // List vente
        listeVente: [],
        soldeVente: 0,
        incomeVent: 0,
        // Maintenances
        listeMaintenance:[],
        soldeMaintenance: 0,
        incomeMaintenance: 0,


    },
    getters:{
        getSoldeAppro:(state) => state.totalAppro,
        getListAppro:(state) => state.listAppro,

        getListDepense:(state) => state.listDepense,
        getSoldeDepense:(state) => state.soldeDepense,
        
        getDepenseUpdated:state => state.depenseUpdated,

        getSoldeCaisse:(state) => state.soldeCaisse,
        getSoldeBenefice:(state) => state.soldeBenefice,
        // Ventes
        getListVent:(state) => state.listeVente,
        getSoldeVente:(state) => state.soldeVente,
        getIncomeVent:(state) => state.incomeVent,
        // Maintenances 
        getListMaintenance:(state) => state.listeMaintenance,
        getSoldeMaintenance:(state) => state.soldeMaintenance,
        getIncomeMaintenance:(state) => state.incomeMaintenance,

    },
    mutations:{
        SET_LIST_APPRO(state, data){
             state.listAppro = data
        },

        SET_TOTAL_APPRO(state, data){
            state.totalAppro = data
        },

        SET_LIST_DEPENSE:(state, data) => state.listDepense = data,

        CALCULATE_TOTAL_DEPENSE(state, data){
            state.soldeDepense = 0
            state.depenseUpdated = !state.depenseUpdated
            data.forEach(el => {
                state.soldeDepense += parseFloat(el.amount)
            });
        },
        UPDATE_LIST_DEPENSE(state, data){
            console.log("New data sfaf :",data);
            state.listDepense.forEach((el) => {
                if(el.id == data.id) {
                    el.amount = data.amount
                }
            });
            if (data.amount == 0) {
                state.listDepense.filter((el) => el.amount > 0)
            }
            state.soldeDepense = 0
            state.listDepense.forEach(el => {
                state.soldeDepense += parseFloat(el.amount)
            });
            // state.soldeDepense -= parseFloat(data.amount)
        },
        SET_SOLDE_CAISSE:(state, solde) => state.soldeCaisse = solde,
        SET_SOLDE_BENEFICE:(state, solde) => state.soldeBenefice = solde,
        // Ventes
        SET_LIST_VENT:(state, data) => state.listeVente = data,
        SET_TOTAL_VENT:(state, data) => state.soldeVente = data,
        SET_INCOME_VENT:(state, data) => state.incomeVent = data,
        // Maintenances
        SET_LIST_MAINTENANCE:(state, data) => state.listeMaintenance = data,
        SET_TOTAL_MAINTENANCE:(state, data) => state.soldeMaintenance = data,
        SET_INCOME_MAINTENANCE:(state, data) => state.incomeMaintenance = data,

    },
    actions:{
        async setListAppro({commit}, payload){
            commit('SET_LIST_APPRO', payload)
        },
        async setSoldeAppro({commit}, payload){
            commit('SET_TOTAL_APPRO', payload)
        },
        async setListDepense({commit}, payload){
            commit('SET_LIST_DEPENSE', payload.data),
            commit('CALCULATE_TOTAL_DEPENSE', payload.data)
        },
        async updateListDepense({commit}, payload){
            commit('UPDATE_LIST_DEPENSE', payload)
        },
        async setSoldeCaisse({commit}, payload){
            commit('SET_SOLDE_CAISSE', payload)
        },
        async setSoldeBenefice({commit}, payload){
            commit('SET_SOLDE_BENEFICE', payload)
        },
        async setListVente({commit}, payload){
            commit('SET_LIST_VENT', payload)
        },
        async setTotalVente({commit}, payload){
            commit('SET_TOTAL_VENT', payload)
        },
        async setTotalIncome({commit}, payload){
            commit('SET_INCOME_VENT', payload)
        },
        // Maintenances
        async setListMaintenance({commit}, payload){
            commit('SET_LIST_MAINTENANCE', payload)
        },
        async setTotalMaintenance({commit}, payload){
            commit('SET_TOTAL_MAINTENANCE', payload)
        },
        async setTotalIncomeMaintenance({commit}, payload){
            commit('SET_INCOME_MAINTENANCE', payload)
        },
    }
}