// import moment from "moment"
export default{
    namespaced: true,
    state:{
        listComponent:[],
        listRole:[],
        
    },
    getters:{
        getListRole:(state) => state.listRole,

        // getListComponent:(state) => state.listComponent = state.listComponent.filter(el => el.libelle !== 'Master'),  
    },
    mutations:{  
        SET_LIST_ROLE:(state, lists) => state.listRole = lists,
        SET_LIST_COMPONENT:(state, lists) => state.listComponent = lists,
        
    },
    actions:{
        async assignListRole({commit}, payload){
            commit('SET_LIST_ROLE',payload)
        },
        async assignListComponent({commit}, payload){
            commit('SET_LIST_COMPONENT',payload)
        },
        
    }
}