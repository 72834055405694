import ApiServiceD from "@/classes/ApiServiceD";
import store from "@/store";
export default class ArticleManager{
    static listPiece(entreprise_id, token=null){
        return new Promise(function(resolve, reject){
            new ApiServiceD().getList('pieces/register/',{entreprise: entreprise_id}, token)
            .then((resp)=>{
                resolve(resp);
            }).catch((err)=>{reject(err);})
        })
    } 
    static allArticle(entreprise_id, token=null){
        return new Promise(function(resolve, reject){
            new ApiServiceD().getList('pieces/all_article/',{entreprise: entreprise_id}, token)
            .then((resp)=>{
                resolve(resp);
            }).catch((err)=>{reject(err);})
        })
    }
    static listAvailable(entreprise_id, token=null){
        return new Promise(function(resolve, reject){
            new ApiServiceD().getList('pieces/available/',{entreprise: entreprise_id}, token)
            .then((resp)=>{
                resolve(resp);
            }).catch((err)=>{reject(err);})
        })
    }
    static registerPiece(formData ,token){
        return new Promise(function(resolve, reject){
            new ApiServiceD().add('pieces/register/',formData, null, token)
            .then((resp)=>{
                resolve(resp);
            }).catch((err)=>{reject(err);})
        })
    }
    static updatePiece(formData, piece_id, entreprise_id, token=null){
        return new Promise(function(resolve, reject){
            new ApiServiceD().update('pieces/register/',formData,
            { id: piece_id, entreprise: entreprise_id }, token)
            .then((resp)=>{
                resolve(resp);
            }).catch((err)=>{reject(err);})
        })
    }
    //Categories Management
    static addCategory(formData, token){
        return new Promise(function(resolve, reject){
            new ApiServiceD().create('categorie/register/', formData, null, token)
            .then((resp)=>resolve(resp))
            .catch((err)=>reject(err))
        })
    }

    static getCategories(entreprise_id, token=null){
        return new Promise(function(resolve, reject){
            new ApiServiceD().getList('categorie/register/',{entreprise: entreprise_id}, token)
            .then((resp)=>{
                store.dispatch('categorie/fetchListCategorie', resp.data)
                resolve(resp);
            }).catch((err)=>{reject(err);})
        })
    }
    
    static updateCategory(formData, categoriID, token){
        return new Promise(function(resolve, reject){
            new ApiServiceD().update('categorie/register/', formData, {id: categoriID}, token)
            .then((resp)=>resolve(resp))
            .catch((err)=>reject(err))
        })
    }

    static deleteCategory(categoriID, token){
        return new Promise(function(resolve, reject){
            new ApiServiceD().delete('categorie/register/', {id: categoriID}, token)
            .then((resp)=>resolve(resp))
            .catch((err)=>reject(err))
        })
    }

    static uploadImage(formData, selectedID, token){
        console.log("uploadImageID", selectedID);
        return new Promise(function(resolve, reject){
            new ApiServiceD().axiosInstance().post('pieces/image/upload/', formData,{
                param:{id: selectedID},
                headers: {
                    Authorization: `Token ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
               }).then((resp)=>resolve(resp))
               .catch((err)=>reject(err))
            
        })
    }
    
}