<template>
    <div class="log"></div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'LogoutVue',
    created() {
        this.logoutUser()
    },
    methods: {
        ...mapGetters(['logout']),
        logoutUser(){
            this.$store.dispatch('logoutUser')
            this.$router.push({ path: '/login' })
        }
    },
}
</script>

<style>

</style>