<template>
  <div>
    <v-container class="mt-4" fluid>
      <v-row>
        <v-expansion-panels popout>
          <v-expansion-panel v-for="(item, i) in headers" :key="i">
            <v-expansion-panel-header style="color:#006064; font-size: medium; font-weight: 800;">
              {{ item.text }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <component v-bind:is="item.content"></component>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import ComponentRole from '@/components/ComposantRole/ComponentRole.vue';
import ListService from '@/components/ServiceAcces/ListService.vue';
import { mapGetters } from 'vuex'
export default {
  components: {
    'ComponentRole': ComponentRole,
    'ListService':ListService,
  },
  data() {
    return {
      dialog: false,
      headers: this.$i18n.locale == 'fr' ?[
        { text: 'Liste des utilisateurs', content: 'ComponentRole' },
        { text: 'Services et Accès utilisateur', content: 'ListService' },
      ]:[
        { text: 'User list', content: 'ComponentRole' },
        { text: 'Services and User Access', content: 'ListService' },
      ],
      errors: this.getErrors(),
    }
  },
  props: {
    lounchDialog: null
  },
  watch: {
    /**
     * a wather to lounch the dialog
     */
    lounchDialog() {
      this.dialog = true;
    },

  },
  methods: {
    ...mapGetters(['getErrors'])
  },
}
</script>
<style lang="css">
#main {
  background-color: #DDDDDD !important;
}
</style>