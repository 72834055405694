<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      width="400"
    >
      
      <v-card>
        <v-card-title>
          <span class="text-h6">Confirmation</span>
          <v-spacer></v-spacer>
          <v-icon color="primary" size="40">fas fa-question</v-icon>
        </v-card-title>
        <hr>
        <v-card-text height="300" style="color: #000; text-align: left;">
          {{ message }}
          <!-- Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure at eligendi quaerat sunt vitae temporibus cupiditate harum error modi rerum. Omnis voluptatibus saepe, ipsam inventore enim reiciendis officiis facere animi. -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            color="red"
            class="mr-2"
            variant="text"
            @click="confirmDialog"
          >
            Confirm
          </v-btn>
          <v-btn
            small
            color="blue-darken-1"
            variant="text"
            @click="cancel"
          >
            Cancel
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name : "ConfirmAlert",
  computed: {
      dialog() {
          return this.getConfirmation()
      },
      message(){
          return this.$store.state.locale == 'fr'? 
          "Êtes-vous vraimant sûre de vouloir suppiermer":
          "Are realy you sure you want to delete"
      }
  },
  methods:{
      ...mapGetters('alerts',["getConfirmation", "getMessage"]),
      confirmDialog() {
          this.$emit('confirmed', true)
          this.$store.dispatch('alerts/switchConfirmation', false)
      },

      cancel() {
        this.$emit('confirmed', false)
        this.$store.dispatch('alerts/switchConfirmation', false)
      }
  }
}
</script>