<template>
  <v-card id="card_satistique" class="mt-n11">
    <v-card-title id="card_title" v-appcolor>
      <v-spacer></v-spacer>
      {{ $t('dashboard.chartBarTitle') }}
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <Bar :chart-options="chartOptions" :chart-data="chartData" :chart-id="chartId" :dataset-id-key="datasetIdKey"
        :plugins="plugins" :css-classes="cssClasses" :styles="styles" :width="width" :height="height" />
    </v-card-text>
  </v-card>
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
import VenteManager from '@/services/VenteManager'
import { mapGetters } from 'vuex'
export default {
  name: 'BarChart',
  components: {
    Bar
  },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 570
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => { }
    },
    plugins: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      token: localStorage.getItem('accessToken'),
      listCount: [],
      months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      chartData: this.$store.state.locale =='fr' ?{
        labels: [
          'Janvier','Février','Mars','Avirl','Mai','Juin','Juillet','Août','Septembre','Octobre','Novembre','Décembre'
        ],
        datasets: [
          {
            label: 'Vente',
            backgroundColor: '#43A047',
            data: []
          },

        ]
      }:{
        labels: [
          'January','February','Mach','April','May','June','July','August','September','October','November','December'
        ],
        datasets: [
          {
            label: 'Sale',
            backgroundColor: '#43A047',
            data: []
          },
        ]
      },

      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      }
    }
  },
  created() {
    this.loadeVente()
  },
  methods: {
    ...mapGetters(['getAccessToken', 'isAuthenticated', 'getEntreprise']),
    loadeVente() {
      VenteManager.statistiqueVente(this.getEntreprise().id, this.getAccessToken())
        .then((rep) => {
          this.chartData.datasets[0].data = rep.data
        }).catch(err => {
          err.data
        })
    },
  }

}
</script>
<style scopd>
#card_title {
  text-align: center;
  border-bottom: 2px solid gray;
  /* color:#114E60; */
  font-weight: 900;
  font-size: medium;
  font-family: Arial, Helvetica, sans-serif;
}

#card_satistique {
  border-radius: 0px;
  /* margin-top: 15px; */
}
</style>
