<template lang="">
    <v-card  >
        <v-toolbar v-appcolor>
            <v-toolbar-title>
                Categories
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn v-if="!updateOptiion" icon fab color="white" elevation=3 title="Nouvelle categorie" 
                @click="save" :loading="isLoading">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-btn v-if="updateOptiion" icon fab color="white" elevation=3 title="Modifier categorie" 
                @click="update" :loading="isLoading">
                <v-icon>
                    mdi-content-save
                </v-icon>
            </v-btn>
            
        </v-toolbar>
        <div class="px-3">
            <v-text-field v-model="Form.libelle" 
            :label=" lang =='fr'? 'Nom categorie':'Category Name'" single-line hide-details
                dense class="white black--text mb-5 mt-5" size="15px">
            </v-text-field>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details filled
                dense rounded class="white black--text mb-2" size="15px">
            </v-text-field>
        </div>
            <v-data-table 
                :headers="headers" 
                :items="items" 
                :search="search" 
                sort-by="calories" class="elevation-1" 
                >
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="editItem(item)" color="info" title="Editer">
                    fa-duotone fa-pen-to-square
                    </v-icon>
                    <v-icon small @click="dialogDelete(item)" color="red" title="Supprimer">
                    fa-duotone fa-trash-can
                    </v-icon>
                </template>
            </v-data-table>
            <ConfirmAlert @confirmed="confirmDelete" />
    </v-card>
</template>
<script>
import ArticleManager from '@/services/ArticleManager';
import ConfirmAlert from '../Alerts/ConfirmAlert.vue'
import { mapGetters } from 'vuex';
export default {
    name: 'CategorieView',
    components: {
        ConfirmAlert
    },
    data() {
        return {
            valid: true,
            updateOptiion: false,
            dialog: false,
            isLoading: false,
            categoriID: '',
            items: [],
            search: '',
            headers: this.$i18n.locale == 'fr' ? [
                { text: 'Nom Categorie',align: 'start',sortable: true,value: 'libelle',},
                { text: 'Actions', value: 'actions', sortable: false },
            ]:[
                { text: 'Category Name',align: 'start',sortable: true,value: 'libelle',},
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            requireRules: [
                v => !!v || "Ce champ est obligatoire",
            ],
            Form: {
                libelle: '',
                entreprise: '',
            }
        }
    },
    created() {
        this.listCategory();
    },
    computed: {
        lang() {
            return this.$store.state.locale
        },
    },
    methods: {
        ...mapGetters(['getAccessToken', 'getEntreprise']),
        save() {
            if (this.Form.libelle.trim() != '') {
                this.Form.entreprise = this.getEntreprise().id;
                this.isLoading = true;
                ArticleManager.addCategory(this.Form, this.getAccessToken())
                    .then((res) => {
                        this.listCategory()
                        this.Form.libelle = ''
                        this.isLoading = false
                        console.log(res)
                    })
                    .catch((err) => {this.isLoading = false, console.log(err)})
            }

        },
        listCategory() {
            ArticleManager.getCategories(this.getEntreprise().id, this.getAccessToken())
                .then((res) => {
                    console.log(res.data);
                    this.items = res.data;
                })
                .catch((err) => console.log(err))

        },
        editItem(item) {
            this.updateOptiion = true;
            this.Form.libelle = item.libelle;
            this.categoriID = item.id;
        },
        update() {
            this.Form.entreprise = this.getEntreprise().id;
            this.isLoading = true
            ArticleManager.updateCategory(this.Form, this.categoriID, this.getAccessToken())
                .then((res) => {
                    this.items.filter((item) => {
                        if (item.id == res.data.id) {
                            item.libelle = res.data.libelle
                        }
                    })
                    this.$store.dispatch('categorie/fetchListCategorie', this.items)
                    this.Form.libelle = ''
                    this.updateOptiion = false;
                    this.categoriID = '';
                    console.log(res)
                    this.isLoading = false
                })
                .catch((err) => {this.isLoading = false,console.log(err)})
        },
        dialogDelete(item) {
            this.categoriID = item.id;
            this.$store.dispatch('alerts/switchConfirmation',true)
        },
        confirmDelete(value) {
            if (value) {
                this.Form.entreprise = this.getEntreprise().id;
                ArticleManager.deleteCategory(this.categoriID, this.getAccessToken())
                    .then(() => {
                        let newList = [];
                        this.items.filter((item) => {
                            if (item.id != this.categoriID) {
                                newList.push(item);
                            }
                        });
                        this.items = newList;
                        this.$store.dispatch('categorie/fetchListCategorie', this.items)
                        this.categoriID = '';
                    })
                .catch((err) => {console.log(err), this.categoriID = '';})
                
            }
            
        }
    },

}
</script>