<template>
  <v-dialog persistent v-model="dialog" hide-overlay scrollable max-width="800px" style="margin-top: 100vh;">
    <v-card justify="center">
      <v-card-title v-appcolor>
        <v-row>
          <v-col sm="4" md="4" align="left">
            Plan de suscription toto
          </v-col>
          <v-col md="8" align="right">
            <v-icon medium @click="close" color="white" title="Supprimer">
              fas fa-circle-xmark
            </v-icon>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <!-- Form suscription -->
            <v-form ref="formDirect" v-model="valid" lazy-validation>
              <v-text-field :rules="requireRule" v-model="codeEntreprise" :label="$t('formField.codeinscription')"
                required class="mt-4" type="numeric" disabled>
              </v-text-field>
              <v-combobox v-model="selectperiode" :items="$i18n.locale == 'fr' ? periodSuscription : periodSuscription1"
                :label="$t('formField.period')" item-value="id" item-text="text" dense auto-select-first eraseble
                class="mt-8" @change="setSelectPeriode" :rules="requireRule">
              </v-combobox>
            </v-form>
            <v-btn variant="outlined" @click="updateSuscription" class="mt-5" :loading="isLoading">
              {{ $t('buttons.validate') }}
            </v-btn>
            <!-- End form -->
          </v-col>
          <v-col cols="12" md="6">
            <p class="text-h5  text--accent-3" style="color:#114E60;">
              {{ $t('loginView.mode2') }}
            </p>
            <div class="text--primary mode-essai" align="left">
              {{ $t('typeSuscriptioin.mode2') }}
              <br>
              <b>NB: </b> <span>
                {{ $t('typeSuscriptioin.mode2_1') }}
              </span>
            </div>
          </v-col>
        </v-row>


      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <div>

        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Message from "@/services/Message";
import SuscriptionManager from "@/services/Suscription";
import { mapGetters } from "vuex";
export default ({
  data() {
    return {
      valid: true,
      isLoading: false,
      selectperiode: '',
      periodSuscription: [
        { id: 1, text: '1 Mois' },
        { id: 3, text: '3 Mois' },
        { id: 6, text: '6 Mois' },
        { id: 12, text: '1 Ans' },
      ],
      periodSuscription1: [
        { id: 1, text: '1 Month' },
        { id: 3, text: '3 Months' },
        { id: 6, text: '6 Months' },
        { id: 12, text: '1 Year' },
      ],
      formLigneSuscription: {
        periode: "",
        entreprise: "",
      },
      requireRule: [
        v => !!v || 'Ce champ est obligatoire',
      ],
    }
  },
  computed: {
    dialog() {
      return this.getDialogEntreprise()
    },
    codeEntreprise() {
      return this.getCodeEntreprise()
    },
  },
  methods: {
    ...mapGetters("entreprise", ['getDialogEntreprise', 'getCodeEntreprise', 'getSingleEntreprise']),
    close() {
      this.$store.dispatch('entreprise/switchDialogEntreprise', false);
    },
    setSelectPeriode() {
      console.log(this.selectperiode)
      console.log(this.getSingleEntreprise())
      this.formLigneSuscription.periode = this.selectperiode.id
    },
    updateSuscription() {
      if (this.$refs.formDirect.validate() == true) {
        this.isLoading = true
        var data = {
          'duree': this.formLigneSuscription.periode,
          'remindingDuration': 0,
        }
        SuscriptionManager.updateSuscriptionAdmin(data, this.getSingleEntreprise().id)
          .then(() => {
            setTimeout(() => {
              this.isLoading = false;
              this.close();
            }, 5000)

          }).catch((error) => {
            this.$store.dispatch(
              "makeErros",
              Message.createMessage(
                error.response.data.message,
              )
            );
          });
      }
    },
  },
})
</script>
<style>
.list_item {
  border-bottom: 1px solid lightgray;
}

#dette {
  line-height: 20px;
}

#dette:first-child {
  font-weight: 800;
  line-height: -2px;
}
</style>
