import ApiServiceD from "@/classes/ApiServiceD";

export default class ClientManager{
    static listClient(entreprise_id, token=null){
        return new Promise(function(resolve, reject){
            new ApiServiceD().getList('clients/register/',{entreprise: entreprise_id}, token)
            .then((resp)=>{
                resolve(resp);
            }).catch((err)=>{reject(err);})
        })
    }
    static registerClient(formData, token=null){
        return new Promise(function(resolve, reject){
            new ApiServiceD().add('clients/register/',formData, null,token)
            .then((resp)=>{
                resolve(resp);
            }).catch((err)=>{reject(err);})
        })
    }
    static updateClient(formData, entreprise_id, id_client, token=null){
        return new Promise(function(resolve, reject){
            new ApiServiceD().update('clients/register/',formData
            ,{id: id_client, entreprise: entreprise_id}, token)
            .then((resp)=>{
                resolve(resp);
            }).catch((err)=>{reject(err);})
        })
    }
    static deleteClient(id_client, token=null){
        return new Promise(function(resolve, reject){
            new ApiServiceD().delete('clients/register/',{id: id_client}, token)
            .then((resp)=>{
                resolve(resp);
            }).catch((err)=>{reject(err);})
        })
    }
   
}