<template >
    <v-container>
        <FormTemplate>
            <template v-slot:header>
                <v-spacer></v-spacer>
                <v-toolbar-title class="d-none d-sm-flex">
                    {{ lang == 'fr' ? 'Liste dépense mensuelle' : 'Monthly expenditure list' }} 
                </v-toolbar-title>
                <v-toolbar-title class="d-flex d-sm-none" v-size="17">
                    {{ lang == 'fr' ? 'Liste dépense mensuelle' : 'Monthly expenditure list' }} 
                </v-toolbar-title>
                <v-spacer></v-spacer>
            </template>
            <template v-slot:cbody>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details filled
                dense rounded class="white black--text mb-2" size="15px">
            </v-text-field>
            <div class="retourner-argent" v-if="display">
                <v-text-field v-model="Form.amount" :label="lang == 'fr' ? 'Montant à retourner' : 'Amount to put back'"
                    single-line hide-details filled
                dense  class="white black--text mb-2" size="15px">
                </v-text-field>
                <v-btn  class="mr-2" @click="returnDepense" v-appcolor title="Details" :loading="isLoading">
                    <v-icon>
                        fas fa-paper-plane
                    </v-icon>
                </v-btn>
                
            </div>
            <v-data-table 
                :headers="headers" 
                :items="listDepenseMensuelle" 
                :search="search" 
                sort-by="calories" class="elevation-1" 
                >
                <template v-slot:top>
                    <v-dialog persistent v-model="dialog" max-width="500px">
                        <v-card>
                        <v-card-title class="text-h5 mb-2" v-appcolor>
                            {{ lang =='fr'? 'Motif de la dépense':'Reason for expenditure' }} 
                            <v-spacer></v-spacer>
                            <v-icon medium class="mr-2" text @click="closeDialog" color="white" title="Close">
                                fas fa-circle-xmark
                            </v-icon>
                         </v-card-title>
                        <v-card-text style="text-align: start; color: black; font-size: 15px;">
                            <p>
                                {{ motifs }}
                            </p>
                        </v-card-text>
                        </v-card>
                    </v-dialog>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small class="mr-3" @click="showPutBackField(item)" 
                        color="orange" :title="lang == 'fr' ? 'Retourner' : 'Put back'">
                        fas fa-rotate-left
                    </v-icon>
                    <v-icon small @click="detailItem(item)" 
                        color="info" title="Details">
                        fas fa-eye
                    </v-icon>
                </template>
            </v-data-table>
            </template>
        </FormTemplate>
    </v-container>
</template>
<script>
import FormTemplate from '../FormTemplate.vue';
import { mapGetters } from 'vuex';
import SoldeCaisse from '@/services/caisse/SoldeCaisse';
export default {
    components: { FormTemplate},
    data() {
        return {
            search: '',
            dialog: false,
            motifs: '',
            display: false,
            isLoading:false,
            Form:{
                amount: '',
                entreprise: '',
                source:'',
            },
            depenseItemId: '',
            headers: this.$i18n.locale == 'fr' ? [
                {text: 'Montant',align: 'start',sortable: true, value: 'amount'},
                { text: 'Source Financière', value: 'source', align: 'center' },
                { text: 'Actions', value: 'actions', sortable: false },
            ]:[
                {text: 'Amoount',align: 'start',sortable: true, value: 'amount'},
                { text: 'Financial source', value: 'source', align: 'center' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            listDepenseMensuelle:[],
        }
    },
    computed: {
        controleDepense(){
            return this.getDepenseUpdated()
        },
        lang() {
            return this.$store.state.locale
        }
    },
    watch: {
        controleDepense(){
            this.initialize()
        },
        dialog(val) {
            val || this.closeDialog()
        },
    },
    created(){
        this.initialize()
    },
    methods: { 
        ...mapGetters(['getAccessToken','getEntreprise']), 
        ...mapGetters("comptable",['getDepenseUpdated','getListDepense']), 
        initialize(){
            this.listDepenseMensuelle =[]
            this.getListDepense().forEach(ele => {
                if (ele.source == '1') {
                    ele.source = this.lang == 'fr'? 'Caisse':'Fund'
                    this.listDepenseMensuelle.push(ele)
                } else {
                    ele.source = this.lang == 'fr'? 'Bénéfice':'Profit'
                    this.listDepenseMensuelle.push(ele)
                }
            });
            console.log("Mensual depense: ", this.listDepenseMensuelle);
        },
        detailItem(item){
            this.motifs = item.motifs
            this.dialog = true; 
        },
        closeDialog(){
            this.motifs = ""
            this.dialog = false; 
        },
        showPutBackField(item){
            this.display = true;
            this.depenseItemId = item.id
            this.Form.source = item.source
            if (item.source == 'Caisse' || item.source == 'Fund') {
                this.Form.source = 1
            } else {
                this.Form.source = 2
            }
        },
        returnDepense(){
            this.isLoading = true
            if( !isNaN(this.Form.amount)){
                this.Form.entreprise = this.getEntreprise().id
                    SoldeCaisse.returnDepense(this.Form,this.depenseItemId,this.getAccessToken())
                    .then(() => {
                        this.display = false;
                        this.depenseItemId = '';
                        this.Form.amount = '';
                        this.isLoading = false;

                    })
                    .catch(error => {this.isLoading= false, console.log("error :",error)})
            }else{
                this.display = false;
                this.depenseItemId = '';
            }
        }
    }

}
</script>
<style lang="scss">
    .retourner-argent{
        display: flex;
        background-color: lightgray;
        margin: 25px auto;
        padding: 20px;
        :first-child{
            margin-right: 10px;
        }
    }
</style>