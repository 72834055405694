<template>
  <v-container fluid>
    <v-toolbar flat v-appcolor>
      <v-toolbar-title class="white--text d-none d-sm-flex">
        {{ lang == 'fr' ? 'Liste des services': 'List of services' }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details filled dense
        rounded class="d-none d-sm-flex  white black--text" style="width:50%; max-width: 50%;">
      </v-text-field>
      <v-btn v-appcolor dark class="mb-2 ml-1 mt-2" :sm="false" @click="showDialog">
        {{ $t('buttons.addNew') }}
      </v-btn>
    </v-toolbar>
    <v-divider></v-divider>

    <v-data-table :headers="headers" :items="listServiceAcces" :search="search" class="elevation-1">

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" color="info" @click="editeItme(item)" title="Editer">
          fa-duotone fa-pen-to-square
        </v-icon>
        <v-icon small @click="deleteItem(item)" color="red" title="Supprimer">
          fa-duotone fa-trash-can
        </v-icon>
      </template>
    </v-data-table>
    <DialogServiceAcces @submited="initialize()" />
    <!-- dialog delete Service -->
    <v-dialog persistent v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import DialogServiceAcces from '../DialogServiceAcces.vue';
import ServiceAccesManager from '@/services/ServiceAccesManager';

import { mapGetters } from 'vuex'

export default {
  name: "ListService",
  components: { DialogServiceAcces },
  data() {
    return {
      search: "",
      dialogDelete: false,
      headers: this.$i18n.locale == 'fr'?[
        {text: "Service",align: "start",sortable: true,value: "services.libelle"},
        { text: "Nombre d'utilisateur", value: "nb_users" },
        { text: "Nombre d'accès", value: "nb_access" },
        { text: "Actions", value: "actions", sortable: false },
      ]:[
        { text: "Service",align: "start",sortable: true,value: "services.libelle"},
        { text: "User Name", value: "nb_users" },
        { text: "Number of accesses", value: "nb_access" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      listServiceAcces: [],
      service_id: null,
    };
  },
  created() {
    this.initialize()
  },
  computed: {
    lang() {
      return this.$store.state.locale
    },
  },
  methods: {
    ...mapGetters(['getAccessToken', 'getEntreprise']),
    showDialog() {
      this.$store.dispatch("serviceaccess/switchDialogService", true);
    },

    initialize() {
      ServiceAccesManager.listServiceAcces(this.getEntreprise().id, this.getAccessToken())
        .then((resp) => {
          this.listServiceAcces = resp
        }).catch((err) => { console.log(err.data); });
    },

    editeItme(value) {
      this.$store.dispatch("serviceaccess/setListAccessByService", value);
      this.$store.dispatch("serviceaccess/switchDialogService", true);
    },

    deleteItem(item) {
      this.dialogDelete = true;
      this.service_id = item.services.id
      console.log(item);
    },
    deleteItemConfirm() {
      ServiceAccesManager.DeleteService(this.service_id, this.getAccessToken())
        .then(() => {
          this.initialize();
          this.closeDelete()
        }).catch((err) => { console.log(err.data); })
    },
    closeDelete() {
      this.dialogDelete = false;
      this.service_id = null;
    },

  },

}
</script>