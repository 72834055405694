<template lang="">
    <div class="pb-5">
        <v-card-text class="mt-12">
        <h3 class="text-center  text--accent-3" style="color:#114E60;">
            {{$t('entrepriseRegisterView.title')}}
        </h3>
        <v-alert v-if="errors.message"
        dense
        type="error"
        style="height: 60px; padding-to: 10px"
        >
        {{errors.message}}
        </v-alert>
        <v-img id="signimg" src="../../assets/logo/1.png" class="mx-auto mb-6" contain>
        </v-img>
        <v-row class="mb-3 pl-8">
            <v-col cols="12" md="6" align="left">
                <v-btn rounded v-appcolor dark  @click="getGetCode">{{$t('buttons.suscription_code')}}</v-btn>
            </v-col>
            <i>{{$t('suscription_code_alert')}}</i> 
            <v-icon color="orange">fas fa-lightbulb</v-icon>
        </v-row>
        <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
                v-model="codeInscription"
                :label="$t('formField.codeinscription')"
                :name="$t('formField.codeinscription')"
                prepend-icon="lock"
                type="text"
                color="cyan darken-3"
                :rules="requireRules" dense outlined required/>
            <v-text-field
                v-model="Form.name"
                :label="$t('formField.businessName')"
                :name="$t('formField.businessName')"
                prepend-icon="house"
                type="text"
                color="cyan darken-3"
                :rules="requireRules" dense outlined required />
            <v-text-field
                v-model="Form.telephone"
                :label="$t('formField.phoneNumber')"
                :name="$t('formField.phoneNumber')"
                prepend-icon="phone"
                type="text"
                color="cyan darken-3"
                :rules="requireRules" dense outlined required />
            <v-text-field
                v-model="Form.email_address"
                :label="$t('formField.emailAdress')"
                :name="$t('formField.emailAdress')"
                prepend-icon="email"
                type="text"
                color="cyan darken-3"
                :rules="requireRules" dense outlined required />
            <v-text-field
                v-model="Form.type_monaie"
                :label="lang == 'fr'? 'Type Monétaire': 'Currency type'"
                :name="lang == 'fr'? 'Type Monétaire': 'Currency type'"
                prepend-icon="email"
                type="text"
                color="cyan darken-3"
                :rules="requireRules" dense outlined required />
        </v-form>
    </v-card-text>
    <div class="text-center mb-5">
        <v-btn rounded v-appcolor dark  @click="register">{{$t('buttons.save')}}</v-btn>
    </div>
    <i18n path="already_registered" tag="p" id="noaccount">
        <router-link id="link" to="/login">{{$t('buttons.login')}}</router-link>
    </i18n>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import EntrepriseManager from '@/services/EntrepriseManager'
export default {
    name: 'EntrepriseForm',
    data() {
        return {
            valid: true,
            codeInscription: "",
            Form: {
                codeUsed:false,
                name: "",
                telephone: "",
                email_address: "",
                type_monaie: "",
            },
            errors: this.getErrors(),
            requireRules: [
                v => !!v || "Ce champ est obligatoire",
            ],
        }
    },
    computed: {
        lang() {
            return this.$store.state.locale
        },
    },
    methods: {
        ...mapGetters(['getErrors',]),
        ...mapActions(['userRegister']),
        loginview() {
            this.$router.push({ path: '/login' })
        },
        register() {
            if (this.$refs.form.validate()) {
                EntrepriseManager.updateEntreprise(this.Form, this.codeInscription)
                    .then(() => {
                        this.$router.push({ path: '/type-suscription' })
                    }).catch((err) => {
                        console.log(err);
                    })
            }

        },
        getGetCode() {
            // EntrepriseManager.generateCodeSuscription()
            //     .then((res) => {
            //         this.codeInscription = res.data.code
            //     }).catch((err) => {
            //         console.log(err);
            //     })
            var msg = this.lang =='fr'? 
                'Service non disponible pour le moment, veillez nous contacter via whatsapp: (+224)621 06 04 78':
                'Service not available at the moment, please contact us via whatsapp: (+224)621 06 04 78'
            this.successMessage(msg)
        },
        successMessage(message) {
            this.$toast.success(message, {
                position: "bottom-right",
                timeout: 7000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: false,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false
            });
        },
    },

}
</script>
