<template>
  <v-container>
    <v-menu v-model="menu2" 
    :close-on-content-click="false" 
    transition="scale-transition" 
    offset-y max-width="290px"
      min-width="auto">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field v-model="computedDateFormatted" 
        :label=" lang == 'fr'? 'Date du jour' : 'Today\'s date'" 
        persistent-hint 
        prepend-icon="mdi-calendar"
        readonly v-bind="attrs" v-on="on"></v-text-field>
      </template>
      <v-date-picker v-model="date" no-title @input="menu2 = false"></v-date-picker>
    </v-menu>
  </v-container>
</template>
<script>
export default {
  data: vm => ({
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    dateFormatted: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
    menu2: false,
  }),

  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date)
    },
    lang() {
      return this.$store.state.locale
    },
  },

  watch: {
    date() {
      this.$emit('selectedDate', this.date)
      this.dateFormatted = this.formatDate(this.date)
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate(date) {
      if (!date) return null

      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
  },

  created() {
    this.$emit('selectedDate', this.date)
  },
}
</script>