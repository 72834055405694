<template>
    <v-app style="background:#DDDDDD">
        <v-container>
            <v-alert v-if="errors.message" dense :type="errors.type" style="height: 60px; padding-to: 10px">
                {{ errors.message }}
            </v-alert>
            <v-row class="mt-2">
                <v-col cols="12" md="8" sm="12" class="mx-auto">
                    <template>
                        <v-card outlined class="p-10">
                            <v-list-item three-line>
                                <v-list-item-content>
                                    <div class="text-overline mb-4">
                                        <u><span class="textInfo" v-size="16">
                                            {{ lang == 'fr' ? 'Information de suscription ': 'Subscription information'}}
                                        </span></u>
                                    </div>
                                    <div style="text-align: start;">
                                        <span class="info-label">Code suscription:</span> 
                                        &nbsp;<samp>{{ this.Form.codeInscription}}</samp> 
                                        <br><br>
                                        <span class="info-label">Type:</span>
                                        &nbsp;<samp>{{ entrepriseData.type}}</samp>
                                        <br><br>
                                        <span class="info-label">Durée:</span>
                                        &nbsp;<samp>{{ entrepriseData.duree}}</samp>
                                        <br><br>
                                        <span class="info-label">Nobre de jours passé:</span>
                                        &nbsp;<samp>{{ entrepriseData.reminingDay}}</samp>
                                        <br><br>
                                        <span class="info-label">Date de fin:</span>
                                        &nbsp;<samp>{{ entrepriseData.dateFin}}</samp>
                                        <br><br>
                                    </div>
                                    <!-- <div class="text-overline mb-4">
                                        <u><span class="textInfo" v-size="16">
                                            {{ lang == 'fr' ? 'Information de  ': 'Subscription information'}}
                                        </span></u>
                                    </div> -->
                                    <div>
                                        <v-form ref="form" lazy-validation>
                                            <v-row>
                                                <v-col cols="12" class="mt-n4">
                                                    <v-text-field 
                                                        v-model="Form.name" 
                                                        :rules="requireRules"
                                                        :label=" lang == 'fr'? 'Nom de l\'entreprise':'Business Name' " 
                                                        required prepend-icon="lock"
                                                        color="cyan darken-3"
                                                        type="text" :disabled="disableEditing">
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12" class="mt-n4">
                                                    <v-text-field 
                                                        v-model="Form.telephone" 
                                                        :rules="requireRules"
                                                        :label=" lang == 'fr'? 'Téléphone':'Phone Number' " 
                                                        required prepend-icon="lock"
                                                        color="cyan darken-3"
                                                        type="text" :disabled="disableEditing">
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12" class="mt-n4">
                                                    <v-text-field 
                                                        v-model="Form.email_address" 
                                                        :rules="requireRules"
                                                        :label=" lang == 'fr'? 'Adresse Émail':'Email Adress' " 
                                                        required prepend-icon="lock"
                                                        color="cyan darken-3"
                                                        type="text" :disabled="disableEditing">
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12" class="mt-n4">
                                                    <v-text-field 
                                                        v-model="Form.type_monaie" 
                                                        :rules="requireRules"
                                                        :label=" lang == 'fr'? 'Type Monétaire':'Currency type' " 
                                                        required prepend-icon="lock"
                                                        color="cyan darken-3"
                                                        type="text" :disabled="disableEditing">
                                                    </v-text-field>
                                                </v-col>
                                            </v-row>

                                            <div class="left">
                                                <v-btn  text color="success" class="mr-4"
                                                    @click="switchEditMode">
                                                    Editer
                                                </v-btn>

                                                <v-btn  text color="success" class="mr-4"
                                                    @click="updateEntreprise" :disabled="disableEditing">
                                                    Validate
                                                </v-btn>

                                                <v-btn text color="primary" @click="navigateTo('/')">
                                                    <v-icon size="25px" class="mr-1">fa-brands fa-accusoft</v-icon>
                                                    <span>Accueil</span>
                                                </v-btn>
                                            </div>
                                        </v-form>
                                    </div>
                                </v-list-item-content>
                            </v-list-item>

                        </v-card>
                    </template>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>
<script>
import { mapGetters } from 'vuex'
import Message from '@/services/Message'
import UserService from '@/services/UserService'
import SuscriptionManager from '@/services/Suscription'
import EntrepriseManager from '@/services/EntrepriseManager'
import moment from 'moment'
export default {
    components: {},
    data() {
        return {
            entrepriseData:{},
            errors: this.getErrors(),
            disableEditing: true,
            Form: {
                codeUsed:false,
                codeInscription:'',
                name : '',
                telephone : '',
                email_address : '',
                type_monaie : '',
            },
            requireRules: [
                v => !!v || "Ce champ est obligatoire",
            ],
        }
    },
    computed: {
        lang() {
            return this.$store.state.locale
        },
    },
    created() {
        console.log(this.getEntreprise());
        this.$store.dispatch("makeErros", Message.createMessage(''))
        this.initialize()
    },
    methods: {
        ...mapGetters(['getErrors', 'getAccessToken', 'getUser', 'getEntreprise']),

        resetPassword(){
            if(this.$refs.form.validate()){
                this.Form.username = this.getUser().username
                UserService.resetPassword(this.Form, this.getAccessToken())
            }
        },
        switchEditMode(){
            this.disableEditing = ! this.disableEditing
        },
        navigateTo(path){this.$router.push(path)},
        initialize() {
            SuscriptionManager.getOneSuscription(this.getEntreprise().id, this.getAccessToken())
            .then((rep) => {
                var duree;
                if (rep.data.duree < 12) {
                    if (this.lang == "fr") {
                        duree = rep.data.duree+' Mois'
                    }else{
                        duree = rep.data.duree+' Month'
                    }
                } else {
                    if (this.lang == "fr") {
                        duree = rep.data.duree+' Ans'
                    }else{
                        duree = rep.data.duree+' Year'
                    } 
                }
            
                this.entrepriseData = {
                        name        : rep.data.entreprise.name,
                        email       : rep.data.entreprise.email_address,
                        contact     : rep.data.entreprise.telephone,
                        code        : rep.data.entreprise.code,
                        type        : rep.data.type_suscription,
                        type_monaie : rep.data.type_monaie,
                        created_at  : moment(rep.data.created_at).format('DD-MM-YYYY'),
                        reminingDay : moment(rep.data.created_at).locale(this.lang).startOf('day').fromNow(),
                        duree       : duree,
                        dateFin     : this.calculDateFin(rep.data.created_at, rep.data.duree)
                    }
                this.Form = {
                    codeInscription : rep.data.entreprise.code,
                    name            : rep.data.entreprise.name,
                    telephone       : rep.data.entreprise.telephone,
                    email_address   : rep.data.entreprise.email_address,
                    type_monaie     : rep.data.entreprise.type_monaie,
                }
            }).catch((error) => {
            console.log(error)
            })
        },
        updateEntreprise() {
            if (this.$refs.form.validate()) {
                this.Form.codeUsed = true
                console.log(this.Form.codeInscription);
                EntrepriseManager.updateEntreprise(this.Form, this.Form.codeInscription)
                    .then((res) => {
                        console.log(res.data);
                        var message = this.lang == 'fr'? 'Mise à jour éffectué avec succès':'Update performed successfully'
                        this.$store.dispatch("makeErros", Message.createMessage(message, "success"));
                        let data ={
                            name            :res.data.name,
                            email_address   :res.data.email_address,
                            telephone       :res.data.telephone,
                            type_monaie     :res.data.type_monaie,
                        }
                        
                        this.$store.dispatch("updateUserEntreprise", data)
                        setTimeout(() => {
                        this.$store.dispatch("makeErros", Message.createMessage(''));
                        }, UserService.timeout);
                    }).catch((err) => {
                        console.log(err);
                    })
            }

        },
        calculDateFin(data, duree){
            if (parseInt(duree) ==10) {
                return moment(data).add(duree, 'days').format('DD-MM-YYYY')
            } else {
                return moment(data).add(duree, 'month').format('DD-MM-YYYY')
            }
      
        },

    },
}
</script>
<style scoped>
.textInfo {
    font-style: italic;
    font-weight: 600;
    color: '#114E60';
}
.info-label{
    display: inline-block;
    /* margin-bottom: 1px; */
    color: black;
    font-size: 16;
    font-weight: 200;
}
</style>