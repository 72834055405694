<template lang="">
    <div class="pb-5">
        <v-card-text class="mt-12">
            <h3 class="text-center  text--accent-3" style="color:#114E60;">
                ADMINISTRATION
            </h3>
            <v-alert v-if="errors.message"
            dense
            :type="errors.type"
            style="height: 60px; padding-to: 10px"
            >
            {{errors.message}} 
            </v-alert>
            <div>
                <v-img id="signimg" src="../../assets/logo/2.png" class="mx-auto mb-5" contain>
            </v-img>
            </div>
            <v-form ref="form" v-model="valid" lazy-validation >
                <v-text-field
                    v-model.trim="Form.username"
                    label="Nom d'utilisateur"
                    name="Nom d'utilisateur"
                    prepend-icon="person"
                    type="text"
                    color="cyan darken-3"
                    :rules="requireRules" dense outlined required />
                <v-text-field
                    v-model.trim="Form.password"
                    id="password"
                    label="Mot de passe"
                    name="Mot de passe"
                    prepend-icon="lock"
                    type="password"
                    color="cyan darken-3"
                    :rules="requireRules" dense outlined required/>
            </v-form>
            <div class="text-center mb-5">
                <v-btn rounded v-appcolor dark @click="login">CONNEXION</v-btn>
            </div>
        </v-card-text>
        <LoaderViewVue v-if="loader"/>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import UserService from '@/services/UserService'
import LoaderViewVue from '../LoaderView.vue'
// import Message from '@/services/Message'
export default {
    components:{
        LoaderViewVue
    },
    data() {
        return {
            valid: true,
            loader: false,
            Form:{
                username: "",
                password: "",
            },
            incorrectAuth: false,
            requireRules: [
                v => !!v || "Ce champ est obligatoire",
            ],
            errors: this.getErrors()
        }
    },
    methods: {
        ...mapActions(['setAccessToken', 'setUser', 'makeErros']),
        ...mapGetters(['getErrors']),
        registeriew() {
            this.$router.push({path: '/entreprise'})
        },

        async login(){
            if(this.$refs.form.validate()){
                this.loader = true
               await UserService.login(this.Form)
                .then((res) => {
                    this.setAccessToken(res.data.tokens.access)
                    this.setUser(res.data.user)
                    this.makeErros('')
                    this.$router.push({ path: '/' })
                }).catch((err) => {
                    this.loader = false
                    UserService.getErrorMessage(err)
                })
            }
        }

    },
}
</script>

<style lang="scss">
    #noaccount{
        color:#556413;
        font-size: medium;
        font-weight: 600;
        #link{
            text-decoration: none;
            color: #114E60;
        }
    }
    #logo{
        margin-bottom: -8rem;
        #img{
            width: 200px;
        }
    }
    #signimg{
        height: 150px;
    }
</style>