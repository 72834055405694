<template lang="">
    <div>
        <div>The page you came is not found</div>
        <div>
            <router-link to="/">Go to Home page</router-link>
        </div>
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="">
    
</style>