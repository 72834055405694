<template id="loginviw">
    <div id="contai">
        <v-container>
            <div class="text-center mx-auto" id="logo">
                <v-img id="img" src="../assets/logo-avec-fond1.png" class="mx-auto" contain>
                </v-img>
            </div>
            <v-container class="fill-height" fluid>
                <v-row align="center" justify="center" class="mt-15">
                    <v-col cols="12" md="6" sm="8">
                        <v-card class="elevation-5">
                            <AdminLoginForm />
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-container>
    </div>
</template>
<script>
import AdminLoginForm from "../components/Account/AdminLoginForm.vue";
export default {
    components: { AdminLoginForm },
    data: () => ({
        stop: 1,
        name: "LoginVue",
    }),
}
</script>
<style lang="scss">
#contai {
    height: 100%;
    widows: 100%;
    background-size: cover;
    /* Background image is fixed in the viewport so that it doesn't move when
            the content's height is greater than the image's height */
    background-attachment: fixed;
    // background-position: center center;
}
</style>