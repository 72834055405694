export default{
    namespaced: true,
    state: {
        saleData: [],
        invoiceInfo:{
            sales_date:'',
            totalToPay:'',
            totalPaid:'',
            remainder:'',
            customerFullName:'',
        },
    },
    
    getters: {
        getSaleData:(state) => state.saleData,
        getInvoiceInfo:(state) => state.invoiceInfo
    },
    
    mutations: {
        SET_SALLE_DATA:(state, value) => state.saleData = value,

        SET_INVOICE_INFO:(state, value) => state.invoiceInfo = value,

        SET_CLEAN_DATA(state){
            state.invoiceInfo.customerFullName ='',
            state.invoiceInfo.sales_date ='',
            state.invoiceInfo.totalToPay ='',
            state.invoiceInfo.totalPaid ='',
            state.invoiceInfo.remainder =''
        }
            
    },
    actions: {
        assignSaleDate:({commit}, payload) => commit('SET_SALLE_DATA',payload),
    
        assignInvoiceInfo:({commit}, payload) => commit('SET_INVOICE_INFO', payload),

        cleanDate:({commit}) => commit('SET_CLEAN_DATA'),
    }
}