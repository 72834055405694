<template>
  <v-container>
    <v-toolbar flat v-appcolor>
      <v-toolbar-title class="white--text" style="font-size:medium">
        {{ $t('service.client.listClient').toLocaleUpperCase() }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details filled dense
        rounded class="d-none d-sm-flex white black--text" style="width:30%; max-width: 30%;">
      </v-text-field>
    </v-toolbar>
    <v-data-table :headers="headers" :items="listeclient" :search="search" sort-by="calories" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details filled dense
            rounded class="d-flex d-sm-none white black--text" size="15px">
          </v-text-field>
          <v-dialog persistent v-model="dialog" max-width="800px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-appcolor dark class="mb-2 d-none d-sm-flex" v-bind="attrs" v-on="on">
                {{ lang == "fr" ? 'Nouveau Client' : 'New Customer' }}
              </v-btn>

              <v-btn fab v-appcolor dark class="ml-3 mt-3 mb-2 d-flex d-sm-none" width="40px" height="40px" v-bind="attrs"
                v-on="on">
                <v-icon>fa-regular fa-plus</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title v-appcolor>
                <span class="text-h5">{{ lang == 'fr' ? frFormTitle: enFormTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-row>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field v-model="clientForm.first_name" :label="lang == 'fr' ? 'Prénom' : 'First Name'"
                          :rules="firstNameRules" required>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field :rules="lastNameRules" v-model="clientForm.last_name"
                          :label="lang == 'fr' ? 'Nom' : 'Last Name'" required>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-text-field :rules="telRules" v-model="clientForm.tel"
                          :label="lang == 'fr' ? 'Téléphone' : 'Phone Number'" required>
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :disabled="!valid" text color="success" class="mr-4" @click="validate">
                  {{ $t('buttons.validate') }}
                </v-btn>

                <v-btn color="red darken-1" text class="mr-4" @click="reset">
                  {{ $t('buttons.cancel') }}
                </v-btn>

                <v-btn color="blue darken-1" text @click="close">
                  {{ $t('buttons.close') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog persistent v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)" color="info" title="Editer">
          fa-duotone fa-pen-to-square
        </v-icon>
        <v-icon small @click="deleteItem(item)" color="red" title="Supprimer">
          fa-duotone fa-trash-can
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import ClientManager from '@/services/ClientManager.js'
import { mapGetters } from "vuex";
export default {
  components: {},
  data() {
    return {
      valid: true,
      dialog: false,
      dialogDelete: false,
      search: '',
      headers: this.$i18n.locale == 'fr' ? [
        { text: 'PRENOMS', align: 'start', sortable: true, value: 'first_name', },
        { text: 'NOM', value: 'last_name' },
        { text: 'NUMERO DE TELEPHONE', value: 'tel' },
        { text: 'Actions', value: 'actions', sortable: false },
      ] : [
        { text: 'FIRST NAME', align: 'start', sortable: true, value: 'first_name', },
        { text: 'LAST NAME', value: 'last_name' },
        { text: 'PHONE NOMBER', value: 'tel' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      listeclient: [],
      editedIndex: -1,
      clientForm: {
        first_name: '',
        last_name: '',
        tel: '',
        entreprise: '',
      },
      defaultItem: {
        first_name: '',
        last_name: '',
        tel: '',
        entreprise: '',
      },
      firstNameRules: [
        v => !!v || 'Ce champ est obligatoire',
      ],
      lastNameRules: [
        v => !!v || 'Ce champ est obligatoire',
      ],
      telRules: [
        v => !!v || 'Ce champ est obligatoire(un numéro valide)',
      ],
    }
  },
  computed: {
    lang() {
      return this.$store.state.locale
    },
    frFormTitle() {
      return this.editedIndex === -1 ? 'Nouveau Client' : 'Modification'
    },
    enFormTitle() {
      return this.editedIndex === -1 ? 'New Customer' : 'Update Customer'
    },
    
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  created() {
    this.initialize()
  },

  methods: {
    ...mapGetters(['getAccessToken', 'getEntreprise', 'getAppReloaded']),
    validate() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          ClientManager.updateClient(this.clientForm, this.getEntreprise().id, this.clientForm.id, this.getAccessToken())
            .then(() => {
              this.close()
              this.reset()
              this.initialize()
              this.infoMessage("Client modifier avec succès")
            }).catch((error) => { console.log(error.response.data) })
          // this.close()
        }
        else {
          let rep = this.checkDuplicateTel(this.clientForm.tel)
          if (parseInt(rep) == -1) {
            this.clientForm.entreprise = this.getEntreprise().id
            ClientManager.registerClient(this.clientForm, this.getAccessToken())
              .then(() => {
                this.reset()
                this.close()
                this.initialize()
                this.infoMessage("Client enregister avec succès")
                this.$store.dispatch('reloadApp')

              }).catch((error) => { console.log(error.response.data) })
          } else
            this.errorMessage('Cet numéro de téléphone existe déjà')
        }
        this.initialize()
      }
    },
    reset() {
      this.$refs.form.reset()
    },
    initialize() {
      ClientManager.listClient(this.getEntreprise().id, this.getAccessToken())
        .then((rep) => {
          this.listeclient = rep.data
        }).catch((error) => {
          console.log(error)
        })
    },
    deleteItemConfirm() {
      ClientManager.deleteClient(this.clientForm.id, this.getAccessToken())
        .then((rep) => {
          console.log(rep)
          this.infoMessage("Client supprimer avec succès")
          this.initialize()
        }).catch((error) => { console.log(error.response.data) })
      this.closeDelete()
    },
    editItem(item) {
      this.editedIndex = this.listeclient.indexOf(item)
      this.clientForm = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.listeclient.indexOf(item)
      this.clientForm = Object.assign({}, item)
      this.dialogDelete = true
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.clientForm = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.clientForm = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    checkDuplicateTel(value) {
      return this.listeclient.findIndex(el => el.tel == value)
    },

    errorMessage(message) {
      this.$toast.error(message, {
        position: "bottom-right",
        timeout: 4095,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: false,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false
      });
    },
    infoMessage(message) {
      this.$toast.success(message, {
        position: "bottom-right",
        timeout: 4095,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: false,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false
      });
    },
  },
}
</script>