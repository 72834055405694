export default{
    namespaced: true,
    state:{
        listCategorie: [],
    },
    getters:{
        getListCategorie:(state) => state.listCategorie,
    },
    mutations : {
        SET_LIST_CATEGORIE:(state, payload) => state.listCategorie = payload,
        
    },
    actions : {
        async fetchListCategorie({commit}, payload){
            commit('SET_LIST_CATEGORIE', payload)
        },
    }
}