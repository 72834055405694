import ApiServiceD from "@/classes/ApiServiceD";

export default class VenteManager{
    static listVente(entreprise_id, token=null){
        return new Promise(function(resolve, reject){
            new ApiServiceD().getList('ventes/register/',{entreprise: entreprise_id}, token)
            .then((resp)=>{
                resolve(resp);
            }).catch((err)=>{reject(err);})
        })
    }
    static statistiqueVente(entreprise_id, token=null){
        return new Promise(function(resolve, reject){
            new ApiServiceD().getList('statistique/vente/',{entreprise: entreprise_id}, token)
            .then((resp)=>{
                resolve(resp);
            }).catch((err)=>{reject(err);})
        })
    }
    static statistiqueVenteMois(entreprise_id, token=null){
        return new Promise(function(resolve, reject){
            new ApiServiceD().getList('statistique/vente/mois/',{entreprise: entreprise_id}, token)
            .then((resp)=>{
                resolve(resp);
            }).catch((err)=>{reject(err);})
        })
    }
}