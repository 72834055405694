<template>
  <v-app id="contai">
    <SideBare />
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="4">
          <DashboardCard />
          <div style="margin-bottom: 2rem !important;">
            <FormTemplate>
            <template v-slot:header>
              <v-toolbar-title>
                <p class="d-none d-sm-flex">
                  {{ $tc('dashboard.totalSale',{total: totalArticle}) }}
                </p>
                <p class="d-flex d-sm-none" v-size="15">
                  {{ $tc('dashboard.totalSale',{total: totalArticle}) }}
                </p>
              </v-toolbar-title>
            </template>
            <template v-slot:cbody>
              <v-data-table :headers="headers" :items="listeArticle" class="elevation-1">
              </v-data-table>
            </template>
          </FormTemplate>
          </div>
        </v-col>
        <v-col cols="12" md="8">
          <BarVue />
        </v-col>
      </v-row>
      
      <v-row>
        <v-col cols="12">
          
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
// @ is an alias to /src
import SideBare from '@/components/SideBare.vue';
import BarVue from '@/components/ChartBar/BarVue.vue';
import FormTemplate from '@/components/FormTemplate.vue';
import VenteManager from '@/services/VenteManager'
import DashboardCard from '@/components/DashboardCard.vue';
import { mapGetters } from 'vuex'
export default {
  name: 'HomeView',
  components: {
    SideBare,
    BarVue,
    FormTemplate,
    DashboardCard
  },
  data() {
    return {
      listeArticle: [],
      totalArticle: 0,
      listeMaintenance: [],
      totalMaintenance: 0,
      headers: [
        {
          text: 'Article',
          align: 'start',
          sortable: true,
          value: 'libelle',
        },
        { text: 'Total', value: 'total', align: "center" },
      ],
    }
  },
  created() {
    this.loadVente()
  },
  methods: {
    ...mapGetters(['isAuthenticated', 'getAccessToken', 'getEntreprise']),
    loadVente() {
      VenteManager.statistiqueVenteMois(this.getEntreprise().id, this.getAccessToken())
        .then(rep => {
          this.listeArticle = rep.data
          this.totalArticle = rep.data.length
        })
        .catch(err => {
          console.log(err.data)
        })
    },
  },
}
</script>
