<template lang="">
    <div>
        <v-list>
            <v-list-item v-for="(item, index) in APIData" :key="index">
                <v-list-item-content>{{item.full_name}} {{item.tel}}</v-list-item-content>
            </v-list-item>
        </v-list>
    </div>
</template>
<script>
    import ApiServiceD from '@/classes/ApiServiceD';
    import { mapState , mapGetters} from 'vuex';
export default {
    name : 'FournisseurView',

    computed: mapState(['APIData']),
    created (){
        new ApiServiceD().findAll('fournisseurs/register/', this.getAccessToken())
        .then(rep =>{
            this.$store.state.APIData = rep.data
        })
        .catch(err =>{console.log(err.data)})
    },
    methods: {
        ...mapGetters(['getAccessToken']),
    },
}
</script>
<style lang="">
    
</style>