<template>
    <v-container >
        <div class="invoce-head">
            <div>
                <span>{{ getEntreprise().name }}</span>
                <span>{{ getInvoiceInfo().sales_date }}</span>
            </div>
            <div style="text-align: center;">
                <h3>{{ lang =='fr'? 'FACTURE DE VENTE':'SALES INVOICE' }}</h3>
            </div>
            <br>
            <div>
                <h3>{{ lang =='fr'? 'LISTE DES ARTICLES':'LIST OF ARTICLES' }}</h3>
            </div>
        </div>
        <div style="overflow-x:auto;">
            <table>
                <thead>
                    <th>N°</th>
                    <th>{{ lang =='fr'? 'Article':'Article' }}</th>
                    <th>{{ lang =='fr'? 'Quantite':'Quantity' }}</th>
                    <th>{{ lang =='fr'? 'Prix Unitaire':'Unit price' }}</th>
                    <th>{{ lang =='fr'? 'Taux':'Rates' }}</th>
                    <th>{{ lang =='fr'? 'Prix':'Price' }}</th>
                </thead>
                <tbody v-for="(item, index) in getSaleData()" :key="index">
                    <tr>
                        <td>{{ (index + 1) }}</td>
                        <td>{{ item['libelle'] }}</td>
                        <td>{{ item['quantite'] }}</td>
                        <td>{{ item['pvu'] }}</td>
                        <td>{{ item['taux'] }}</td>
                        <td>{{ item['prix_materiel'] }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="cash-invoce">
            <div>
                <span>{{ lang =='fr'? 'Prix tota':'Total price' }}:</span>
                <span>{{ getInvoiceInfo().totalToPay }}</span>
                <span>{{ getEntreprise().type_monaie  }}</span>
            </div>
            <div>
                <span>{{ lang =='fr'? 'Total payer':'Total paid' }}:</span>
                <span>{{ getInvoiceInfo().totalPaid }}</span>
                <span>{{ getEntreprise().type_monaie  }}</span>
            </div>
            <div>
                <span>{{ lang =='fr'? 'Rest à payer':'Rest to pay' }}:</span>
                <span>{{ getInvoiceInfo().remainder }}</span>
                <span>{{ getEntreprise().type_monaie  }}</span>
            </div>
        </div>
        <div class="invoce-footer">
            <div>
                <p>{{ lang =='fr'? 'Responsable':'Responsible' }}</p>
                <p>{{ getUser().first_name+" "+getUser().last_name  }}</p>
            </div>
            <div>
                <p>{{ lang =='fr'? 'Client':'Customer' }}:</p>
                <p>{{ getInvoiceInfo().customerFullName }}</p>
            </div>
        </div>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
export default{
    computed: {
        lang() {
            return this.$store.state.locale
        }
    },
    methods:{
        ...mapGetters(['getEntreprise', 'getUser']),
        ...mapGetters('ventes', ['getSaleData', 'getInvoiceInfo']),
    }
}
</script>
<style lang="scss" scoped>
.invoce-head {
    div:first-child {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid;
        margin-bottom: 1.2rem;
    }

    div:last-child {
        text-align: center;
        h3 {
            color: #114E60;
            font-family: 'Courier New', Courier, monospace;
        }
    }
}

.cash-invoce {
    text-align: right;
    margin-bottom: 5rem;

    div {
        justify-content: space-between;

        span:first-child {
            font-weight: bold;
            color: #114E60;
            margin-right: 1rem;
        }

        span:last-child {
            font-weight: bold;
            color: #114E60;
            margin-left: 1px;
        }
    }

}

.invoce-footer {
    display: flex;
    justify-content: space-between;

    div {
        p {
            font-weight: bold;
            &:first-child {
                margin-bottom: 4rem;
            }
        }
    }
}

table {
    margin-bottom: 1rem;
    width: 100%;

    thead {
        background-color: #114E60;
        color: #fff;
        font-family: 'Courier New', Courier, monospace;

        th {
            margin-right: 5rem !important;
            padding: 2px;
        }
    }

    tbody {
        border-bottom: 1px solid black;
        tr {
            font-size: 16px;
            
            td {
                text-align: center;
                border-left: 1px solid black;
                border-right: 1px solid black;
            }
        }
    }
}</style>