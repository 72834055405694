<template>
    <v-app-bar color="transparent" dark dense flat>
         <v-chip class="ma-2 respocl" @click="navigateTo('/')" color="white--text" style="font-size:18px;" pill>
            <span class="d-none d-sm-flex">
                {{$i18n.locale == 'fr' ? "Accueil" : "Home"}}
               <v-icon right small color="white">fa-brands fa-accusoft</v-icon>
            </span>
            <v-icon color="white" class="d-sm-none" size="25px">fa-brands fa-accusoft</v-icon>
        </v-chip>
    </v-app-bar>
</template>
<script>
export default {
    name: 'SettingChips',
    data: () => ({
        link: 'setting',
    }),
    methods: {
        navigateTo(chemin) {
            this.$router.push({ path: chemin }).catch(()=>{});
        },
    }

}
</script>
<style>
@media only screen and (max-width: 600px) {

    /* For mobile phones: */
    .respocl {
        background-color: transparent !important;
        border-radius: 25px;
        display: block;
        margin-left: -20px;
    }
}
</style>