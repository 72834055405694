<template >
    <v-container>
        <FormTemplate>
            <template v-slot:header>
                <v-toolbar-title class="d-none d-sm-flex">
                    {{ lang == 'fr' ? 'Approvisionnements mensuel' : 'Monthly supply' }} 
                </v-toolbar-title>
                <v-toolbar-title class="d-flex d-sm-none" v-size="17">
                    {{ lang == 'fr' ? 'Approvisionnements mensuel' : 'Monthly supply' }} 
                </v-toolbar-title>
            </template>
            <template v-slot:cbody>
                <v-row>
                    <v-spacer></v-spacer>
                    <v-btn v-appcolor text @click="find">{{ $t('buttons.display') }}</v-btn>
                </v-row>
                <v-switch v-model="toggleSwitch" 
                :label="lang == 'fr' ? 'Chercher entre de dates' : 'Search between dates'"
                 color="#114E60" hide-details>
                </v-switch>
                <v-row>
                    <v-col cols="12" md="6" v-if="toggleSwitch">
                        <DatePiker @selectedDate="setFromDate" />
                    </v-col>
                    <v-col cols="12" md="6" v-if="toggleSwitch">
                        <DatePiker2 @selectedDate="setToDate" />
                    </v-col>
                    <v-col cols="12" v-if="!toggleSwitch">
                        <DatePiker @selectedDate="setFindExactDate" />
                    </v-col>

                </v-row>
                <v-card-title class="mt-n8" style="color: #114E60;">
                    {{ lang == 'fr' ? 'Dépense total:' : 'Total expenditure:' }}
                    <span class="ml-8" v-size="20" style="font-weight: bold;">
                        <v-progress-circular v-if="progress" indeterminate color="primary"></v-progress-circular>
                        {{ solde | localFormat }}{{ getEntreprise().type_monaie }}
                    </span>
                </v-card-title>
            </template>
        </FormTemplate>
    </v-container>
</template>
<script>
import FormTemplate from '../FormTemplate.vue';
import DatePiker from '../DatePiker.vue';
import DatePiker2 from '../DatePiker2.vue';
import SoldeCaisse from '@/services/caisse/SoldeCaisse.js'
import moment from 'moment';
import { mapGetters } from 'vuex'
export default {
    components: { FormTemplate, DatePiker, DatePiker2 },
    data: () => ({
        toggleSwitch: false,
        fromDate: null,
        toDate: null,
        exactDate: null,
        solde: 0,
        listAppro:[],
        progress: true,
    }),
    created() {
        setTimeout(() => {
            this.solde = this.getSoldeAppro()
            this.listAppro = this.getListAppro()
            this.progress = false
        }, 1500);
    },
    computed: {
        lang() {
            return this.$store.state.locale
        },
    },
    mounted(){
        this.initialize() 
    },
    methods: {
        ...mapGetters(['getAccessToken','getEntreprise']),
        ...mapGetters("comptable",['getListAppro', 'getSoldeAppro']), 
        initialize() {
            SoldeCaisse.soldeAppro(this.getEntreprise().id, this.getAccessToken())
        },
        find() {
            this.solde = 0
            console.log("Chaque element 1")
            if (!this.toggleSwitch) {
                this.listAppro.forEach(el => {
                    console.log("Chaque element 2")
                    if (moment(el.date_appro).isSame(this.exactDate)) {
                        console.log("Chaque element 3:", el)
                        this.solde += parseInt(el.pau)
                        console.log("Nouvelle solde: ", this.solde)
                    }
                });
            } else {
                console.log(this.listAppro)
                this.listAppro.forEach(el => {
                    if (moment(el.date_appro).isBetween(this.fromDate, this.toDate)) {
                        this.solde += parseInt(el.pau)
                    }
                });
            }
        },
        setFindExactDate(val) {
            this.exactDate = val
            console.log(val)
        },
        setToDate(val) {
            this.toDate = val
        },
        setFromDate(val) {
            this.fromDate = val
        },
    },
}
</script>
<style lang="">
    
</style>