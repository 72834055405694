<template>
    <v-container class="mt-5">
        <v-toolbar color="white--text" flat class="mb-5" v-appcolor>
            <v-toolbar-title class="d-none d-sm-flex">
                {{ lang == 'fr' ? 'NOUVELLE VENTE' : 'NEW SALE' }}
            </v-toolbar-title>
            <v-toolbar-title class="d-flex d-sm-none" style="font-size:small">
                {{ lang == 'fr' ? 'NOUVELLE VENTE' : 'NEW SALE' }}
            </v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog persistent v-model="dialog" max-width="800px">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-appcolor dark class="mb-2 d-none d-sm-flex" :sm="false" v-bind="attrs" v-on="on">
                        {{ $t('buttons.addNew') }}
                    </v-btn>
                    <v-btn fab v-appcolor dark class="ml-3 mt-3 mb-2 d-flex d-sm-none" width="40px" height="40px"
                        v-bind="attrs" v-on="on">
                        <v-icon>fa-regular fa-plus</v-icon>
                    </v-btn>
                </template>
                <v-card>
                    <v-card-title v-appcolor>
                        <span class="text-h5">{{ lang == 'fr' ? frFormTitle : enFormTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                        <v-container>
                            <v-form ref="form" v-model="valid" lazy-validation>
                                <v-row>
                                    <!-- item-text="libelle" -->
                                    <v-col cols="12" sm="6" md="4">
                                        <v-combobox v-model="selectArticle" :items="listArticle" :rules="requireRule"
                                            :label="lang == 'fr' ? 'Article' : 'Article'" item-value="id"
                                            item-text="categ_libelle" dense class="mt-8" @change="setSelectArticle">
                                        </v-combobox>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field :rules="requireRule" v-model="formLigneVente.quantite"
                                            :label="lang == 'fr' ? 'Quantité' : 'Quantity'" required class="mt-4"
                                            type="numeric">
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="4">
                                        <v-text-field :rules="requireRule" v-model="formLigneVente.taux"
                                            :label="lang == 'fr' ? 'Taux de reduction' : 'Discount rate'" required
                                            class="mt-4" type="numeric">
                                        </v-text-field>
                                        <!-- <v-combobox v-model="c" :items="tauxReduction"
                                            :label="lang == 'fr' ? 'Taux de reduction' : 'Discount rate'"
                                            item-value="id" item-text="text" dense auto-select-first class="mt-8"
                                            @change="setSelectTaux">
                                        </v-combobox> -->
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-container>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red darken-1" text @click="close">
                            {{ $t('buttons.cancel') }}
                        </v-btn>
                        <v-btn color="blue darken-1" text @click="save">
                            {{ $t('buttons.validate') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog persistent v-model="dialogDelete" max-width="500px">
                <v-card>
                    <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                        <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-toolbar>
        <v-row>
            <v-col cols="12" md="4" sm="4">
                <DatePiker id="dPiker" @selectedDate="getSelectedDate" class="mt-n6 mb-n7 ml-n22" />
            </v-col>
            <v-col cols="12" md="4">
                <v-autocomplete v-model="idClient" :items="clientList" item-value="id" item-text="full_name"
                    auto-select-first chips clearable
                    :label="lang == 'fr' ? 'Téléphone client' : 'Cumstomer Phone Number'" @change="selectTel"
                    class="mt-n4">
                </v-autocomplete>
            </v-col>
            <v-col cols="12" md="4">
                <v-text-field v-model="nom_du_client" :label="lang == 'fr' ? 'Nom complet' : 'Full name'" required
                    class="mt-n1">
                </v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-data-table :headers="headers" id="my-table" :items="lingeVente" sort-by="calories"
                    class="elevation-1">
                    <template v-slot:[`item.prix_materiel`]="{ item }">
                        <span>{{ item.prix_materiel | localFormat }}</span>
                    </template>
                    <template v-slot:[`item.pvu`]="{ item }">
                        <span>{{ item.pvu | localFormat }}</span>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-icon small class="mr-2" @click="editItem(item)" color="info" title="Editer">
                            fa-duotone fa-pen-to-square
                        </v-icon>
                        <v-icon small @click="deleteItem(item)" color="red" title="Supprimer">
                            fa-duotone fa-trash-can
                        </v-icon>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <!-- Start -->
        <!-- <template> -->
        <div>
            <vue-html2pdf :show-layout="false" :float-layout="true" :enable-download="true" :preview-modal="true"
                :paginate-elements-by-height="1400" :pdf-quality="2" :manual-pagination="false" pdf-format="a4"
                pdf-orientation="p" pdf-content-width="800px" :htmlopdfoptions="{
                    filename: invoceFilename,
                    margin: [8, 8, 8, 8]
                }" ref="html2Pdf">
                <section slot="pdf-content">
                    <SaleInvoice></SaleInvoice>
                </section>
            </vue-html2pdf>
        </div>
        <!-- </template> -->
        <!-- End -->

        <v-row>
            <v-col cols="12" md="4">
                <v-text-field v-model="prix_payer" :label="lang == 'fr' ? 'Prix payer' : 'Price paid'" required>
                </v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" id="totalPrix" align="right" class="mt-4">
                <v-spacer>{{ lang == 'fr' ? 'Prix Total' : 'Total Price' }}:</v-spacer>
                <v-spacer>{{ prixTota | localFormat }}{{ getEntreprise().type_monaie }}</v-spacer>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" align="left" style="display: flex; justify-content: space-between;">
                <v-checkbox v-model="checkbox" :label="lang == 'fr' ? 'Imprimer une facture?' : 'Print an invoice?'"
                    color="info" @change="switchCheckBox(checkbox)"></v-checkbox>
                <v-btn class="mr-4" v-appcolor type="submit" @click="submit" :loading="isLoading">
                    {{ $t('buttons.save') }}
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-btn class="mr-4" v-appcolor type="submit" @click="cleanInvoceData">
                    <v-icon>fas fa-arrow-rotate-left</v-icon>
                    {{ lang == 'fr' ? 'Annuler la facture' : 'Cancel the invoice' }}

                </v-btn>
            </v-col>
        </v-row>
        <SaleInvoice></SaleInvoice>
    </v-container>
</template>
<script>
import ApiServiceD from '@/classes/ApiServiceD'
import DatePiker from '@/components/DatePiker.vue'
import ArticleManager from '@/services/ArticleManager'
import ClientManager from '@/services/ClientManager.js'
import SaleInvoice from './SaleInvoice.vue'
import DetteManager from '@/services/dettes/DetteManager'
import VueHtml2pdf from 'vue-html2pdf'
import moment from 'moment'
import { mapGetters } from 'vuex'
export default {
    components: { DatePiker, SaleInvoice, VueHtml2pdf },
    data() {
        return {
            checkbox: false,
            isLoading: false,
            dialog: false,
            valid: true,//Form validation
            dialogDelete: false,
            listArticle: [],
            stockAvailable: true,//control on selected piece stock before cell it
            is_duplicate: false,//check in other to avoid duplication listArticle
            selectArticle: null,//Selected article
            clientList: null,//Client list
            restourn: '',// Article prix reduction
            prixTota: 0,// Connaitre le prix de vente total
            invoceFilename: "",
            tauxReduction: [
                { id: 0, text: '0%' },
                { id: 5, text: '5%' },
                { id: 10, text: '10%' },
                { id: 15, text: '15%' },
                { id: 20, text: '20%' },
                { id: 25, text: '25%' },
                { id: 30, text: '30%' },
                { id: 35, text: '35%' },
                { id: 40, text: '40%' },
                { id: 45, text: '45%' },
                { id: 50, text: '50%' },
            ],

            headers: this.$i18n.locale == 'fr' ? [
                { text: "Article", value: "categ_libelle" },
                { text: "Prix de vente Unitaire", value: "pvu", align: "center" },
                { text: "Quantite", value: "quantite", align: "center" },
                { text: "Taux", value: "taux", align: "center" },
                { text: "Prix a payer", value: "prix_materiel", align: "center" },
                { text: "Actions", value: "actions", sortable: false },
            ] : [
                { text: "Article", value: "categ_libelle" },
                { text: "Unit sales price", value: "pvu", align: "center" },
                { text: "Quantity", value: "quantite", align: "center" },
                { text: "Rate", value: "taux", align: "center" },
                { text: "Price to pay", value: "prix_materiel", align: "center" },
                { text: "Actions", value: "actions", sortable: false },
            ],
            // Validation rules
            requireRule: [
                v => !!v || 'Ce champ est obligatoire',
            ],
            //Client name
            nom_du_client: "",
            idClient: -1,
            prix_payer: "",
            formLigneVente: {
                libelle: "",
                categ_libelle: "",
                pau: "",
                pvu: "",
                quantite: "",
                taux: '0',
                prix_materiel: "",
                income: "",
            },
            defaultItem: {
                libelle: "",
                categ_libelle: "",
                pau: "",
                pvu: "",
                quantite: "",
                taux: '0',
                prix_materiel: "",
                income: "",
            },

            lingeVente: [],
            editedIndex: -1,

            date_commande: null,
            editionItem: null,//Element qu'on veut editer ou modi
            moreText: this.$i18n.locale == 'fr' ? "PRIX TOTAL: " : "TOTAL PRICE",
            moreText1: this.$i18n.locale == 'fr' ? "MONTANT PAYR: " : "AMOUNT PAID",
        }
    },
    computed: {
        frFormTitle() {
            return this.editedIndex === -1 ? "Ajouter au panier" : "Mise a jour du panier";
        },
        enFormTitle() {
            return this.editedIndex === -1 ? 'Add to cart' : 'Update of the basket'
        },
        getDocumentSize() {
            return this.documents ? this.documents.length : 0;
        },
        lang() {
            return this.$store.state.locale
        },
        typeMonaie() {
            return this.getEntreprise().type_monaie
        }
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        },
    },
    created() {
        this.loadArticle()
        this.getClientContact()
    },
    methods: {
        ...mapGetters(['getAccessToken', 'getEntreprise', 'getUser']),
        ...mapGetters('ventes', ['getSaleData', 'getInvoiceInfo']),
        getClientContact() {
            ClientManager.listClient(this.getEntreprise().id, this.getAccessToken())
                .then((resp) => {
                    this.clientList = resp.data
                    console.log("ClientList: ", resp.data)
                    this.$emit('submit')
                }).catch((error) => {
                    console.log(error)
                });
        },
        switchCheckBox(checkbox) {
            if (checkbox) {
                var remainder = parseFloat(this.prixTota) - parseFloat(this.prix_payer)
                this.getInvoiceInfo().customerFullName = this.nom_du_client
                this.getInvoiceInfo().remainder = !isNaN(remainder) ? remainder : 0
                this.getInvoiceInfo().sales_date = moment(this.date_commande).format('DD/MM/YYYY')
                this.getInvoiceInfo().totalPaid = !isNaN(this.prix_payer) ? this.prix_payer : 0
                this.getInvoiceInfo().totalToPay = this.prixTota
                this.$store.dispatch("ventes/assignInvoiceInfo", this.getInvoiceInfo())
            } else {
                this.$store.dispatch("ventes/cleanDate")
            }
        },
        cleanInvoceData() {
            this.checkbox = false
            this.$store.dispatch("ventes/cleanDate")
            this.$store.dispatch("ventes/assignSaleDate", [])
        },
        selectTel() {
            var founded = false;
            this.clientList.forEach((client) => {
                if (this.idClient == client.id) {
                    this.nom_du_client = client.first_name + " " + client.last_name;
                    founded = true;
                }
            })

            if (!founded) {
                this.idClient = -1;
                this.nom_du_client = "";
            }
        },
        save() {

            if (this.$refs.form.validate() == true) {

                if (this.editedIndex > -1) {
                    this.prixTota = 0;

                    let totalPrice = (parseFloat(this.formLigneVente.pvu) * parseInt(this.formLigneVente.quantite))
                    if (this.formLigneVente.taux != '0' && this.formLigneVente.taux.trim() != '') {
                        let pvu = this.formLigneVente.pvu
                        pvu -= parseFloat(this.formLigneVente.taux)
                        totalPrice = (parseFloat(pvu) * parseInt(this.formLigneVente.quantite))
                    } else {
                        this.formLigneVente.taux = '0'

                    }

                    this.formLigneVente.prix_materiel = totalPrice
                    /** Icome calculation */
                    this.formLigneVente.income = totalPrice - (parseFloat(this.formLigneVente.pau) * parseInt(this.formLigneVente.quantite))

                    Object.assign(this.lingeVente[this.editedIndex], this.formLigneVente);
                    this.lingeVente.forEach(async (ele) => {
                        this.prixTota += parseFloat(ele.prix_materiel)
                    });
                    this.close();
                }
                else {
                    this.checkQuantity()
                    if (this.stockAvailable) {
                        let rep = this.checkDuplicate(this.formLigneVente.id)
                        console.log(this.formLigneVente);
                        if (parseInt(rep) == -1) {

                            let data = {
                                id: this.formLigneVente.id,
                                libelle: this.formLigneVente.libelle,
                                categ_libelle: this.formLigneVente.categ_libelle,
                                pvu: this.formLigneVente.pvu,
                                pau: this.formLigneVente.pau,
                                quantite: this.formLigneVente.quantite,
                                taux: this.formLigneVente.taux,
                                prix_materiel: this.formLigneVente.prix_materiel,
                                income: this.formLigneVente.income,
                            }
                            this.lingeVente.push(data)
                            this.close();
                        } else {
                            this.errorMessage("Cet article existe déjà sur la list.")
                        }

                    } else {
                        this.infoMessage("Stock non disponible.")

                    }

                }
                this.$store.dispatch("ventes/assignSaleDate", this.lingeVente)
                this.is_duplicate = false
            }
        },
        generateReport() {
            let date = new Date()
            this.invoceFilename = date.getDay() + ' ' + date.getMonth() + '' + date.getFullYear() + ':' + date.getUTCHours() + '' + date.getMinutes() + '' + date.getUTCSeconds()
            this.$refs.html2Pdf.generatePdf()
        },
        submit() {
            if (this.lingeVente.length > 0) {
                this.prix_payer = this.prix_payer && !isNaN(this.prix_payer) ? this.prix_payer : 0

                //Si le prix payer est infférieur au prix total a payer
                //et que le client est inconnu alors on refuse d'accorder
                //la dette à ce client.
                if (this.prix_payer < this.prixTota && this.idClient < 0) {
                    this.errorMessage("Pas de dette pour les clients inconnus")
                    return;
                } else {
                    let api = new ApiServiceD()
                    let vente = {
                        "article": this.lingeVente,
                        "client": this.idClient,
                        "prix_payer": this.prix_payer,
                        "registred_at": this.date_commande,
                        "entreprise": this.getEntreprise().id,
                    }
                    this.isLoading = true
                    api.create('ventes/register/', vente, null, this.getAccessToken())
                        .then(() => {
                            if (this.checkbox) {
                                this.generateReport()
                            }
                            this.checkbox = false
                            this.successMessage("Enregistrement effectué avec succès.")
                            this.claen()
                            this.loadArticle()
                            this.$store.dispatch('reloadApp')
                            this.$store.dispatch('dettes/updateListDette')
                            DetteManager.listDette(this.getEntreprise().id, this.getAccessToken())
                            this.isLoading = false
                        }).catch((error) => {
                            this.isLoading = false
                            console.log(error)
                        });
                }

            } else {
                this.errorMessage("Veillez ajouter des articles a revendre")
            }
        },
        claen() {
            this.lingeVente = []
            this.nom_du_client = ""
            this.idClient = -1
            this.prixTota = 0
            this.prix_payer = 0
        },
        getSelectedDate(value) {
            this.date_commande = value
        },
        setSelectArticle() {
            this.formLigneVente.id = this.selectArticle.id
            this.formLigneVente.pvu = this.selectArticle.pvu
            this.formLigneVente.libelle = this.selectArticle.libelle
            this.formLigneVente.categ_libelle = this.selectArticle.categ_libelle
        },

        // setSelectTaux() {
        //     this.formLigneVente.taux = this.restourn.text
        // },

        loadArticle() {
            ArticleManager.listAvailable(this.getEntreprise().id, this.getAccessToken())
                .then((rep) => {
                    this.listArticle = rep.data
                    console.log("Article: ", this.listArticle)
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        /**
         * Avoid duplicate item in the lingeVente
         */
        checkDuplicate(val) {
            let result = this.lingeVente.findIndex(el => el.id == val)
            return result
        },
        // check if there is more quantity for selected article
        checkQuantity() {
            this.listArticle.forEach((el) => {
                if ((el.id == this.selectArticle.id)) {
                    let quantite = parseInt(this.formLigneVente.quantite)
                    if (!isNaN(quantite)) {
                        if (parseInt(el.stock) >= quantite) {
                            let message = null;

                            if (el.stock <= el.seuil) {
                                if (quantite > el.stock) {
                                    this.stockAvailable = false
                                    return 0
                                }
                                var confirm_message = this.lang == 'fr' ?
                                    "Le stock de cet article est inférieur à son seuil \n d'approvisonnement. Voulez-vous qu'à même parsser la vente ?" :
                                    "The stock of this article is below its supply threshold.\n Would you like to start selling it?"
                                message = confirm(confirm_message)
                            }
                            if (message != null && message == true) {
                                this.stockAvailable = true
                            } else if (message == null) {
                                this.stockAvailable = true
                            } else {
                                this.stockAvailable = false
                                return 0
                            }
                            /**
                           * Price calculation with decrease rate
                           */
                            let totalPrice = (parseFloat(el.pvu) * quantite)
                            if (this.formLigneVente.taux != '0' && this.formLigneVente.taux != '') {
                                let pvu = el.pvu
                                pvu -= parseFloat(this.formLigneVente.taux)
                                totalPrice = (parseFloat(pvu) * quantite)
                            } else
                                this.formLigneVente.taux = '0'
                            this.formLigneVente.prix_materiel = totalPrice
                            this.formLigneVente.pau = el.pau
                            /**Income calculation */
                            this.formLigneVente.income = totalPrice - (el.pau * quantite)

                            this.prixTota = parseInt(this.prixTota) + parseInt(totalPrice)
                        } else
                            this.stockAvailable = false
                    } else {
                        alert("La quantité doit être un nombre")
                        this.stockAvailable = false
                    }

                }
            })
        },

        editItem(item) {
            this.editedIndex = this.lingeVente.indexOf(item);
            this.formLigneVente = Object.assign({}, item);
            // this.restourn = item.taux
            this.selectArticle = item.libelle
            this.formLigneVente.taux = item.taux
            this.editionItem = item;
            this.dialog = true;
        },
        deleteItem(item) {
            this.editedIndex = this.lingeVente.indexOf(item);
            this.formLigneVente = Object.assign({}, item);
            this.editionItem = item;
            this.dialogDelete = true;
        },
        deleteItemConfirm() {
            this.lingeVente.splice(this.editedIndex, 1);
            this.prixTota -= parseFloat(this.editionItem.prix_materiel);
            this.$store.dispatch("ventes/assignSaleDate", this.lingeVente)
            this.closeDelete();
        },
        close() {
            this.dialog = false;
            this.selectArticle = null
            this.$refs.form.reset()
            this.is_duplicate = false
            // this.restourn = '0'
            this.$nextTick(() => {
                this.formLigneVente = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.formLigneVente = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        infoMessage(message) {
            this.$toast.info(message, {
                position: "bottom-right",
                timeout: 6000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: false,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false
            });
        },
        errorMessage(message) {
            this.$toast.error(message, {
                position: "bottom-right",
                timeout: 6000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: false,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false
            });
        },
        successMessage(message) {
            this.$toast.success(message, {
                position: "bottom-right",
                timeout: 4000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: false,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false
            });
        },

    },
}
</script>
<style lang="scss">
#dPiker {
    margin-left: -1rem;
}

#totalPrix {
    display: inline-flex;

    :last-child {
        font-weight: 800;
        font-size: large;
    }
}
</style>