<template>
    <v-app style="background:#DDDDDD">
        <v-container>
            <v-row class="mt-2" >
                <v-col cols="12">
                    <v-card height="calc(100vh - 70)">
                        <v-tabs v-model="tab" background-color="#114E60" centered dark icons-and-text>
                            <v-tabs-slider></v-tabs-slider>

                            <v-tab href="#tab-1">
                                Information générales
                                <v-icon>mdi-account-box</v-icon>
                            </v-tab>

                            <v-tab href="#tab-2">
                                Sécurité
                                <v-icon>mdi-lock</v-icon>
                            </v-tab>

                            <v-tab href="#tab-3">
                                Entreprise
                                <v-icon>mdi-account-box</v-icon>
                            </v-tab>
                        </v-tabs>

                        <v-tabs-items v-model="tab">
                            <v-tab-item v-for="i in 3" :key="i" :value="'tab-' + i">

                                <v-card flat v-if="i == 1">
                                    <ProfilViewer />
                                </v-card>

                                <v-card flat v-if="i == 2">
                                    <EditPassword />
                                </v-card>

                                <v-card flat v-if="i == 3">
                                    <EntrepriseInfo />
                                </v-card>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>
<script>

import EditPassword from '@/components/Profile/EditPassword.vue';
import ProfilViewer from '@/components/Profile/ProfilViewer.vue';
import EntrepriseInfo from '@/components/Profile/EntrepriseInfo.vue';
export default {
    data() {
        return {
            tab: null,
            text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        };
    },
    components: { ProfilViewer, EditPassword, EntrepriseInfo, }
}
</script>