import ApiService from "@/classes/ApiService";
import store from "@/store";
import router from "@/router";
import ApiServiceD from "@/classes/ApiServiceD";
import Message from "./Message";
export default class UserService {
  static timeout = new ApiService().getTimeout();
  static login(credentials) {
    return new Promise(function (resolve, reject) {
      return new ApiService()
        .login("/auth/login/", credentials)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          console.log(error);
          reject(error)
        });
    })
  }
  static logout(token) {
    return new ApiService().axiosInstance().post("/auth/logout/", token);
  }
  static validateEmail(email) {
    if (
      !email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      store.dispatch(
        "makeErros",
        Message.createMessage("Adresse email invalide")
      );
      return false;
    }
    return true;
  }
  static validateTelephone(telephone) {
    if (telephone.trim().length === 0) {
      store.dispatch(
        "makeErros",
        Message.createMessage(
          "Seul les chiffres sont autorisé pour le Téléphone"
        )
      );
      return false;
    }
    return true;
  }
  static getErrorMessage(error) {
    for (const [key, value] of Object.entries(error.response.data)) {
      store.dispatch("makeErros", Message.createMessage(`${value}`));
      // console.info(`${key}: ${value}`);
      return key;
    }
  }
  static async register(url, data) {
    if (UserService.validateTelephone(data.telephone) && UserService.validateEmail(data.email)) {
      if (data.password !== data.password2) {
        store.dispatch(
          "makeErros",
          Message.createMessage("Les mot de passes ne sont pas identique")
        );
      } else if (data.password.length < 8) {
        store.dispatch(
          "makeErros",
          Message.createMessage(
            "Le mot de passe doit avoir minimum 8 caractères"
          )
        );
      } else {
        return new ApiService()
          .login(url, data)
          .then(() => {
            let notification = Message.createMessage(
              "Compte administrateur créer avec succès",
              "success"
            )
            console.log(notification);
            store.dispatch(
              "makeErros",
              notification
            );
            router.push({ path: "/login" }).catch(() => { });
          })
          .catch((error) => {
            UserService.getErrorMessage(error);
          });
      }
    }
  }
  static async addUser(user, token) {
    return new Promise(function (resolve, reject) {
      if (isNaN(user.telephone)) {
        store.dispatch(
          "makeErros",
          Message.createMessage(
            "Seul les chiffres sont autorisé pour le Téléphone"
          )
        );
      } else {
        new ApiServiceD().add("/auth/create/user/", user, null, token)
          .then((resp) => {
            store.dispatch(
              "makeErros",
              Message.createMessage(
                "Compte utilisateur créer avec succès.",
                "success"
              )
            );
            resolve(resp);
          })
          .catch((err) => {
            UserService.getErrorMessage(err);
            setTimeout(() => {
              store.dispatch(
                "makeErros",
                Message.createMessage(
                  ""
                )
              );
            }, UserService.timeout);
            reject(err)
          });
      }
    });
  }
  static async updateUser(user, entreprise_id, user_id, token = null) {
    return new Promise(function (resolve, reject) {
      if (UserService.validateTelephone(user.telephone) && UserService.validateEmail(user.email)) {
        new ApiServiceD()
          .update(
            "/auth/update/user/",
            user,
            { entreprise: entreprise_id, user: user_id },
            token
          )
          .then((resp) => {
            store.dispatch("makeErros", Message.createMessage(resp.data.message, "success"));
            store.dispatch("updateUser", resp.data.user);
            setTimeout(() => {
              store.dispatch("makeErros", Message.createMessage(''));
            }, UserService.timeout);
            resolve(resp)
          })
          .catch((error) => {
            UserService.getErrorMessage(error);
            reject(error);
          });

      }
    });
  }
  static listUsers(entreprise_id, token) {
    return new Promise(function (resolve, reject) {
      new ApiServiceD()
        .getList("/auth/user/list/", { entreprise: entreprise_id }, token)
        .then((rep) => {
          console.log(rep.data);
          resolve(rep.data)
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async resetPassword(data, token) {
    if (data.newPassword !== data.newPassword1) {
      store.dispatch(
        "makeErros",
        Message.createMessage("Les mot de passes ne sont pas identique")
      );
    } else {
      return new ApiService().axiosInstance().post(
        '/auth/reset/',
        data,
        {
          headers: {
            'Authorization': `Token ${token}`
          }
        }
      ).then((res) => {
        store.dispatch("makeErros", Message.createMessage(res.data.message, "success"));
        setTimeout(() => {
          store.dispatch("makeErros", Message.createMessage(''));
        }, UserService.timeout);
        setTimeout(() => {

          store.commit("LOGOUT_USER");

          router.push({ path: "/login" }).catch(() => { });
        }, UserService.timeout);

      }).catch((err) => {
        UserService.getErrorMessage(err);
      });
    }

  }
}
