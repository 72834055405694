<template>
  <v-dialog persistent v-model="dialog" hide-overlay scrollable max-width="500px" style="margin-top: 100vh;">
    <v-card justify="center">
      <v-card-title v-appcolor>
        <v-row class="pl-5">
          <v-col sm="4" md="4">
            <v-img contain src="@/assets/img/001-pay.png" height="50px" width="50px" class="ml-n6">
                </v-img>
          </v-col>
          <v-col md="8">
            <div id="dette">
              <span>Dettes</span> <br>
              <small>Liste de vos créanciers</small>
            </div>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-container>
          

          <v-divider></v-divider>

          <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details filled
                dense rounded class="white black--text mb-2" size="15px">
          </v-text-field>
          <v-data-table 
              :headers="headers" 
              :items="listMaintInpayer" 
              :search="search" 
              sort-by="calories" class="elevation-1" 
              >
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small class="mr-2" @click="paid(item)" color="primary" title="Payer" v-size="20">
                  fa-solid fa-money-bill-transfer
                </v-icon>
              </template>
          </v-data-table>

        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <div >
          <v-btn v-appcolor text class="mr-4" @click="close">
            Fermer
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
export default ({
  data(){
    return {
      // dialog: this.getDialogDette(),
      token: localStorage.getItem('accessToken'),
      listMaintInpayer:[],
      search: '',
      headers:[
        {
            text: 'Prénom(s) et Nom',
            align: 'start',
            sortable: true,
            value: 'client',
        },
        { text: 'Contact', value: 'tel', align: 'center' },
        { text: 'Montant', value: 'rest_apayer', align: 'center' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    }
  },
  computed: {
    getTotalDette() {
      return this.maintUpdated()
    },
    dialog(){
      return this.getDialogDette()
    }
  },
  watch: {
    getTotalDette() {
      setTimeout(() => {
        this.initialize()

      }, 500);
    },
  },
  created() {
    this.initialize()
  },
  methods: {
    ...mapGetters(['isAuthenticated', 'getAccessToken']),
    ...mapGetters("dettes", ['allDettes', 'maintUpdated', 'getDialogDette']),
    paid(item) {
      this.$emit('payer', item)
    },
    initialize() {
      this.listMaintInpayer = this.allDettes().filter((dette) => dette.rest_apayer >0)    
    },
    close() {
      this.$store.dispatch('dettes/switchDialogDette', false);
    }
  },
})
</script>
<style>
.list_item {
  border-bottom: 1px solid lightgray;
}
#dette{
  line-height: 20px;
}
#dette:first-child{
  font-weight: 800;
  line-height: -2px;
}
</style>
