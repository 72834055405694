<template>
  <v-container>
    <v-data-table :headers="headers" :items="userList" :search="search" sort-by="calories" hide-default-footer
      class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>

          <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details filled dense
            rounded class="d-flex d-sm-none white black--text" size="15px">
          </v-text-field>
          <v-dialog persistent v-model="dialog" max-width="800px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-appcolor dark class="mb-2 d-none d-sm-flex" v-bind="attrs" v-on="on">
                {{ $t('buttons.addNew') }}
              </v-btn>

              <v-btn fab v-appcolor dark class="ml-3 mt-3 mb-2 d-flex d-sm-none" width="40px" height="40px"
                v-bind="attrs" v-on="on">
                <v-icon>fa-regular fa-plus</v-icon>
              </v-btn>

            </template>
            <v-card>
              <v-card-title v-appcolor>
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-text-field v-model="Form.username" :label="lang == 'fr' ? 'Non d\'utilisateur' : 'User Name'"
                      :name="lang == 'fr' ? 'Non d\'utilisateur' : 'User Name'" prepend-icon="person" type="text"
                      color="cyan darken-3" :rules="requireRules" dense outlined required />
                    <v-text-field v-model="Form.telephone" :label="lang == 'fr' ? 'Téléphone' : 'Phone Number'"
                      :name="lang == 'fr' ? 'Téléphone' : 'Phone Number'" prepend-icon="phone" type="text"
                      color="cyan darken-3" :rules="requireRules" dense outlined required />
                  </v-form>

                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <div class="text-center mt-3 mb-5 ml-5 mr-3">
                  <v-btn rounded v-appcolor dark @click="close">{{ $t('buttons.close') }}</v-btn>
                </div>

                <div class="text-center mt-3 mb-5">
                  <v-btn rounded v-appcolor dark @click="register">{{ $t('buttons.save') }}</v-btn>
                </div>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon medium @click="showDialogRole(item)" class="mr-2" color="success" title="Accès" v-size="17">
          fa-solid fa-building-lock
        </v-icon>
        <v-icon medium @click="removeUserToService(item)"
          :disabled="item.service_name == 'Aucun service' ? true : false" color="red" title="Retirer" v-size="17">
          fa-solid fa-ban
        </v-icon>
      </template>
    </v-data-table>
    <v-dialog persistent v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <DialogServiceManager v-on:submited="initilaze" />
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import UserService from '@/services/UserService'
import ApiService from '@/classes/ApiService'
import DialogServiceManager from '../DialogServiceManager.vue'
import ServiceAccesManager from '@/services/ServiceAccesManager.js'
// import ComponentRoleService from '@/services/ComponentRoleService.js'
export default {
  components: { DialogServiceManager },
  data() {
    return {
      valid: true,
      dialog: false,
      dialogDelete: false,
      errors: this.getErrors(),
      userList: [],
      search: '',
      user: '',
      user_id: null,
      headers: this.$i18n.locale == 'fr' ? [
        { text: 'Prenom', align: 'start', sortable: true, value: 'user.first_name' },
        { text: 'Nom', value: 'user.last_name' },
        { text: 'Contact', value: 'user.telephone' },
        { text: 'Email', value: 'user.email' },
        { text: 'Service', value: 'service_name' },
        { text: 'Actions', value: 'actions', sortable: false },
      ] : [
        { text: 'First Name', align: 'start', sortable: true, value: 'user.first_name' },
        { text: 'Last Name', value: 'user.last_name' },
        { text: 'Phone Number', value: 'user.telephone' },
        { text: 'Email Adress', value: 'user.email' },
        { text: 'Service', value: 'service_name' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      Form: {
        username: '',
        telephone: '',
        email: '',
        password: '',
        password2: '',
        entreprise: '',
      },
      defaultForm: {
        username: '',
        telephone: '',
        email: '',
        password: '',
        password2: '',
      },
      requireRules: [
        v => !!v || "Ce champ est obligatoire",
      ],
    }
  },

  computed: {
    formTitle() {
      return this.$store.locale == 'fr' ? 'Ajouter un compte utilisateur' : 'Add a user account'
    },
    lang() {
      return this.$store.state.locale
    },
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
  },
  created() {
    this.Form.entreprise = this.getEntreprise().id
    this.initilaze()
  },
  methods: {
    ...mapGetters(['getErrors', 'getEntreprise', 'getUser', 'getAccessToken']),
    ...mapActions(['userRegister', 'makeErros']),
    register() {
      if (this.$refs.form.validate()) {
        UserService.addUser(this.Form, this.getAccessToken())
          .then(() => {
            this.initilaze(),
              this.close(),
              setTimeout(() => {
                this.makeErros('')
              }, new ApiService().network.timeout);
          })
          .catch((error) => { console.error(error) });
      }
    },

    initilaze() {
      UserService.listUsers(this.getEntreprise().id, this.getAccessToken())
        .then((response) => {
          this.userList = response.filter(ele => ele.user.id !== this.getUser().id);
          this.$store.dispatch('setListUser', response)
        }).catch((err) => { console.log(err); })
    },

    showDialogRole(value) {
      console.log(value);
      this.user = value
      this.$store.commit('setShowDialogRole', value);
    },

    removeUserToService(value) {
      this.dialogDelete = true;
      this.user_id = value.user.id;
    },

    deleteItemConfirm() {
      ServiceAccesManager.RemoveUserToService(this.user_id, this.getAccessToken())
        .then(() => {
          this.user_id = null;
          this.initilaze()
          this.closeDelete()
        });
    },

    closeDelete() {
      this.dialogDelete = false;
    },

    close() {
      this.dialog = false
    },

    errorMessage(message) {
      this.$toast.error(message, {
        position: "bottom-right",
        timeout: 4095,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: false,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false
      });
    },

    successMessage(message) {
      this.$toast.success(message, {
        position: "bottom-right",
        timeout: 4095,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: false,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false
      });
    },

  },
}
</script>