import ApiServiceD from "@/classes/ApiServiceD";
import store from "@/store";

export default class DetteManager {
  static async listDette(id_entreprise,token = null) {
    try {
      const rep = await new ApiServiceD()
        .getList("caisse/maintenance/nonpayer/", { entreprise: id_entreprise }, token);
      store.dispatch("dettes/fetchDette", rep.data);
      store.dispatch("dettes/assignMontantTotalDette", rep.data.total_dette);
      store.dispatch('comptable/setSoldeCaisse',rep.data.solde_caisse);
      console.log(rep.data);
    } catch (err) {
      console.log(err.data);
    }
  }
  static paydebt(data, maint_id, token = null) {
    return new Promise((resolve, reject) => {
      try {
        const rep = new ApiServiceD()
          .update("caisse/maintenance/payerdette/", data, { id: maint_id }, token);
        resolve(rep);
      } catch (err) {
        reject(err);
        console.log(err.data);
      }
    })
  }
}
