

export default{
    namespaced: true,
    state:{
        confirmation: false,
        message: "",
    },
    getters:{
        getConfirmation:(state) => state.confirmation,
        getMessage:(state) => state.message
    },
    mutations : {
        async SET_ALERT_STATE(state, payload){
            state.confirmation = payload
        },
    },
    actions : {
        switchConfirmation:({commit}, payload)=>commit('SET_ALERT_STATE', payload),
    }
}