<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="6" lg="6" sm="12">
        <NouvelApprovisonnement v-on:submit="initialize()" class="mt-n1" />
      </v-col>
      <v-col clos="12" md="6" lg="6" sm="12">
        <v-toolbar flat v-appcolor>
          <v-toolbar-title class="white--text d-none d-sm-flex">
            {{ lang == 'fr' ? 'LISTE APPROVISIONNEMENT' : 'SUPPLY LIST' }}
          </v-toolbar-title>
          <v-toolbar-title class="white--text d-flex d-sm-none" style="font-size:small">
            {{ lang == 'fr' ? 'LISTE APPROVISIONNEMENT' : 'SUPPLY LIST' }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details filled dense
            rounded class="d-none d-sm-flex white black--text" style="width:30%; max-width: 30%;">
          </v-text-field>
        </v-toolbar>
        <div class="d-block d-sm-none" align="left"
          style="color:cornflowerblue; font-weight:900; font-size:large;font-family:Arial, Helvetica, sans-serif">
          {{ lang == 'fr' ? 'Cout total' : 'Total cost' }}: {{ totalCost | localFormat }}{{ getEntreprise().type_monaie }}
        </div>
        <v-data-table :headers="headers" :items="listApproJours" :search="search" sort-by="calories" class="elevation-1"
          hide-default-footer>
          <template v-slot:[`item.nbArticle`]="{ item }">
            <v-chip color="primary" dark>
              {{ item.nbArticle }}
            </v-chip>
          </template>
          <template v-slot:top>
            <v-toolbar flat>
              <v-divider class="mx-4" inset vertical></v-divider>
              <p class="d-none d-sm-flex" style="font-weight:900; font-size:large;font-family:Arial; font-size:20px">
                {{ lang == 'fr' ? 'Cout total' : 'Total cost' }}: {{ totalCost | localFormat }}{{ getEntreprise().type_monaie
                }}
              </p>
              <v-spacer></v-spacer>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details filled
                dense rounded class="d-flex d-sm-none white black--text" size="15px">
              </v-text-field>
              <v-dialog persistent v-model="dialog" max-width="600px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-appcolor dark class="mb-2 d-none d-sm-flex" v-bind="attrs" v-on="on">
                    <v-icon>fa-duotone fa-calendar-days</v-icon>
                  </v-btn>

                  <v-btn fab v-appcolor dark class="ml-3 mt-3 mb-2 d-flex d-sm-none" width="40px" height="40px"
                    v-bind="attrs" v-on="on">
                    <v-icon>fa-duotone fa-calendar-days</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title v-appcolor>
                    <span class="text-h5">{{ lang == 'fr' ? frFormTitle : enFormTitle }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" md="6" v-if="toggleSwitch">
                          <DatePiker @selectedDate="setFromDate" />
                        </v-col>
                        <v-col cols="12" md="6" v-if="toggleSwitch">
                          <DatePiker2 @selectedDate="setToDate" />
                        </v-col>
                        <v-col cols="12" v-if="!toggleSwitch">
                          <DatePiker @selectedDate="setFindExactDate" />
                        </v-col>
                      </v-row>

                      <v-switch v-model="toggleSwitch"
                        :label="lang == 'fr' ? 'Chercher entre de dates' : 'Search between dates'" color="#114E60"
                        hide-details>
                      </v-switch>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      {{ $t('buttons.close') }}
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="find">
                      {{ $t('buttons.display') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog persistent v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ApprovisonnementManager from '@/services/ApprovisonnementManager'
import NouvelApprovisonnement from './NouvelApprovisonnement.vue'
import DatePiker from '../DatePiker.vue'
import DatePiker2 from '../DatePiker2.vue'
import moment from 'moment'
import { mapGetters } from 'vuex'
export default {
  components: { NouvelApprovisonnement, DatePiker, DatePiker2 },
  data() {
    return {
      valid: true,
      dialog: false,
      dialogDelete: false,
      search: '',
      toggleSwitch: false,
      totalCost: 0,
      headers: this.$i18n.locale == 'fr' ?[
        { text: 'ARTICLE', align: 'start', sortable: true, value: 'libelle' },
        { text: 'QUANTITE', value: 'qantite_apro', align: 'center' },
        { text: 'FOURNISSEUR', value: 'fournisseur', align: 'center' },
      ]:[
        { text: 'ARTICLE', align: 'start', sortable: true, value: 'libelle' },
        { text: 'QUANTITY', value: 'qantite_apro', align: 'center' },
        { text: 'SUPPLYER', value: 'fournisseur', align: 'center' },
      ],

      listAppro: [],
      tmp_list: [],
      listApproJours: [],

      listeDetaill: [],

      editedIndex: -1,
      approForm: {
        first_name: '',
        last_name: '',
        tel: '',
      },
      defaultItem: {
        first_name: '',
        last_name: '',
        tel: '',
      },
    }
  },

  computed: {
    frFormTitle() {
      return this.editedIndex === -1 ? 'Historique' : ''
    },
    enFormTitle() {
      return this.editedIndex === -1 ? 'History' : ''
    },
    lang() {
      return this.$store.state.locale
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  created() {
    this.initialize()
  },

  methods: {
    ...mapGetters(['getAccessToken', 'getEntreprise']),
    reset() {
      this.$refs.form.reset()
    },
    initialize() {
      let sauve = [], sauveToday = [];
      console.log("Listing: ", this.listApproJours);
      ApprovisonnementManager.listAppro(this.getEntreprise().id, this.getAccessToken())
        .then((rep) => {

          console.log("Liste des approvalnement :", rep.data);
          this.listApproJours = rep.data
          // a local variable store today date
          let todayDate = moment(new Date()).format('YYYY-MM-DD')
          console.log("Date du jour: ", todayDate)
          rep.data.forEach(el => {
            let donne
            // let appro_date = moment().format('YYYY-MM-DD')
            if (moment(todayDate).isSame(el.date_appro)) {
              this.totalCost += (parseFloat(el.pau) * parseInt(el.qantite_apro))
              donne = {
                "id": el.id,
                "libelle": el.piece.libelle+'('+el.piece.categorie.libelle+')',
                "pau": el.pau,
                "fournisseur": el.fournisseur.full_name,
                "qantite_apro": el.qantite_apro,
                "date_appro": el.date_appro,
              }
              sauveToday.push(donne)
            }

            let data = {
              "id": el.id,
              "libelle": el.piece.libelle,
              "pau": el.pau,
              "fournisseur": el.fournisseur.full_name,
              "qantite_apro": el.qantite_apro,
              "date_appro": el.date_appro,
            }
            sauve.push(data)
          });
          this.listAppro = sauve
          this.listApproJours = sauveToday
          console.log(this.listApproJours)
        }).catch((error) => {
          console.log(error)
        })
    },

    find() {
      let sauve = [];
      if (this.toggleSwitch == false) {
        this.totalCost = 0
        if (this.tmp_list.length > 0) {
          this.tmp_list.forEach(el => {
            if (moment(el.date_appro).isSame(this.exactDate)) {
              this.totalCost += (parseFloat(el.pau) * parseInt(el.qantite_apro))
              let data = {
                "id": el.id,
                "libelle": el.libelle,
                "pau": el.pau,
                "fournisseur": el.fournisseur,
                "qantite_apro": el.qantite_apro,
                "date_appro": el.date_appro,
              }
              sauve.push(data)
            }
          });
        } else {
          this.tmp_list = this.listAppro
          this.listAppro.forEach(el => {
            if (moment(el.date_appro).isSame(this.exactDate)) {
              this.totalCost += (parseFloat(el.pau) * parseInt(el.qantite_apro))
              let data = {
                "id": el.id,
                "libelle": el.libelle,
                "pau": el.pau,
                "fournisseur": el.fournisseur,
                "qantite_apro": el.qantite_apro,
                "date_appro": el.date_appro,
              }
              sauve.push(data)
            }
          });
        }
        this.listAppro = sauve
        this.listApproJours = sauve
      } else {
        if (this.tmp_list.length > 0) {
          this.tmp_list.forEach(el => {
            if (moment(el.date_appro).isBetween(this.fromDate, this.toDate)) {
              this.totalCost += (parseFloat(el.pau) * parseInt(el.qantite_apro))
              let data = {
                "id": el.id,
                "libelle": el.libelle,
                "pau": el.pau,
                "fournisseur": el.fournisseur,
                "qantite_apro": el.qantite_apro,
                "date_appro": el.date_appro,
              }
              sauve.push(data)
            }
            // console.log(element.registred_at)
          });
        } else {
          this.tmp_list = this.listAppro
          this.listAppro.forEach(el => {
            if (moment(el.date_appro).isBetween(this.fromDate, this.toDate)) {
              this.totalCost += (parseFloat(el.pau) * parseInt(el.qantite_apro))
              let data = {
                "id": el.id,
                "libelle": el.libelle,
                "pau": el.pau,
                "fournisseur": el.fournisseur,
                "qantite_apro": el.qantite_apro,
                "date_appro": el.date_appro,
              }
              sauve.push(data)
            }
            // console.log(element.registred_at)
          });

        }
        this.listAppro = sauve
        this.listApproJours = sauve
      }
      this.close()
    },
    setFromDate(val) {
      this.fromDate = val
    },
    setToDate(val) {
      this.toDate = val
    },
    setFindExactDate(val) {
      this.exactDate = val
    },
    deleteItemConfirm() {
      ApprovisonnementManager.deleteApprovisonnement(this.approForm.id, this.getAccessToken())
        .then(() => {
          this.tmp_list = []
          this.listApproJours = []
          this.initialize()
        }).catch((error) => { console.log(error.response.data) })
      this.closeDelete()
    },
    editItem(item) {
      this.editedIndex = this.listAppro.indexOf(item)
      this.approForm = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.listAppro.indexOf(item)
      this.approForm = Object.assign({}, item)
      this.dialogDelete = true
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.approForm = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.approForm = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },


  },
}
</script>