<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="9" sm="12">
        <v-toolbar flat v-appcolor>
          <v-toolbar-title class="white--text d-none d-sm-flex">
            {{ lang == 'fr' ? 'LISTE DES ARTICLES' : 'LIST OF ARTICLES' }}</v-toolbar-title>
          <v-toolbar-title class="white--text d-flex d-sm-none" style="font-size:small">
            {{ lang == 'fr' ? 'LISTE DES ARTICLES' : 'LIST OF ARTICLES' }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details filled dense
            rounded class="d-none d-sm-flex white black--text mr-3" style="width:30%; max-width: 30%;">
          </v-text-field>

        </v-toolbar>
        <div class="d-block d-sm-none" align="left"
          style="color:cornflowerblue; font-weight:900; font-size:large;font-family:Arial, Helvetica, sans-serif">
          {{ lang == 'fr' ? 'Stock total' : 'Total stock' }}: {{ socktotal }} <br>
          {{ lang == 'fr' ? 'Total Article' : 'Total Article' }}: {{ articletotal }}
          <br>
          <v-btn color="primary darken-4" @click="detailCout" text class="mr-4"
            style="font-size:medium;font-family:Arial">
            Détails du coût d'achat
          </v-btn>

        </div>
        <v-data-table :headers="headers" :items="listepieces" :search="search" sort-by="calories" class="elevation-1">
          <template v-slot:[`item.thumbnail`]="{ item }">
            <v-img v-if="item.thumbnail" style="cursor: pointer;"
              class="bg-white"
              @click="showDialogViewImage(item)"
              :width="60"
              :height="60"
              :aspect-ratio="1"
              :src="serverURL+''+item.thumbnail"
              contain
            >
            </v-img>
            <v-img v-else
              class="bg-white"
              :width="60"
              :height="60"
              :aspect-ratio="1"
              :src="require(`../../assets/icons/placeholder.jpg`)" contain>
            </v-img>
          </template>
          
          <template v-slot:top>
            <v-toolbar flat>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-toolbar-title class="d-none d-sm-flex">
                {{ lang == 'fr' ? 'Stock total' : 'Total stock' }}: {{ socktotal }} &nbsp;&nbsp;
                {{ lang == 'fr' ? 'Total Article' : 'Total Article' }}: {{ articletotal }}
                <v-spacer></v-spacer>
                <v-btn v-appcolor dark class="ml-2 mb-2" @click="initialize" :loading="isLoading">
                    <v-icon>fas fa-rotate</v-icon>
                </v-btn>
              </v-toolbar-title>
              <v-spacer></v-spacer>


              <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details filled
                dense rounded class="d-flex d-sm-none white black--text" size="15px">
              </v-text-field>
              <v-dialog persistent v-model="dialog" max-width="800px">
                <template v-slot:activator="{ on, attrs }">
                  
                  <v-btn v-appcolor dark class="mb-2 d-none d-sm-flex" v-bind="attrs" v-on="on">
                    {{ $t('buttons.addNew') }}
                  </v-btn>
                  <v-btn fab v-appcolor dark class="ml-3 mt-3 mb-2 d-flex d-sm-none" width="40px" height="40px"
                    v-bind="attrs" v-on="on">
                    <v-icon>fa-regular fa-plus</v-icon>
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title v-appcolor>
                    <span class="text-h5">{{ lang =='fr'? frFormTitle:enFormTitle }}</span>
                  </v-card-title>
                  <validation-observer ref="observer" v-slot="{ invalid }">
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6" md="4">
                            <validation-provider v-slot="{ errors }" 
                              :name="lang=='fr'? 'Fournisseur':'Supplier'" rules="required">
                              <v-combobox v-model="selectFour" :items="listFournisseurs" 
                              :label="lang=='fr'? 'Fournisseur':'Supplier'"
                                item-value="id" item-text="full_name" dense :error-messages="errors" class="mt-5"
                                @change="setSelectedFourniseur">
                              </v-combobox>
                            </validation-provider>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <validation-provider v-slot="{ errors }" 
                              :name="lang=='fr'? 'Désignation':'Designation'" rules="required">
                              <v-text-field v-model="apparielForm.libelle" 
                              :label="lang=='fr'? 'Désignation':'Designation'" :error-messages="errors"
                                required></v-text-field>
                            </validation-provider>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <validation-provider v-slot="{ errors }" 
                              :name="lang=='fr'? 'Catégorie':'Category'" rules="required">
                              <v-combobox v-model="selectCategorie" :items="categorieItems" 
                              :label="lang=='fr'? 'Catégorie':'Category'"
                                item-value="id" item-text="libelle" dense :error-messages="errors" class="mt-5"
                                @change="selectAccessoire">
                              </v-combobox>
                            </validation-provider>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <validation-provider v-slot="{ errors }" 
                              :name=" lang=='fr'? 'Quantité':'Quantity'" rules="required">
                              <v-text-field v-model="apparielForm.stock" 
                              :label=" lang=='fr'? 'Quantité':'Quantity'" :error-messages="errors"
                                required>
                              </v-text-field>
                            </validation-provider>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <validation-provider v-slot="{ errors }" 
                              :name=" lang=='fr'? 'Prix d\'achat unitaire':'Unit purchase price'" rules="required">
                              <v-text-field v-model="apparielForm.pau" 
                              :label=" lang=='fr'? 'Prix d\'achat unitaire':'Unit purchase price'" :error-messages="errors"
                                required>
                              </v-text-field>
                            </validation-provider>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <validation-provider v-slot="{ errors }" 
                              :name=" lang=='fr'? 'Seuil aprovisionnement':'Supply threshold'" rules="required">
                              <v-text-field v-model="apparielForm.seuil" 
                              :label=" lang=='fr'? 'Seuil aprovisionnement':'Supply threshold'"
                                :error-messages="errors" required>
                              </v-text-field>
                            </validation-provider>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <validation-provider v-slot="{ errors }" 
                              :name=" lang=='fr'? 'Prix de vente unitaire':'Unit selling price'" rules="required">
                              <v-text-field v-model="apparielForm.pvu" 
                              :label=" lang=='fr'? 'Prix de vente unitaire':'Unit selling price'" :error-messages="errors"
                                required>
                              </v-text-field>
                            </validation-provider>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-combobox v-model="selectConteneur" :items="listConteurs" 
                              :label="lang=='fr'? 'Conteneur':'Container'"
                                item-value="id" item-text="libelle" dense class="mt-5"
                                @change="setselectConteneur">
                              </v-combobox>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="close">
                        {{ $t('buttons.cancel') }}
                      </v-btn>
                      <v-btn color="blue darken-1" :disabled="invalid" text @click="save">
                        {{ $t('buttons.save') }}
                      </v-btn>
                    </v-card-actions>
                  </validation-observer>
                </v-card>
              </v-dialog>
              
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <input ref="uploader" class="d-none" type="file" @change="onFileChanged">
            <v-icon small class="mr-2" @click="handleFileImport(item)" color="info" title="Ajouter une image">
              fas fa-images
            </v-icon>
            <v-icon small class="mr-2" @click="editItem(item)" color="info" title="Editer">
              fa-duotone fa-pen-to-square
            </v-icon>
            <v-icon small @click="deleteItem(item)" color="red" title="Supprimer">
              fa-duotone fa-trash-can
            </v-icon>
          </template>
        </v-data-table>

        <v-card class="pr-20 d-flex">
          <v-row>
            <v-col cols="12" md="6">
              <v-btn color="primary darken-4" @click="detailCout" text class="mr-4"
                style="font-size:medium;font-family:Arial">
                {{ lang == 'fr' ? 'Détails du coût d\'achat' : 'Purchase cost details' }}
              </v-btn>
            </v-col>
            <v-col cols="12" md="6">

              <v-card-text>
                <div class="d-block text--dark" align="right" style="font-weight:900; color:#000000; 
                font-size:large;font-family:Arial, Helvetica, sans-serif; padding-right:30px">
                  {{ lang == 'fr' ? 'Prix de vente total' : 'Total sale price' }} :
                  {{ totalSellCost | localFormat }}{{ getEntreprise().type_monaie }}<br>
                  <br>
                </div>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="3" sm="12">
        <CategorieList />
      </v-col>
    </v-row>
    
      <!-- Dialog view image -->
      <v-dialog v-model="dialogViewImage" width="300" hide-overlay>
        <v-card>
          <v-card-text>
            <v-img
              class="bg-white"
              :width="300"
              :height="300"
              :aspect-ratio="1"
              :src="serverURL+''+overViewImageLink"
              contain
            ></v-img>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!--End dialog view image -->
      <DialogDetailCoutArticle :detailInfo="listDetailCout" />
      <ConfirmAlert @confirmed="deleteItemConfirm" />
  </v-container>
</template>
<script>
import ApiServiceD from '@/classes/ApiServiceD'
import ApiService from '@/classes/ApiService'
import ArticleManager from '@/services/ArticleManager'
import FournisseurManager from '@/services/FournisseurManager'
import ApprovisonnementManager from '@/services/ApprovisonnementManager'
import { required } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import DialogDetailCoutArticle from '../DialogDetailCoutArticle.vue'
import { mapGetters } from 'vuex'
import CategorieList from './CategorieList.vue'
import ConteneurManager from '@/services/Conteneur'
import ConfirmAlert from '../Alerts/ConfirmAlert.vue'
extend('required', {
  ...required,
  message: '{_field_} can not be empty',
})

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    DialogDetailCoutArticle,
    CategorieList,
    ConfirmAlert
  },
  data() {
    return {
      dialog: false,
      loading: false,
      dialogViewImage: false,
      overViewImageLink: '',
      isLoading: false,
      search: '',
      selectedFile: null,
      selectedItemID:0,
      selectedItemImage:'',
      selectedItemImageThumbnail:'',
      listConteurs:[],
      selectConteneur:null,
      headers: this.$i18n.locale == 'fr' ? [
        { text: 'Nom Produit', align: 'start', sortable: true, value: 'libelle', },
        // { text: 'PRIX ACHAT UNITAIRE', value: 'pau' },
        { text: 'Image', value: 'thumbnail' },
        { text: 'Conteneur', value: 'conteneur.libelle' },
        { text: 'Stock', value: 'stock' },
        { text: 'Prix de vente', value: 'pvu' },
        { text: 'Actions', value: 'actions', sortable: false },
      ] : [
        { text: 'Product Name', align: 'start', sortable: true, value: 'libelle', },
        // { text: 'PRIX ACHAT UNITAIRE', value: 'pau' },
        { text: 'Image', value: 'thumbnail' },
        { text: 'Container', value: 'conteneur.libelle' },
        { text: 'Stock', value: 'stock' },
        { text: 'Sale Price', value: 'pvu' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      listDetailCout: [],
      listepieces: [],
      listFournisseurs: [],
      totalCost: 0,// All Piece total cost$
      totalSellCost: 0,// All Piece total sell price$
      socktotal: 0,// Total stock
      articletotal: 0,// Total article
      selectCategorie: '',//Piece categorie selected
      selectFour: '',//Selected Fournisseur
      editedIndex: -1,
      apparielForm: {
        id: '',
        libelle: '',
        categorie: null,
        stock: '',
        pau: '',
        seuil: '',
        pvu: '',
        fournisseur: '',
        entreprise: '',
        conteneur:'',
        // image: '',
      },
      defaultItem: {
        id: '',
        libelle: '',
        categorie: null,
        stock: '',
        pau: '',
        seuil: '',
        pvu: '',
        conteneur:'',
        // image: '',
      },
    }
  },

  computed: { 
    frFormTitle() {
      return this.editedIndex === -1 ? 'Nouveau Article' : 'Modification'
    },
    enFormTitle() {
      return this.editedIndex === -1 ? 'New Article' : 'Modification'
    },
    categorieItems() {
      return this.getListCategorie()
    },
    lang() {
      return this.$store.state.locale
    },
    // Load server base url in order to load images link
    serverURL(){
      return new ApiService().baseURL
    }

  },
  watch: {
    dialog(val) {
      val || this.close()
    },
  },
  created() {
    this.initialize()
    this.loadFournisseur() 
  },
  methods: {
    ...mapGetters(['getAccessToken', 'getEntreprise']),
    ...mapGetters('categorie', ['getListCategorie']),
    initialize() {
      this.isLoading = true;
      ArticleManager.listPiece(this.getEntreprise().id, this.getAccessToken())
        .then((rep) => {
          this.listepieces = rep.data[0].filter(item => item.stock > 0)
          this.articletotal = rep.data[0].length
          this.totalCost = rep.data[1]
          this.socktotal = rep.data[2]
          this.totalSellCost = rep.data[3]
          this.isLoading = false
        }).catch((error) => {
          console.log(error)
          this.isLoading = false
        })
        this.loadConteneur()
    },
    loadConteneur(){
        ConteneurManager.conteneurListe(this.getEntreprise().id, this.getAccessToken())
        .then(res => {
            this.listConteurs = res.data.reverse();
        })
        .catch(error => {
            console.log("error :",error)
        })
    },
    showDialogViewImage(item){
      console.log(item);
      this.overViewImageLink = item.image
      this.dialogViewImage = true
    },
    detailCout() {
      ApprovisonnementManager.listApproDetail(this.getEntreprise().id, this.getAccessToken())
        .then((rep) => {
          this.listDetailCout = rep.data
        }).catch((error) => {
          console.log(error)
        })
    },
    save() {
      //tester l'ajout des clients, ventes et maintenance
      this.apparielForm.entreprise = this.getEntreprise().id
      if (!this.apparielForm.conteneur) {
        this.apparielForm.conteneur = 0
      }
      if (this.editedIndex > -1) {
        // console.log(this.apparielForm)
        var rep = this.checkDuplicateOnUpdate(this.apparielForm)
        
        if (parseInt(rep) == -1) {
          // Extraire le libelle de l'article pour ne pas que sa soit collé
          // avec la categorie.
          this.apparielForm.libelle = this.apparielForm.libelle.split('(')[0]
          
          ArticleManager.updatePiece(this.apparielForm, this.apparielForm.id,
          this.getEntreprise().id, this.getAccessToken())
          .then((rep) => {
            this.successMessage(rep.data.message)
            this.initialize()
            this.close()
          }).catch((error) => { console.log(error.response.data) })
        }else
          this.errorMessage('Cet article existe déjà')
        
      }
      else {
        console.log(this.apparielForm)
        let rep = this.checkDuplicateOnSave(this.apparielForm)
        if (parseInt(rep) == -1) {
          ArticleManager.registerPiece(this.apparielForm, this.getAccessToken())
            .then((rep) => {
              this.successMessage(rep.data.message)
              this.initialize()
            }).catch((error) => { console.log(error.response.data) })
          this.close()
        } else
          this.errorMessage('Cet article existe déjà')
      }

    },
    handleFileImport(item) {
        // Trigger click on the FileInput
        this.selectedItemID = item.id
        this.selectedItemImage = item.image
        this.selectedItemImageThumbnail = item.thumbnail
        this.$refs.uploader.click();
    },
    onFileChanged(e) {
        this.selectedFile = e.target.files[0];
        console.log(this.selectedFile);
        if (this.selectedFile) {
          this.loading = true;
          let formData = new FormData();
          formData.append('image', this.selectedFile)
          formData.append('selectedID', this.selectedItemID)
          formData.append('selectedItemImage', this.selectedItemImage)
          formData.append('thumbnail', this.selectedItemImageThumbnail)
          ArticleManager.uploadImage(formData, null, this.getAccessToken())
          .then(() => {
            this.initialize()
            this.loading = false
          })
          .catch(error =>{
            this.selectedFile = null
            console.log("error :",error)
          })
            
        }
    },
    deleteItem(item) {
      this.editedIndex = this.listepieces.indexOf(item)
      this.apparielForm = Object.assign({}, item)
      this.$store.dispatch('alerts/switchConfirmation',true)
    },
    deleteItemConfirm(value) {
      if(value){
        let cm = new ApiServiceD()
        cm.delete('pieces/register/', { param_name: this.apparielForm.id }, this.getAccessToken())
          .then((rep) => {
            console.log(rep)
            this.initialize()
          }).catch((error) => { console.log(error.response.data) })
        this.closeDelete()
      }
      
    },
    selectAccessoire() {
      this.apparielForm.categorie = this.selectCategorie.id
    },
    setSelectedFourniseur() {
      this.apparielForm.fournisseur = this.selectFour.id
    },
    setselectConteneur() {
      this.apparielForm.conteneur = this.selectConteneur.id
    },
    loadFournisseur() {
      FournisseurManager.listFournisseur(this.getEntreprise().id, this.getAccessToken())
        .then((rep) => {
          this.listFournisseurs = rep.data
        }).catch((error) => {
          console.log(error)
        })
    },
    editItem(item) {
      console.log(item);
      this.editedIndex = this.listepieces.indexOf(item)
      this.apparielForm = Object.assign({}, item)
      this.selectFour = item.fournisseur
      this.selectConteneur = item.conteneur
      this.apparielForm.fournisseur = item.fournisseur.id
      this.selectCategorie = item.categorie //we set combo tyte selected by imte.tyep 
      //Assigning to it corresponding item key throu a terna conditono
      this.apparielForm.categorie = item.categorie.id
      this.dialog = true
    },
    close() {
      this.dialog = false
      this.selectFour = null
      this.$refs.observer.reset()
      this.selectConteneur = ''
      this.$nextTick(() => {
        this.apparielForm = Object.assign({}, this.defaultItem)
        this.selectCategorie = ''
        this.editedIndex = -1
      })
    },
    closeDelete() {
      this.$nextTick(() => {
        this.apparielForm = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    checkDuplicateOnSave(value) {
      console.log(this.listepieces);
      var rep = this.listepieces.findIndex((el) => {
        if ( el.libelle == value.libelle && el.categorie.id == value.categorie &&
          el.categorie.entreprise == this.getEntreprise().id) {
          return true;
        }
        return false;
      })
      return rep;
    },
    checkDuplicateOnUpdate(value) {
      console.log('list: ',this.listepieces);
      console.log('new value: ',value);
      var rep = this.listepieces.findIndex((el) => {
        if (el.id != value.id && el.libelle == value.libelle && 
        el.categorie.id == value.categorie && 
        el.categorie.entreprise == this.getEntreprise().id) {
          return true;
        }
        return false;
      })
      return rep;
    },
    errorMessage(message) {
      this.$toast.error(message, {
        position: "bottom-right",
        timeout: 4095,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: false,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false
      });
    },
    successMessage(message) {
      this.$toast.success(message, {
        position: "bottom-right",
        timeout: 4095,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: false,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false
      });
    },

  },
}
</script>
