import ApiService from "@/classes/ApiService";
import ApiServiceD from "@/classes/ApiServiceD";
export default class SuscriptionManager {
    static modeEssais(entreprise) {
        return new Promise(function (resolve, reject) {
            new ApiService().login('suscription/essais/', { entreprise: entreprise })
                .then((resp) => {
                    resolve(resp);
                }).catch((err) => { reject(err); })
        })
    }

    static modeDirect(data) {
        return new Promise(function (resolve, reject) {
            new ApiService().login('suscription/directe/', data)
                .then((resp) => {
                    resolve(resp);
                }).catch((err) => { reject(err); })
        })
    }

    static updateSuscription(data, entreprise, token) {
        return new Promise(function (resolve, reject) {
            new ApiServiceD().update('suscription/update/', data, { entreprise: entreprise }, token)
                .then((resp) => {
                    resolve(resp);
                }).catch((err) => { reject(err); })
        })
    }

    static updateSuscriptionAdmin(data, entreprise) {
        return new Promise(function (resolve, reject) {
            new ApiServiceD().axiosInstance().put('suscription/admin/update/', data, {
                params: { entreprise: entreprise },
            }).then((resp) => {
                resolve(resp);
            }).catch((err) => { reject(err); })
        })
    }

    static getAll() {
        return new Promise(function (resolve, reject) {
            new ApiService().axiosInstance().get('/suscription/directe/')
                .then((resp) => {
                    resolve(resp);
                }).catch((err) => { reject(err); })
        })
    }

    static getOneSuscription(entrepriseID, token) {
        return new Promise(function (resolve, reject) {
            new ApiServiceD().getList('/suscription/onentreprisesuscription/', { 'entreprise': entrepriseID }, token)
                .then((resp) => {
                    resolve(resp);
                }).catch((err) => { reject(err); })
        })
    }

    static deactivateActivateEntreprise(entrepriseID) {
        return new Promise(function (resolve, reject) {
            new ApiService().axiosInstance().put('entreprise/activate/deactivate/', null, {
                params: { 'entreprise': entrepriseID },
            }).then((resp) => {
                resolve(resp);
            }).catch((err) => { reject(err); })
        })
    }
}