<template>
    <v-container>
        <FormTemplate>
            <template v-slot:header>
                <v-toolbar-title class="d-none d-sm-flex">
                    {{ lang == 'fr' ? 'Dépenses' : 'Expenses' }}
                </v-toolbar-title>
                <v-toolbar-title class="d-flex d-sm-none" v-size="17">
                    {{ lang == 'fr' ? 'Dépenses' : 'Expenses' }}
                </v-toolbar-title>
            </template>
            <template v-slot:cbody>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-combobox v-model="selectSource" :items="sourceFinancere"
                        :label="lang == 'fr' ? 'Source financière' : 'Financial source'" item-value="id"
                        item-text="text" dense auto-select-first class="mt-8" @change="setSelectSource" required
                        :rules="requireRule">
                    </v-combobox>
                    <v-text-field v-model="Form.amount" :rules="requireRule"
                        :label="lang == 'fr' ? 'Montant' : 'Amount'" required>
                    </v-text-field>

                    <v-textarea clearable filled auto-grow v-model="Form.motifs"
                        :label="lang == 'fr' ? 'Motif de la dépense' : 'Reason for expenditure'" rows="2"
                        row-height="30" shaped>
                    </v-textarea>
                    <v-row class="mb-1 mr-1">
                        <v-spacer></v-spacer>
                        <v-btn v-appcolor text @click="register" :loading="isLoading">
                            {{ $t('buttons.save') }}
                        </v-btn>
                    </v-row>
                </v-form>
            </template>
        </FormTemplate>
    </v-container>
</template>
<script>
import FormTemplate from '../FormTemplate.vue';
import SoldeCaisse from '@/services/caisse/SoldeCaisse.js';
import { mapGetters } from 'vuex'
export default {
    components: { FormTemplate },
    data() {
        return {
            toggleSwitch: false,
            isLoading: false,
            fromDate: null,
            toDate: null,
            exactDate: null,
            selectSource: null,
            valid: true,//Form validation
            soldeCaisse: 0,
            soldeBenefice: 0,
            sourceFinancere: this.$i18n.locale == 'fr' ? [
                { id: 1, text: 'Caisse' },
                { id: 2, text: 'Bénéfice' },
            ] : [
                { id: 1, text: 'Fund' },
                { id: 2, text: 'Profit' },
            ],
            requireRule: [
                v => !!v || 'Ce champ est obligatoire',
            ],
            Form: {
                source: "",
                amount: "",
                motifs: "",
                entreprise: "",
            }

        }
    },
    created() {
        this.loadCaisseSolde()
        this.loadBeneficeSolde()
    },
    computed: {
        lang() {
            return this.$store.state.locale
        },
    },
    methods: {
        ...mapGetters(['getAccessToken', 'getEntreprise']),
        ...mapGetters("comptable", ['getSoldeCaisse', 'getSoldeBenefice']),
        register() {
            if (this.$refs.form.validate() == true) {
                let valid = false
                if ((this.Form.source == 1) && parseInt(this.Form.amount) <= parseInt(this.soldeCaisse)) {
                    valid = true
                } else if ((this.Form.source == 2) && parseInt(this.Form.amount) <= parseInt(this.soldeBenefice)) {
                    valid = true
                } else {
                    if (this.Form.source == 1) {
                        alert("Solde de la caisse inssufisant")
                    } else {
                        alert("Solde de la Bénéfice inssufisant")
                    }
                    valid = false
                }

                if (valid) {
                    this.isLoading = true
                    this.Form.entreprise = this.getEntreprise().id
                    SoldeCaisse.registerDepense(this.Form, this.getAccessToken())
                    // Apres la depense on met a jour le solde de la caisse ou du benefice
                    if (this.Form.source == 1) {
                        this.soldeCaisse -= parseInt(this.Form.amount)
                        this.$store.dispatch('comptable/setSoldeCaisse', this.soldeCaisse)
                        this.isLoading = false
                    } else {
                        this.soldeBenefice -= parseInt(this.Form.amount)
                        this.$store.dispatch('comptable/setSoldeBenefice', this.soldeBenefice)
                        this.isLoading = false
                    }
                    this.$refs.form.reset()

                }

            }
        },
        loadCaisseSolde() {
            this.soldeCaisse = this.getSoldeCaisse()
        },
        loadBeneficeSolde() {
            this.soldeBenefice = this.getSoldeBenefice()
        },
        setFindExactDate(val) {
            this.exactDate = val
        },
        setToDate(val) {
            this.toDate = val
        },
        setFromDate(val) {
            this.fromDate = val
        },
        setSelectSource() {
            this.Form.source = this.selectSource.id
            console.log(this.Form.source)
        },
    },
}
</script>
<style lang="">

</style>